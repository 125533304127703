import { Box, Paper, Typography, Grid, Container } from '@mui/material';
import React from 'react';
import { OFMailAtSign, OFCallIcon, OFMoreIcon } from '../../../icons/CustomIcon'
import { ReactComponent as WhatsappIcon } from "../../../assets/icons/WhatsApp.svg"
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';
import { toProper } from '../../../utils/UtilFunctions';
import MaterialTable from '../../../helpers/tables/MaterialTable';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';



const DashboardMain = () => {


    const [userInfo] = useRecoilState(perInfoState);



    const navigate = useNavigate();

    const handleViewMore = () => {
        navigate("/dashboard/inquiries");
    };



    return (


        <Box padding={4}>


            {/* <Container maxWidth={'xl'} > */}


            <Grid container>


                <Grid item xs={12} md={12} mt={1} mb={2}>

                    <Box sx={{ alignItems: 'center', direction: 'row', alignItems: 'center', gap: 2 }}>






                        <Grid container spacing={2} sx={{
                            display: 'flex', height: '100%'
                        }}>
                            <Grid item xs={12} md={7.5}>
                                {/* <Paper elevation={3} sx={{ borderRadius: '18px', padding: 4, backgroundColor: '#FFEFE7', height: '12rem' }}>
                                    <img src="/images/CompanyLogo.svg" alt="Company Logo" height={'auto'} width={'10%'}></img>
                                    <Typography variant='TITLE3' paragraph>Inquiries</Typography>
                                    <Typography variant='TITLE3' paragraph>Inquiries</Typography>
                                </Paper> */}
                                <Paper
                                    elevation={3}
                                    sx={{
                                        borderRadius: '18px',
                                        padding: 4,
                                        backgroundColor: '#FFEFE7',
                                        height: '12rem',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                        }}
                                    >
                                        <img
                                            src="/images/CompanyLogo.svg"
                                            alt="Company Logo"
                                            style={{ height: 'auto', width: '10%' }}
                                        />
                                        <Box>
                                            <Typography variant="Content" paragraph>
                                                Software and IT Services
                                            </Typography>
                                            <Typography variant="h6" paragraph>
                                                Career Tome India Pvt. Ltd.
                                            </Typography>
                                        </Box>
                                    </Box>



                                </Paper>


                            </Grid>




                            <Grid item xs={6} md={1.5}>
                                <Paper elevation={3} sx={{ borderRadius: '18px', padding: 4, backgroundColor: '#FDEBF9', height: '12rem' }}>
                                    <Typography variant='Body' paragraph>Total Views</Typography>
                                    <Typography variant='h6' paragraph>01</Typography>
                                </Paper>
                            </Grid>




                            <Grid item xs={6} md={1.5}>
                                <Paper elevation={3} sx={{ borderRadius: '18px', padding: 4, backgroundColor: '#DCE7FF', height: '12rem' }}>
                                    <Typography variant='Body' paragraph>Total Applications</Typography>
                                    <Typography variant='h6' paragraph>01</Typography>
                                </Paper>
                            </Grid>




                            <Grid item xs={6} md={1.5}>

                                <Paper elevation={2} sx={{ borderRadius: '18px', padding: 4, backgroundColor: '#FFDCDC', height: '12rem' }}>
                                    <Typography variant="h6" sx={{ color: 'rgba(0,0,0,1)' }} paragraph>
                                        {toProper(`${userInfo.firstName} ${userInfo.lastName}`)}
                                    </Typography>
                                    <Typography variant="Content" sx={{ color: 'rgba(22,30,84,1)' }} paragraph>
                                        {toProper(userInfo.roles[0])}
                                    </Typography>
                                </Paper>

                            </Grid>
                        </Grid>


                    </Box>


                </Grid>

                <Grid item xs={12} md={12}>
                    {/* <Paper elevation={3} sx={{ minheight: '12rem', borderRadius: '18px', padding: 2 }}>

                        <Grid container> */}

                    <Grid item xs={12} md={12}>
                        <MaterialTable />
                    </Grid>

                    {/* </Grid>

                    </Paper> */}


                </Grid>

                <Grid item xs={12} md={12} mt={2} container justifyContent="flex-end">
                    <Typography
                        variant="Content"
                        style={{
                            cursor: 'pointer',
                            color: '#0D2659',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                        onClick={handleViewMore}
                    >
                        View more <OFMoreIcon />
                    </Typography>





                </Grid>

            </Grid>
            {/* </Container> */}
        </Box>








    );
}

export default DashboardMain;
