
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, CardContent, CardMedia, Box, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { API } from '../../axios/api-path';
import { fetchData } from '../../axios/api-service';

const SuccessStories = () => {
    const [cardData, setCardData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchStories = async () => {
            const path = API.getAllSuccessStories();

            try {
                const response = await fetchData(path);
                console.log("All stories:", JSON.stringify(response.data, null, 2));
                setCardData(response.data);
            } catch (error) {
                console.error("Error fetching card details:", error);
            }
        };

        fetchStories();
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cardData.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + cardData.length) % cardData.length);
    };

    // Get the three stories to display
    const getVisibleStories = () => {
        if (cardData.length <= 3) return cardData; // Show all if less than 3
        const startIndex = currentIndex;
        const visibleStories = [];
        for (let i = 0; i < 3; i++) {
            visibleStories.push(cardData[(startIndex + i) % cardData.length]);
        }
        return visibleStories;
    };

    const visibleStories = getVisibleStories();

    return (
        <Box paddingY={4} maxWidth="lg" margin="auto">
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Typography variant="h4">Success Stories</Typography>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" textAlign="center">
                    <Typography variant="body1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </Typography>
                </Grid>

                {/* Carousel */}
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" position="relative" margin={4}>
                    <IconButton onClick={handlePrev} sx={{ position: 'absolute', left: 0, zIndex: 10 }}>
                        <ArrowBack />
                    </IconButton>

                    <Box display="flex" justifyContent="center" alignItems="center" overflow="hidden" width="100%">
                        <Box display="flex" justifyContent="center" alignItems="center" gap={6}>
                            {visibleStories.map((story, index) => {
                                const isActive = index === 1; // Center story is active
                                return (
                                    <Card
                                        key={story.id}
                                        sx={{
                                            width: isActive ? '40vh' : '30vh', // Increased width for active
                                            height: isActive ? '40vh' : '30vh', // Increased height for active
                                            borderRadius: '16px',
                                            overflow: 'hidden',
                                            boxShadow: isActive ? 4 : 1,
                                            opacity: isActive ? 1 : 0.7,
                                            transform: isActive ? 'scale(1.1)' : 'scale(1)',
                                            transition: 'all 0.3s',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                        }}
                                        onClick={() => setCurrentIndex((currentIndex + index) % cardData.length)}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="180"
                                            image={story.thumbnail?.url || 'https://via.placeholder.com/120x120'}
                                            alt={story.storyDescription || 'No description available'}
                                        />
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '16px', paddingBottom: '16px' }}>
                                            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2, textAlign: 'center' }}>
                                                {story.storyDescription || 'No description available.'}
                                            </Typography>
                                            <Typography variant="body2" align="center" sx={{ textAlign: 'center' }}>
                                                Name: {story.author || 'Unknown Author'}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                        </Box>
                    </Box>

                    <IconButton onClick={handleNext} sx={{ position: 'absolute', right: 0, zIndex: 10 }}>
                        <ArrowForward />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SuccessStories;
