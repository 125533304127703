import { Box, Paper, Typography, Grid, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { OFMailAtSign, OFCallIcon } from '../../../icons/CustomIcon'
import { ReactComponent as WhatsappIcon } from "../../../assets/icons/WhatsApp.svg"
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';
import { toProper } from '../../../utils/UtilFunctions';
import MaterialTable from '../../../helpers/tables/MaterialTable';
import { API } from '../../../axios/api-path';
import { fetchInterestDetails, fetchSingleCardDetails } from '../../../axios/api-service';
import CustomTable from '../../../helpers/tables/MaterialTable';


const Interests = () => {

    const [interestOnMe, setInterestOnMe] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchInterestDetails();
                console.error("Failed to fetch plan details." + JSON.stringify(response, null, 2));
            } catch (err) {
                console.error("Error fetching payment info:", err);
            }
        };
        fetchData();
    }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const entityCode = '123'
    //         const path = API.getInterestOnMe();
    //         try {
    //             const response = await fetchSingleCardDetails({}, path);
    //             console.error("Response", JSON.stringify(response, null, 2));
    //             if (response.status === "success" && response.data) {
    //                 setInterestOnMe(response.data);

    //             } else {
    //                 // Handle unexpected response
    //                 console.error("Unexpected response structure", response);
    //             }
    //         } catch (err) {
    //             // Handle error
    //             console.error("Error fetching data", err);
    //         }
    //     };

    //     fetchData();
    // }, []);


    const data = [
        {
            "id": 1,
            //   "businessProfileId": 1,
            "interestedUserCode": "SDD1732171156358008",
            "state": {
                "id": 5,
                "name": "Bihar"
            },
            "city": {
                "id": 12,
                "name": "Dinapur Nizamat"
            },
            "mobNo": "45656456600",
            "investementRange": null,
            "comments": "Looking for high ROI opportunities."
        },
        {
            "id": 3,
            //  "businessProfileId": 1,
            "interestedUserCode": "SDD1732171156358008",
            "state": {
                "id": 5,
                "name": "Bihar"
            },
            "city": {
                "id": 12,
                "name": "Dinapur Nizamat"
            },
            "mobNo": "45656456600",
            "investementRange": null,
            "comments": "Looking for high ROI opportunities."
        }
    ]


    const [userInfo] = useRecoilState(perInfoState);
    return (


        <Box padding={4}>


            {/* <Container maxWidth={'xl'} > */}


            <Grid container>




                <Grid item xs={12} md={12}>
                    {/* <Paper elevation={3} sx={{ minheight: '12rem', borderRadius: '18px', padding: 2 }}>

                        <Grid container> */}

                    <Grid item xs={12} md={12}>
                        <CustomTable data={data} />
                    </Grid>

                    {/* </Grid>

                    </Paper> */}


                </Grid>
            </Grid>
            {/* </Container> */}
        </Box>








    );
}

export default Interests;
