
import React, { useEffect, useState } from 'react';
import { Typography, IconButton, useMediaQuery, InputBase, Paper, ListItemText, Checkbox } from '@mui/material';
import LocationIcon from '@mui/icons-material/LocationOn';
import DropdownIcon from '@mui/icons-material/ArrowDropDown';
import CategoryIcon from '@mui/icons-material/Category';
import { oneFranHubtheme } from "../../theme/theme";
import CustomButton from '../../helpers/CustomButton';
import CustomDropDown from '../../helpers/dropdown/CustomDropdown';
import { Grid, MenuItem, Select, InputLabel, FormControl, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchCountries, fetchIndustries, fetchRange, fetchStates } from '../../axios/api-service';
import { OFDescription, OFLocation, OFRupeeIcon } from '../../icons/CustomIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Banner = () => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));


    const navigate = useNavigate();

    const [location, setLocation] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [priceRange, setPriceRange] = useState([]);


    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchStates(setStates, 1);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);

    }, []);


    // console.log(" industries=" + JSON.stringify(industries, null, 2))
    // console.log(" countries=" + JSON.stringify(countries, null, 2))
    console.log(" ranges=" + JSON.stringify(ranges, null, 2))

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handleIndustryChange = (event) => {
        setIndustry(event.target.value);
    };

    const handlePriceRangeChange = (event) => {
        setPriceRange(event.target.value);
    };

    // const handleSearch = () => {
    //     console.log('Selected Values:', {
    //         location,
    //         industry,
    //         priceRange,
    //     });
    //     // Perform search logic here
    // };
    // const handleSearch = () => {
    //     // Extract names of selected filters
    //     const locationNames = location.map(loc => loc.name);  // Using `name` for location
    //     const industryNames = industry.map(ind => ind.name);  // Using `name` for industry
    //     const priceRangeNames = priceRange.map(range => range.name);  // Using `name` for price range

    //     console.log('Selected Locations:', locationNames);
    //     console.log('Selected Industries:', industryNames);
    //     console.log('Selected Price Ranges:', priceRangeNames);
    //     navigate('/search-page', {
    //         state: { location, industry, priceRange }
    //     });
    // };
    const convertInvestmentRange = (ranges) => {
        return ranges.map((range) => [
            range.startIndex,
            range.endIndex,
        ]);
    };
    const handleSearch = () => {
        // Log the selected filters
        const locationNames = location.map(loc => loc.name);
        console.log("Checking location --> " + JSON.stringify(location, null, 2))
        const industryNames = industry.map(ind => ind.name);
        const priceRangeNames = priceRange.map(range => range.name);




        // Structure the selected filters object
        const selectedFiltersFromMain = {
            industry: industry,


            location: location,
            investmentRange: convertInvestmentRange(priceRange)
        };

        // Log the selected filters
        console.log("Constructed Filter at main =", JSON.stringify(selectedFiltersFromMain, null, 2));

        // Navigate with the selected filters
        // navigate('/search-page', {
        //     state: selectedFilters
        // });
        navigate('/search-page', {
            state: {
                ...selectedFiltersFromMain,
                mainSearch: true, // Add the flag here
            },
        });

    };

    return (
        <Grid container maxWidth={'xl'} >
            <Grid item
                sx={{
                    boxSizing: 'border-box',
                    //    paddingX: isMobile ? 2 : isTablet ? 5 : 40,
                    // paddingY: isMobile ? 0 : 2,
                    // border: '1px solid green',
                }}
            >

                <Box
                    pt={isMobile ? 0 : 3}
                    pb={isMobile ? 0 : 10}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: isMobile ? 'center' : 'flex-start', // Center items on mobile

                    }}
                >
                    <Typography
                        variant={isMobile ? 'h6' : 'h1'}
                        sx={{


                            color: '#fff',
                            textAlign: isMobile ? 'center' : 'left',
                            lineHeight: 1.2,
                            marginBottom: '0.5rem',
                        }}
                    >
                        The power behind the type
                    </Typography>
                    <Typography
                        variant={isMobile ? 'h6' : 'h1'}
                        sx={{

                            color: '#fff',
                            textAlign: isMobile ? 'center' : 'left',
                            lineHeight: 1.2,
                            marginBottom: '2rem',
                        }}
                    >
                        of customer your brands
                    </Typography>
                    <Typography
                        sx={{
                            width: isMobile ? '90%' : '80%', // Adjust width for mobile
                            fontSize: '24px',
                            lineHeight: '40px',
                            color: '#fff',
                            textAlign: isMobile ? 'center' : 'left', // Center text on mobile
                            marginBottom: isMobile ? '2rem' : '5rem',
                        }}
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                    </Typography>

                    <Box
                    // sx={{ paddingX: isMobile ? 2 : isTablet ? 5 : 10, marginTop: isMobile ? '1rem' : '0', paddingY: 4 }}
                    >
                        {isMobile ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    width: '100%',
                                    backgroundColor: '#fff',
                                    padding: '1rem',
                                    borderRadius: '10px',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
                                    border: '1px solid #d9b3da',
                                }}
                            >
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: '0.5rem',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                        border: '1px solid #d9b3da',
                                    }}
                                >
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <LocationIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    </IconButton>
                                    <InputBase
                                        placeholder="Search by Location"
                                        sx={{ ml: 1, flex: 1 }}
                                    />
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <DropdownIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </IconButton>
                                </Paper>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: '0.5rem',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                        border: '1px solid #d9b3da',
                                    }}
                                >
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <CategoryIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    </IconButton>
                                    <InputBase
                                        placeholder="Industry"
                                        sx={{ ml: 1, flex: 1 }}
                                    />
                                    <IconButton size="small" sx={{ padding: 0 }}>
                                        <DropdownIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                                    </IconButton>
                                </Paper>
                                <CustomButton
                                    type="submit"
                                    width={{ xs: '75%', sm: '14rem' }}
                                    variant="contained"
                                    label="Submit"
                                //onClick={handleSubmit(onSubmit)}
                                />
                                {/* <Button
                                    variant="contained"
                                    sx={{
                                        width: '100%',
                                        height: '3.875rem',
                                        borderRadius: '37px',
                                        background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                                        fontSize: '1.125rem',
                                        color: '#fff',
                                    }}
                                >
                                    Search
                                </Button> */}
                            </Box>
                        ) : (

                            // <Box
                            //     sx={{
                            //         width: '100%',
                            //         height: '4.5rem',
                            //         display: 'flex',
                            //         flexDirection: 'row',
                            //         alignItems: 'center',
                            //         justifyContent: 'space-between',
                            //         padding: '0.312rem 0.312rem 0.312rem 1.5rem',
                            //         borderRadius: '100px',
                            //         backgroundColor: '#fff',
                            //         border: '1px solid #d9b3da',
                            //         boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
                            //         boxSizing: 'border-box',
                            //         fontSize: '1rem',
                            //         color: '#0d2659',
                            //         fontFamily: 'Outfit',
                            //     }}
                            // >
                            //     {/* Dropdown: Search by Location */}
                            //     <Box
                            //         sx={{
                            //             display: 'flex',
                            //             flexDirection: 'row',
                            //             alignItems: 'center',
                            //             gap: '0.75rem',
                            //         }}
                            //     >
                            //         <LocationIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                            //         <Select
                            //             value={location}
                            //             onChange={(e) => setLocation(e.target.value)}
                            //             displayEmpty
                            //             sx={{
                            //                 width: '10rem',
                            //                 height: '2.5rem',
                            //                 borderRadius: '20px',
                            //                 backgroundColor: '#f5f5f5',
                            //                 paddingLeft: '0.5rem',
                            //             }}
                            //         >
                            //             <MenuItem value="">
                            //                 <em>Search by Location</em>
                            //             </MenuItem>
                            //             <MenuItem value="Location 1">Location 1</MenuItem>
                            //             <MenuItem value="Location 2">Location 2</MenuItem>
                            //         </Select>
                            //     </Box>

                            //     {/* Dropdown: Search by Industry */}
                            //     <Box
                            //         sx={{
                            //             display: 'flex',
                            //             flexDirection: 'row',
                            //             alignItems: 'center',
                            //             gap: '0.75rem',
                            //         }}
                            //     >
                            //         <CategoryIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                            //         <Select
                            //             value={industry}
                            //             onChange={(e) => setIndustry(e.target.value)}
                            //             displayEmpty
                            //             sx={{
                            //                 width: '10rem',
                            //                 height: '2.5rem',
                            //                 borderRadius: '20px',
                            //                 backgroundColor: '#f5f5f5',
                            //                 paddingLeft: '0.5rem',
                            //             }}
                            //         >
                            //             <MenuItem value="">
                            //                 <em>Industry</em>
                            //             </MenuItem>
                            //             <MenuItem value="Industry 1">Industry 1</MenuItem>
                            //             <MenuItem value="Industry 2">Industry 2</MenuItem>
                            //         </Select>
                            //     </Box>

                            //     {/* Dropdown: Search by Price Range */}
                            //     <Box
                            //         sx={{
                            //             display: 'flex',
                            //             flexDirection: 'row',
                            //             alignItems: 'center',
                            //             gap: '0.75rem',
                            //         }}
                            //     >
                            //         <CategoryIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                            //         <Select
                            //             value={industry}
                            //             onChange={(e) => setPriceRange(e.target.value)}
                            //             displayEmpty
                            //             sx={{
                            //                 width: '10rem',
                            //                 height: '2.5rem',
                            //                 borderRadius: '20px',
                            //                 backgroundColor: '#f5f5f5',
                            //                 paddingLeft: '0.5rem',
                            //             }}
                            //         >
                            //             <MenuItem value="">
                            //                 <em>Price Range</em>
                            //             </MenuItem>
                            //             <MenuItem value="Industry 1">Industry 1</MenuItem>
                            //             <MenuItem value="Industry 2">Industry 2</MenuItem>
                            //         </Select>
                            //     </Box>


                            //     {/* Search Button */}
                            //     <Button
                            //         variant="contained"
                            //         sx={{
                            //             width: '10rem',
                            //             height: '3.875rem',
                            //             borderRadius: '37px',
                            //             background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                            //             fontSize: '1.125rem',
                            //             color: '#fff',
                            //             position: 'relative',
                            //         }}
                            //         onClick={() => handleSearch(location, industry)}
                            //     >
                            //         Search
                            //     </Button>
                            // </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '4.5rem',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0.312rem 0.312rem 0.312rem 1.5rem',
                                    borderRadius: '100px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #d9b3da',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
                                    boxSizing: 'border-box',
                                    fontSize: '1rem',
                                    color: '#0d2659',
                                    fontFamily: 'Outfit',
                                }}
                            >

                                {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.75rem' }}>
                                    <LocationIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    <Select
                                        multiple
                                        value={location}
                                        onChange={handleLocationChange}
                                        displayEmpty
                                        renderValue={(selected) => selected.length === 0 ? <em>Search by Location</em> : selected.join(', ')}
                                        sx={{
                                            width: '10rem',
                                            height: '2.5rem',
                                            borderRadius: '20px',
                                            backgroundColor: '#f5f5f5',
                                            paddingLeft: '0.5rem',
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>Search by Location</em>
                                        </MenuItem>
                                        {countries.map((country) => (
                                            <MenuItem key={country.id} value={country.name}>
                                                <Checkbox checked={location.indexOf(country.name) > -1} />
                                                <ListItemText primary={country.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>

                              
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.75rem' }}>
                                    <CategoryIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    <Select
                                        multiple
                                        value={industry}
                                        onChange={handleIndustryChange}
                                        displayEmpty
                                        renderValue={(selected) => selected.length === 0 ? <em>Industry</em> : selected.join(', ')}
                                        sx={{
                                            width: '10rem',
                                            height: '2.5rem',
                                            borderRadius: '20px',
                                            backgroundColor: '#f5f5f5',
                                            paddingLeft: '0.5rem',
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>Industry</em>
                                        </MenuItem>
                                        {industries.map((industryData) => (
                                            <React.Fragment key={industryData.id}>
                                                <MenuItem value={industryData.name}>
                                                    <Checkbox checked={industry.indexOf(industryData.name) > -1} />
                                                    <ListItemText primary={industryData.name} />
                                                </MenuItem>
                                                {industryData.industrySectors.map((sector) => (
                                                    <MenuItem key={sector.id} value={sector.name}>
                                                        <Checkbox checked={industry.indexOf(sector.name) > -1} />
                                                        <ListItemText primary={sector.name} />
                                                    </MenuItem>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </Select>
                                </Box>

                                
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.75rem' }}>
                                    <CategoryIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    <Select
                                        multiple
                                        value={priceRange}
                                        onChange={handlePriceRangeChange}
                                        displayEmpty
                                        renderValue={(selected) => selected.length === 0 ? <em>Price Range</em> : selected.join(', ')}
                                        sx={{
                                            width: '10rem',
                                            height: '2.5rem',
                                            borderRadius: '20px',
                                            backgroundColor: '#f5f5f5',
                                            paddingLeft: '0.5rem',
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>Price Range</em>
                                        </MenuItem>
                                        {ranges.map((range) => (
                                            <MenuItem key={range.id} value={range.name}>
                                                <Checkbox checked={priceRange.indexOf(range.name) > -1} />
                                                <ListItemText primary={range.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box> */}

                                {/* Dropdown: Search by Location */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.75rem' }}>
                                    <OFLocation sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    <Select
                                        multiple
                                        value={location}
                                        onChange={handleLocationChange}
                                        displayEmpty
                                        renderValue={(selected) => selected.length === 0 ? <em>Location</em> : selected.map(item => item.name).join(', ')}
                                        IconComponent={ExpandMoreIcon}
                                        sx={{
                                            width: '10rem',
                                            height: '2.5rem',
                                            border: 'none', // Removes the border
                                            boxShadow: 'none', // Ensures no shadow around the select box
                                            paddingLeft: '0.5rem',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Removes the default outlined input border
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Ensures no border on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Ensures no border when focused
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            Location
                                        </MenuItem>
                                        {states.map((country) => (
                                            <MenuItem key={country.id} value={country}>
                                                <Checkbox checked={location.some(loc => loc.id === country.id)} />
                                                <ListItemText primary={country.name} />
                                            </MenuItem>
                                        ))}
                                        {/* {industries.map((country) => (
                                            <MenuItem key={country.id} value={country}>
                                                <Checkbox checked={location.some(loc => loc.id === country.id)} />
                                                <ListItemText primary={country.name} />
                                            </MenuItem>
                                        ))} */}
                                    </Select>
                                </Box>

                                {/* Dropdown: Search by Industry */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.75rem' }}>
                                    <OFDescription sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    <Select
                                        multiple
                                        value={industry}
                                        onChange={handleIndustryChange}
                                        displayEmpty
                                        renderValue={(selected) => selected.length === 0 ? <em>Industry</em> : selected.map(item => item.name).join(', ')}
                                        // sx={{
                                        //     width: '10rem',
                                        //     height: '2.5rem',
                                        //     //  borderRadius: '20px',
                                        //     //  backgroundColor: '#f5f5f5',
                                        //     paddingLeft: '0.5rem',
                                        // }}
                                        IconComponent={ExpandMoreIcon}
                                        sx={{
                                            width: '10rem',
                                            height: '2.5rem',
                                            border: 'none', // Removes the border
                                            boxShadow: 'none', // Ensures no shadow around the select box
                                            paddingLeft: '0.5rem',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Removes the default outlined input border
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Ensures no border on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Ensures no border when focused
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            Industry
                                        </MenuItem>
                                        {industries.map((country) => (
                                            <MenuItem key={country.id} value={country}>
                                                <Checkbox checked={industry.some(loc => loc.id === country.id)} />
                                                <ListItemText primary={country.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>

                                {/* Dropdown: Search by Price Range */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.75rem' }}>
                                    <OFRupeeIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                                    <Select
                                        multiple
                                        value={priceRange}
                                        onChange={handlePriceRangeChange}
                                        displayEmpty
                                        renderValue={(selected) => selected.length === 0 ? <em>Price Range</em> : selected.map(item => item.name).join(', ')}
                                        // sx={{
                                        //     width: '10rem',
                                        //     height: '2.5rem',
                                        //     //  borderRadius: '20px',
                                        //     // backgroundColor: '#f5f5f5',
                                        //     paddingLeft: '0.5rem',
                                        // }}
                                        IconComponent={ExpandMoreIcon}
                                        sx={{
                                            width: '10rem',
                                            height: '2.5rem',
                                            border: 'none', // Removes the border
                                            boxShadow: 'none', // Ensures no shadow around the select box
                                            paddingLeft: '0.5rem',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Removes the default outlined input border
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Ensures no border on hover
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none', // Ensures no border when focused
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            Price Range
                                        </MenuItem>
                                        {ranges.map((range) => (
                                            <MenuItem key={range.id} value={range}>
                                                <Checkbox checked={priceRange.some(rangeItem => rangeItem.id === range.id)} />
                                                <ListItemText primary={range.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>


                                {/* Search Button */}
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '10rem',
                                        height: '3.875rem',
                                        borderRadius: '37px',
                                        background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                                        fontSize: '1.125rem',
                                        color: '#fff',
                                        position: 'relative',
                                    }}
                                    onClick={handleSearch}
                                >
                                    Search
                                </Button>
                            </Box>


                        )}
                    </Box>
                </Box>

            </Grid >
        </Grid>
    );
};

export default Banner;
