
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Container, Paper, Button, IconButton, useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { API } from '../../../axios/api-path';
import { postApiCall, putApiCallWithCred } from '../../../axios/axios-methods';
import { toast, ToastContainer } from 'react-toastify';
import { OFEditIcon } from '../../../icons/CustomIcon';
import { fetchFormInfo } from '../../../axios/api-service';
import CustomInput from '../../../helpers/custom-input';


function ProfileDetails() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isEditing, setIsEditing] = useState(false);
    const { handleSubmit, control, formState: { errors }, reset } = useForm();
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const path = API.getProfileDetails();
            try {
                const response = await fetchFormInfo(path);
                console.log(" Response " + JSON.stringify(response, null, 2));
                if (response.status === 'success') {
                    setProfileData(response.data);
                }
            } catch (err) {
                // Handle error
                console.error(err);
            }
        };

        fetchData();
    }, []);

    const onSubmit = async (data) => {
        // const apiCallData = {
        //     firstName: data.firstName,
        //     middleName: data.middleName,
        //     lastName: data.lastName,
        //     emailId: data.emailId,
        //     mobNo: data.mobNo,
        // };

        // const path = API.updateProfileDetails();
        // try {
        //     console.log("Submitting data:", apiCallData); // Debug log
        //     await putApiCallWithCred({
        //         params: apiCallData,
        //         path: path,
        //         onSuccess: (res) => {
        //             toast.success("Profile updated successfully");
        //             reset(); // Reset fields after successful submission
        //             setIsEditing(false); // Switch to view mode
        //         },
        //         onError: (error) => {
        //             console.error('Submission error:', error);
        //             toast.error("Failed to update profile");
        //         },
        //     });
        // } catch (error) {
        //     console.error('Unexpected error:', error);
        //     toast.error("Unexpected error occurred");
        // }
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} />
            <div>
                <Box
                    paddingX={0}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1
                    }}
                >
                    <Paper
                        elevation={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            borderRadius: 4,
                            backgroundColor: '#F1F6FF'
                        }}
                    >
                        <Box padding={2}>
                            <Typography variant='h5'>Profile Details</Typography>
                            <IconButton
                                onClick={toggleEdit}
                                style={{ float: 'right' }}
                            >
                                <OFEditIcon />
                            </IconButton>

                            {isEditing ? (
                                <Box>
                                    <Container maxWidth="xl">
                                        <Box paddingY={2}>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <Grid container justifyContent="center" alignItems="left" spacing={2}>
                                                    {/* First Row: Owner Name Fields */}
                                                    <Grid item xs={12} md={4} >
                                                        <Controller
                                                            name="firstName"
                                                            control={control}
                                                            defaultValue={profileData?.firstName || ''}
                                                            rules={{ required: 'First name is required' }}
                                                            render={({ field }) => (
                                                                <CustomInput
                                                                    {...field}
                                                                    required
                                                                    label="First Name"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={!!errors.firstName}
                                                                    helperText={errors.firstName ? errors.firstName.message : ''}
                                                                    placeholder="Enter First Name"
                                                                    isViewMode={!isEditing}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4} >
                                                        <Controller
                                                            name="middleName"
                                                            control={control}
                                                            defaultValue={profileData?.middleName || ''}
                                                            render={({ field }) => (
                                                                <CustomInput
                                                                    {...field}
                                                                    label="Middle Name"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    placeholder="Enter Middle Name"
                                                                    isViewMode={!isEditing}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4} >
                                                        <Controller
                                                            name="lastName"
                                                            control={control}
                                                            defaultValue={profileData?.lastName || ''}
                                                            rules={{ required: 'Last name is required' }}
                                                            render={({ field }) => (
                                                                <CustomInput
                                                                    {...field}
                                                                    required
                                                                    label="Last Name"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={!!errors.lastName}
                                                                    helperText={errors.lastName ? errors.lastName.message : ''}
                                                                    placeholder="Enter Last Name"
                                                                    isViewMode={!isEditing}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    {/* Second Row: Email and Mobile Fields */}
                                                    <Grid item xs={12} md={6} >
                                                        <Controller
                                                            name="emailId"
                                                            control={control}
                                                            defaultValue={profileData?.emailId || ''}
                                                            render={({ field }) => (
                                                                <CustomInput
                                                                    {...field}
                                                                    label="Email"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    disabled
                                                                    placeholder="Enter Email"
                                                                    isViewMode={!isEditing}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} >
                                                        <Controller
                                                            name="mobNo"
                                                            control={control}
                                                            defaultValue={profileData?.mobNo || ''}
                                                            render={({ field }) => (
                                                                <CustomInput
                                                                    {...field}
                                                                    label="Mobile Number"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    disabled
                                                                    placeholder="Enter Mobile Number"
                                                                    isViewMode={!isEditing}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>

                                                    {/* Submit Button */}
                                                    {isEditing && (
                                                        <Grid item xs={12} md={6} mb={2} display="flex" justifyContent="center" alignItems="center">
                                                            <Grid item xs={12} md={6} mb={2}>
                                                                <Button
                                                                    type="submit"
                                                                    variant="contained"
                                                                    sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </form>
                                        </Box>
                                    </Container>
                                </Box>
                            ) : (
                                <Box paddingY={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <CustomInput
                                                value={profileData?.firstName || ''}
                                                label="First Name"
                                                variant="outlined"
                                                fullWidth
                                                isViewMode={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <CustomInput
                                                value={profileData?.middleName || ''}
                                                label="Middle Name"
                                                variant="outlined"
                                                fullWidth
                                                isViewMode={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <CustomInput
                                                value={profileData?.lastName || ''}
                                                label="Last Name"
                                                variant="outlined"
                                                fullWidth
                                                isViewMode={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CustomInput
                                                value={profileData?.emailId || ''}
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                isViewMode={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CustomInput
                                                value={profileData?.mobNo || ''}
                                                label="Mobile Number"
                                                variant="outlined"
                                                fullWidth
                                                isViewMode={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </div>
        </>
    );
}

export default ProfileDetails;
