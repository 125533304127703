

import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { RecoilRoot, useRecoilState } from 'recoil';
import Layout from './Layout';
import { isTokenValid, useTokenValidation } from './utils/AuthUtils';
import { MainPage } from './pages/landing/MainPage';
import PersonalInfo from './pages/registration/PersonalInfo';
import PropertyDetails from './pages/registration/franchisor/PropertyDetails';
import FormContainer from './pages/registration/FormContainer';
import DashboardLayout from './components/main-layout/DashboardLayout';
import CustomNavBar from './components/header/CustomNavBar';
import CustomFooter from './components/footer/CustomFooter';
import Path from './axios/route-path';
import LoginForm from './pages/signin/LoginForm';
import MultiSelectDropdown from './poc/MultiSelectDropdown';
import { AuthContext, AuthProvider } from './context/AuthContext';
import ProtectedRoute from './utils/ProtectedRoute';
import NotFoundPage from './pages/unauthorised/NotFoundPage';
import FinancialDetails from './pages/registration/franchisor/FinancialDetails';
import BusinessDetails from './pages/registration/franchisor/BusinessDetails';
import SpaceRequirementDetails from './pages/registration/franchisor/SpaceRequirementDetails';
import NeedSpace from './pages/registration/need-space/NeedSpace';
import LoanSupport from './pages/registration/loan-support/LoanProperty';
import AppearanceDetails from './pages/registration/franchisor/AppearanceDetails';

import AdvertisementDetails from './pages/registration/sell-business/SAdvertisementDetails';
import OwnerDetails from './pages/registration/sell-business/SOwnerDetails';
import PartnershipDetails from './pages/registration/partner/PartnershipDetails';

import LoanProperty from './pages/registration/loan-support/LoanProperty';
import SearchPage from './pages/search-page/SearchPage';
import SupportPage from './pages/dashboard/support/SupportPage';
import FranchiseDetailedPage from './pages/detiled-page/FranchiseDetailedPage';
import InvestorDetails from './pages/registration/investor/InvestorDetails';
import MainPagePoc from './poc/MainPagePoc';
import OFNavbar from './components/header/OFNavbar';
import OFFooter from './components/footer/OFFooter';
import MainCard from './pages/landing/MainCard';
import RegistrationLayout from './components/main-layout/RegistrationLayout';
import OFCustomNavbar from './components/header/OFCustomNavbar';
import ContactUs from './components/footer/ContactUs';
import { MainLayout } from './components/main-layout/MainLayout';
import AboutUs from './components/footer/AboutUs';
import DashboardMain from './pages/dashboard/home/DashboardMain';
import IdeaPage from './pages/idea-page/IdeaPage';
import Inquiries from './pages/dashboard/inquiries/Inquiries';
import SettingsPage from './pages/dashboard/settings/SettingsPage';
import SBusinessDetails from './pages/registration/sell-business/SBusinessDetails';
import SFinancialDetails from './pages/registration/sell-business/SFinancialDetails';
import SAdvertisementDetails from './pages/registration/sell-business/SAdvertisementDetails';
import Wishlist from './pages/dashboard/wishlist/Wishlist';
import ViewHistory from './pages/dashboard/history/ViewHistory';
import PaymentInfo from './pages/registration/PaymentInfo';
import IdleTimerContainer from './helpers/IdleTimerContainer';
import { isLoggedInState } from './recoil/profile-atom';
import BCCompanyAndGoalInfo from './pages/registration/business-collaboration/BCCompanyAndGoalInfo';
import BCFinancialInfoAndGrowth from './pages/registration/business-collaboration/BCFinancialInfoAndGrowth';
import BCMentorshipAndAdvisory from './pages/registration/business-collaboration/BCMentorshipAndAdvisory';
import BCLegalAndRegulatory from './pages/registration/business-collaboration/BCLegalAndRegulatory';
import BCAppearanceAndMedia from './pages/registration/business-collaboration/BCAppearanceAndMedia';
import DealerBusinessInfo from './pages/registration/dealer/DealerBusinessInfo';
import DealerMarketingInfo from './pages/registration/dealer/DealerMarketingInfo';
import DealerTrainingAndOperation from './pages/registration/dealer/DealerTrainingAndOperation';
import DealerAppearanceInfo from './pages/registration/dealer/DealerAppearanceInfo';
import DealerMarketingDetails from './pages/registration/dealer/DealerMarketingDetails';
import SBusinessDetailsForm from './pages/registration/sell-business/SBusinessDetailsForm';
import AppearanceAndMedia from './pages/registration/franchisor/AppearanceAndMedia';
import BusinessOnSaleDetailedPage from './pages/detiled-page/BusinessOnSaleDetailedPage';
import BusinessCollaborationDetailedPage from './pages/detiled-page/BusinessCollaborationDetailedPage';
import DealersDetailedPage from './pages/detiled-page/DealersDetailedPage';
import ScrollToTop from './utils/ScrollToTop';
import Interests from './pages/dashboard/interests/Interests';
import Idea from './pages/dashboard/ideas/Idea';
import SuccessStory from './pages/dashboard/success-stories/SuccessStory';
import IdeaList from './pages/dashboard/ideas/IdeaList';
import SuccessStoryList from './pages/dashboard/success-stories/SuccessStoryList';








const SimpleLayout = ({ children }) => (
  <>
    {children}
  </>
);

function App() {



  const { logout } = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn);


  // logout function
  const handleLogout = () => {
    console.log(" New implemntation logout called ")
    logout();
    setIsAuthenticated(false);
  };


  return (
    <>

      <>


        <IdleTimerContainer props={{ isAuthenticated, handleLogout }} />

        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Navigate to="/landing" />} />
            <Route path="/landing" element={<MainLayout><MainPagePoc /></MainLayout>} />
            <Route path="/contact-us" element={<MainLayout><ContactUs /></MainLayout>} />
            <Route path="/about-us" element={<MainLayout><AboutUs /></MainLayout>} />


            <Route path="/unauthorised" element={<MainLayout><NotFoundPage /></MainLayout>} />

            <Route path='/franchise/business-page' element={<MainLayout><FranchiseDetailedPage /></MainLayout>} />
            <Route path='/bseller/business-page' element={<MainLayout><BusinessOnSaleDetailedPage /></MainLayout>} />
            <Route path='/partner/business-page' element={<MainLayout><BusinessCollaborationDetailedPage /></MainLayout>} />
            <Route path='/dealer/business-page' element={<MainLayout><DealersDetailedPage /></MainLayout>} />

            <Route path="/search-page" element={<MainLayout><SearchPage /></MainLayout>} />

            {/* <Route path="/franchise/search-page" element={<MainLayout><SearchPage entityType={'FRANCHISER'} /></MainLayout>} />
            <Route path="/partner/search-page" element={<MainLayout><SearchPage entityType={'BUSINESS_COLLAB'} /></MainLayout>} />
            <Route path="/bseller/search-page" element={<MainLayout><SearchPage entityType={'BUSINESS_SALE'} /></MainLayout>} />
            <Route path="/dealer/search-page" element={<MainLayout><SearchPage entityType={'DEALER'} /></MainLayout>} /> */}
            <Route path="/idea-page" element={<MainLayout><IdeaPage /></MainLayout>} />




            <Route path="/need-space" element={<MainLayout><FormContainer customComponent={<NeedSpace user={'franchiser'} title={'Need a space'} />} /></MainLayout>} />
            <Route path="/loan-support" element={<MainLayout><FormContainer customComponent={<LoanProperty user={'franchiser'} title={'Loan on Property'} />} /></MainLayout>} />


            {/* <Route path="/franchise/registration/step/:stepNumber" element={<FormStepUtil />} /> */}

            {/* Shared Personal Info Step */}


            {/* Franchise Steps */}
            <Route path="/franchise/registration/step/1" element={<MainLayout><FormContainer customComponent={


              <PersonalInfo user={'franchiser'} title={'Franchisor Registration'} />


            } /></MainLayout>} />
            <Route
              path="/franchise/registration/step/2"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BusinessDetails isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['FRANCHISER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/franchise/registration/step/3"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<PropertyDetails isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['FRANCHISER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/franchise/registration/step/4"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<FinancialDetails isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['FRANCHISER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/franchise/registration/step/5"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<SpaceRequirementDetails isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['FRANCHISER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/franchise/registration/step/6"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<AppearanceAndMedia isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['FRANCHISER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/franchise/registration/step/7"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<PaymentInfo isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['FRANCHISER', 'ADMIN']}
                />
              }
            />




            {/* Investor Steps */}

            <Route path="/investor/registration/step/1" element={<MainLayout><FormContainer customComponent={


              <PersonalInfo user={'investor'} title={'Investor Registration'} />


            } /></MainLayout>} />



            <Route
              path="/investor/registration/step/2"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<InvestorDetails isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['INVESTOR', 'ADMIN']}
                />
              }
            />


            <Route
              path="/investor/registration/step/3"
              element={
                <MainLayout><FormContainer customComponent={<PaymentInfo isDashboardMode={false} />} /></MainLayout>


              }
            />



            {/* Dealer Steps */}

            <Route path="/dealer/registration/step/1" element={<MainLayout><FormContainer customComponent={<PersonalInfo user={'dealer'} title={'Dealer / Distributor Registration'} />} /></MainLayout>} />
            <Route
              path="/dealer/registration/step/2"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<DealerBusinessInfo />} /></MainLayout>}
                  allowedRoles={['DEALER', 'ADMIN']}
                  step={2} role="DEALER"
                />
              }
            />
            <Route
              path="/dealer/registration/step/3"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<DealerMarketingDetails />} /></MainLayout>}
                  allowedRoles={['DEALER', 'ADMIN']}
                  step={3} role="DEALER"
                />
              }
            />
            <Route
              path="/dealer/registration/step/4"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<DealerTrainingAndOperation />} /></MainLayout>}
                  allowedRoles={['DEALER', 'ADMIN']}
                  step={4} role="DEALER"
                />
              }
            />
            <Route
              path="/dealer/registration/step/5"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<DealerAppearanceInfo />} /></MainLayout>}
                  allowedRoles={['DEALER', 'ADMIN']}
                  step={5} role="DEALER"
                />
              }
            />
            <Route
              path="/dealer/registration/step/6"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<PaymentInfo isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['DEALER', 'ADMIN']}
                  step={6} role="DEALER"
                />
              }
            />




            {/* Partner Steps */}
            <Route path="/partner/registration/step/1" element={<MainLayout><FormContainer customComponent={<PersonalInfo user={'bcollab'} title={'Partnerships Registration'} />} /></MainLayout>} />
            <Route
              path="/partner/registration/step/2"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCCompanyAndGoalInfo />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/partner/registration/step/3"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCFinancialInfoAndGrowth />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/partner/registration/step/4"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCMentorshipAndAdvisory />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/partner/registration/step/5"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCLegalAndRegulatory />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/partner/registration/step/6"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCAppearanceAndMedia />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/partner/registration/step/7"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<PaymentInfo isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            {/* Sell My Business Steps */}
            <Route path="/sell-business/registration/step/1" element={<MainLayout><FormContainer customComponent={<PersonalInfo user={'BusinessSeller'} title={'Business on Sales'} />} /></MainLayout>} />
            <Route
              path="/sell-business/registration/step/2"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<OwnerDetails />} /></MainLayout>}
                  allowedRoles={['BSELLER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/sell-business/registration/step/3"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<SBusinessDetails />} /></MainLayout>}
                  allowedRoles={['BSELLER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/sell-business/registration/step/4"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<SFinancialDetails />} /></MainLayout>}
                  allowedRoles={['BSELLER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/sell-business/registration/step/5"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<SAdvertisementDetails />} /></MainLayout>}
                  allowedRoles={['BSELLER', 'ADMIN']}
                />
              }
            />
            <Route
              path="/sell-business/registration/step/6"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<PaymentInfo isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['BSELLER', 'ADMIN']}
                />
              }
            />



            {/* Business Collaboration */}

            <Route path="/business-collaboration/registration/step/1" element={<MainLayout><FormContainer customComponent={<PersonalInfo user={'bcollab'} title={'Business Collaboration Registration'} />} /></MainLayout>} />
            <Route
              path="/business-collaboration/registration/step/2"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCCompanyAndGoalInfo />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/business-collaboration/registration/step/3"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCFinancialInfoAndGrowth />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/business-collaboration/registration/step/4"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCMentorshipAndAdvisory />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/business-collaboration/registration/step/5"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCLegalAndRegulatory />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/business-collaboration/registration/step/6"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<BCAppearanceAndMedia />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />
            <Route
              path="/business-collaboration/registration/step/7"
              element={
                <ProtectedRoute
                  element={<MainLayout><FormContainer customComponent={<PaymentInfo isDashboardMode={false} />} /></MainLayout>}
                  allowedRoles={['BCOLLAB', 'ADMIN']}
                />
              }
            />

            {/* <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="personal-info" element={<PersonalInfo />} />
          <Route path="business-industry" element={<BusinessDetails />} />
       
        </Route> */}

            {/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              element={<SimpleLayout><DashboardLayout /></SimpleLayout>}
              allowedRoles={['FRANCHISER']}
            />
          }
        /> */}



            <Route path="/dashboard" element={<Navigate to="/dashboard/home" />} />

            <Route
              path="/dashboard"
              element={
                <ProtectedRoute
                  element={
                    <SimpleLayout>
                      <DashboardLayout />
                    </SimpleLayout>
                  }
                  allowedRoles={['FRANCHISER', 'INVESTOR', 'BSELLER', 'DEALER', 'BCOLLAB', 'ADMIN']}
                />
              }
            >
              <Route path="home" element={<DashboardMain />} />
              <Route path="inquiries" element={<Inquiries />} />
              <Route path="interests" element={<Interests />} />
              <Route path="ideas" element={<IdeaList />} />
              <Route path="success-stories" element={<SuccessStoryList />} />

              <Route path="personal-info" element={<PersonalInfo isDashboardMode={true} />} />

              <Route path="franchise/business-info" element={<BusinessDetails isDashboardMode={true} />} />
              <Route path="franchise/expansion-info" element={<PropertyDetails isDashboardMode={true} />} />
              <Route path="franchise/financial-info" element={<FinancialDetails isDashboardMode={true} />} />
              <Route path="franchise/space-info" element={<SpaceRequirementDetails isDashboardMode={true} />} />
              <Route path="franchise/appearance-info" element={<AppearanceAndMedia isDashboardMode={true} />} />

              <Route path="investor-details" element={<InvestorDetails isDashboardMode={true} />} />

              <Route path='dealer/business-info' element={<DealerBusinessInfo isDashboardMode={true} />} />
              <Route path='dealer/market-info' element={<DealerMarketingInfo isDashboardMode={true} />} />
              <Route path='dealer/operation-info' element={<DealerTrainingAndOperation isDashboardMode={true} />} />
              <Route path='dealer/appearance-info' element={<DealerAppearanceInfo isDashboardMode={true} />} />



              <Route path='partnership/company-info' element={<BCCompanyAndGoalInfo isDashboardMode={true} />} />
              <Route path='partnership/financial-info' element={<BCFinancialInfoAndGrowth isDashboardMode={true} />} />
              <Route path='partnership/mentorship-info' element={<BCMentorshipAndAdvisory isDashboardMode={true} />} />
              <Route path='partnership/legal-info' element={<BCLegalAndRegulatory isDashboardMode={true} />} />
              <Route path='partnership/appearance-info' element={<BCAppearanceAndMedia isDashboardMode={true} />} />


              <Route path='seller/owner-info' element={<OwnerDetails isDashboardMode={true} />} />
              <Route path='seller/business-info' element={<SBusinessDetails isDashboardMode={true} />} />
              <Route path='seller/financial-info' element={<SFinancialDetails isDashboardMode={true} />} />
              <Route path='seller/advertisement-info' element={<SAdvertisementDetails isDashboardMode={true} />} />


              <Route path="payment" element={<PaymentInfo isDashboardMode={true} />} />



              <Route path="wishlist" element={<Wishlist isDashboardMode={true} />} />
              <Route path="history" element={<ViewHistory isDashboardMode={true} />} />
              <Route path="support" element={<SupportPage isDashboardMode={true} />} />
              <Route path="settings" element={<SettingsPage isDashboardMode={true} />} />

              <Route path="franchise/registration/step/1"
                element={
                  <PersonalInfo user={'franchiser'} title={'Franchisor Registration'} />
                } />

              <Route
                path="franchise/registration/step/2"
                element={
                  <ProtectedRoute
                    element={<BusinessDetails isDashboardMode={false} />}
                    allowedRoles={['FRANCHISER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="franchise/registration/step/3"
                element={
                  <ProtectedRoute
                    element={<PropertyDetails isDashboardMode={false} />}
                    allowedRoles={['FRANCHISER', 'ADMIN']}
                  />
                }
              />

              <Route
                path="franchise/registration/step/4"
                element={
                  <ProtectedRoute
                    element={<FinancialDetails isDashboardMode={false} />}
                    allowedRoles={['FRANCHISER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="franchise/registration/step/5"
                element={
                  <ProtectedRoute
                    element={<SpaceRequirementDetails isDashboardMode={false} />}
                    allowedRoles={['FRANCHISER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="franchise/registration/step/6"
                element={
                  <ProtectedRoute
                    element={<AppearanceAndMedia isDashboardMode={false} />}
                    allowedRoles={['FRANCHISER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="franchise/registration/step/7"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['FRANCHISER', 'ADMIN']}
                  />
                }
              />

              {/* Investor Steps */}

              <Route path="investor/registration/step/1"
                element={
                  <PersonalInfo user={'investor'} title={'Investor Registration'} />
                } />
              <Route
                path="investor/registration/step/2"
                element={
                  <ProtectedRoute
                    element={<InvestorDetails isDashboardMode={false} />}
                    allowedRoles={['INVESTOR', 'ADMIN']}
                  />
                }
              />
              <Route
                path="investor/registration/step/2"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['INVESTOR', 'ADMIN']}
                  />
                }
              />

              {/* Dealer Steps */}

              <Route path="dealer/registration/step/1"
                element={<PersonalInfo user={'dealer'} title={'Dealer / Distributor Registration'} />
                } />
              <Route
                path="dealer/registration/step/2"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['DEALER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="dealer/registration/step/3"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['DEALER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="dealer/registration/step/4"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['DEALER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="dealer/registration/step/5"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['DEALER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="dealer/registration/step/6"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['DEALER', 'ADMIN']}
                  />
                }
              />


              {/* Partner Steps */}
              <Route path="partner/registration/step/1"
                element={<PersonalInfo user={'bcollab'} title={'Partnerships Registration'} />}

              />
              <Route
                path="partner/registration/step/2"
                element={
                  <ProtectedRoute
                    element={<BCCompanyAndGoalInfo isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="partner/registration/step/3"
                element={
                  <ProtectedRoute
                    element={<BCFinancialInfoAndGrowth isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="partner/registration/step/4"
                element={
                  <ProtectedRoute
                    element={<BCMentorshipAndAdvisory isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="partner/registration/step/5"
                element={
                  <ProtectedRoute
                    element={<BCLegalAndRegulatory isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="partner/registration/step/6"
                element={
                  <ProtectedRoute
                    element={<BCAppearanceAndMedia isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="partner/registration/step/7"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />


              {/* Sell My Business Steps */}
              <Route path="sell-business/registration/step/1"
                element={<PersonalInfo user={'BusinessSeller'} title={'Business on Sales'} />}
              />
              <Route
                path="sell-business/registration/step/2"
                element={
                  <ProtectedRoute
                    element={<OwnerDetails isDashboardMode={false} />}
                    allowedRoles={['BSELLER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="sell-business/registration/step/3"
                element={
                  <ProtectedRoute
                    element={<SBusinessDetails isDashboardMode={false} />}
                    allowedRoles={['BSELLER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="sell-business/registration/step/4"
                element={
                  <ProtectedRoute
                    element={<SFinancialDetails isDashboardMode={false} />}
                    allowedRoles={['BSELLER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="sell-business/registration/step/5"
                element={
                  <ProtectedRoute
                    element={<SAdvertisementDetails isDashboardMode={false} />}
                    allowedRoles={['BSELLER', 'ADMIN']}
                  />
                }
              />
              <Route
                path="sell-business/registration/step/6"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['BSELLER', 'ADMIN']}
                  />
                }
              />

              {/* Business Collaboration */}

              <Route path="business-collaboration/registration/step/1"
                element={<PersonalInfo user={'bcollab'} title={'Business Collaboration Registration'} />}
              />
              <Route
                path="business-collaboration/registration/step/2"
                element={
                  <ProtectedRoute
                    element={<BCCompanyAndGoalInfo isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="business-collaboration/registration/step/3"
                element={
                  <ProtectedRoute
                    element={<BCFinancialInfoAndGrowth isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="business-collaboration/registration/step/4"
                element={
                  <ProtectedRoute
                    element={<BCMentorshipAndAdvisory isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="business-collaboration/registration/step/5"
                element={
                  <ProtectedRoute
                    element={<BCLegalAndRegulatory isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="business-collaboration/registration/step/6"
                element={
                  <ProtectedRoute
                    element={<BCAppearanceAndMedia isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
              <Route
                path="business-collaboration/registration/step/7"
                element={
                  <ProtectedRoute
                    element={<PaymentInfo isDashboardMode={false} />}
                    allowedRoles={['BCOLLAB', 'ADMIN']}
                  />
                }
              />
            </Route>


            {/* Add other routes here */}
          </Routes>
        </Router>

      </>


    </>
  );
}

export default App;
