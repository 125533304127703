

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../theme/theme';
import { API } from '../../axios/api-path';
import CustomButton from '../../helpers/CustomButton';
import { postApiCallWithCred } from '../../axios/axios-methods';
import { toastError, toastSuccess } from '../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../axios/api-service';
import PaymentInfoForm from './PaymentInfoForm';

const PaymentInfo = ({ isDashboardMode = true }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();
    const location = useLocation();

    const [profile, setProfile] = useState(null);
    const [isBusinessProfile, setIsBusinessProfile] = useState(false);
    const [outletStates, setOutletStates] = useState([])




    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
        const fetchData = async () => {
            try {
                const response = await fetchBusinessProfile();

                if (response.data.completed === true) {
                    setProfile(response.data);

                    setIsBusinessProfile(true);
                } else {
                    setIsBusinessProfile(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);

    const validationSchema = yup.object({
        gstNo: yup.string().required('GST NO is required'),

    });

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
    }
        = watch({ control });








    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);

    // useEffect(() => {
    //     fetchCountries(setCountries);
    //     fetchIndustries(setIndustries);
    //     fetchRange(setRanges);
    // }, []);

    useEffect(() => {


        if (selectedCountry) {


            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);

    const transformData = (data) => {
        return data.map(item => ({
            value: item.id,
            label: item.name
        }));
    };
    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }

    // const transformedNoOfFranchise = (data) => data.map(item => ({ value: item.id, label: item.indexTitle }));
    const transformedNoOfFranchise = (data) => {

        return data.map(item => ({
            id: item.id,
            name: item.indexTitle
        }));
    };

    const transformedCommencedYear = (data) => {

        const newValue = [{
            id: data,
            name: data
        }];


        return newValue
    };


    useEffect(() => {
        if (profile) {

            reset({

                gstNo: profile?.gstNo || ''
            });
        }
    }, [profile, reset]);



    const [selectedPlan, setSelectedPlan] = useState(null);

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    const onSubmit = async (data) => {

        console.log("Submit clicked");
        console.log("Selected Plan:", JSON.stringify(selectedPlan, null, 2));

        const apiCallData = {
            planId: selectedPlan?.planId,
            variantId: selectedPlan?.variantId,
            entityType: selectedPlan?.entityType,
            gstNo: data?.gstNo,
        };
        fetchOrderDetails(apiCallData);

    };





    useEffect(() => {
        // Verify Razorpay is loaded
        if (!window.Razorpay) {
            console.error("Razorpay SDK not loaded");
            toastError("Payment gateway is not available. Please try again later.");
        }
    }, []);












    const [orderDetails, setOrderDetails] = useState(null);



    const fetchOrderDetails = async (data) => {
        try {
            const path = API.createPaymentOrder();
            console.log("Inside order Plan:", JSON.stringify(data, null, 2));

            await postApiCallWithCred({
                data: data,
                path: path,
                onSuccess: (res) => {
                    console.log(" Order created details " + JSON.stringify(res, null, 2))
                    // if (res?.data?.status === "success") {
                    if (res?.status === "success") {
                        const order = res.data;
                        setOrderDetails(order);
                        openRazorpay(order); // Pass the order directly to Razorpay
                    } else {
                        console.error("Order creation failed:", res?.data?.message);
                        toastError(res?.data?.message || "Order creation failed.");
                    }
                },
                onError: (error) => {
                    console.error("Error creating order:", error);
                    toastError(error?.message || "Failed to create order.");
                },
            });
        } catch (error) {
            console.error("Unexpected error:", error);
            toastError("An unexpected error occurred while creating the order.");
        }
    };

    // const openRazorpay = (order) => {
    //     const options = {
    //         key: "rzp_test_2f3MyoLe5Xdgw8",
    //         amount: order.amountSentToRazorPay, // Amount in paise
    //         currency: "INR",
    //         name: "Onefranhub",
    //         description: `Payment for Order #${order.orderId}`,
    //         order_id: order.paymentGatewayOrderId,
    //         handler: async (response) => {
    //             console.log("Payment successful:", response);
    //             console.log(" payment response " + JSON.stringify(response, null, 2))
    //             const paymentResponse = await sendPaymentDetailsToBackend({
    //                 orderId: order.orderId,
    //                 mobileNumber: "9555953402", // Replace with user's mobile number
    //                 emailId: "customer@example.com", // Replace with user's email
    //                 amount: (order.amountSentToRazorPay / 100).toFixed(2),
    //                 razorpayPaymentId: response.razorpay_payment_id,
    //                 razorpayOrderId: response.razorpay_order_id,
    //                 razorpaySignature: response.razorpay_signature,
    //                 status: response.status,
    //             });
    //             // console.log(" payment check " + JSON.stringify(paymentResponse, null, 2))
    //             // if (paymentResponse?.status === "SUCCESS") {
    //             //     window.location.href = "/payment-success";
    //             // } else {
    //             //     console.log("Payment capturing failed. Please contact support.");
    //             // }
    //         },
    //         prefill: {
    //             name: "Customer Name",
    //             email: "customer@example.com",
    //             contact: "9999999999",
    //         },
    //         theme: {
    //             color: "#3399cc",
    //         },
    //     };

    //     const razorpayInstance = new window.Razorpay(options);

    //     razorpayInstance.on("payment.failed", async (response) => {
    //         console.error("Payment failed:", response);

    //         await sendPaymentDetailsToBackend({
    //             orderId: order.orderId,
    //             mobileNumber: "9555953402",
    //             emailId: "customer@example.com",
    //             amount: (order.amountSentToRazorPay / 100).toFixed(2),
    //             razorpayPaymentId: response.error.metadata.payment_id || null,
    //             razorpayOrderId: response.error.metadata.order_id || null,
    //             razorpaySignature: null,
    //             status: "FAILED",
    //         });

    //         toastError("Payment failed. Please try again.");
    //     });

    //     razorpayInstance.open();
    // };

    // const sendPaymentDetailsToBackend = async (paymentData) => {
    //     try {
    //         const path = API.postPayment();
    //         let result = null;

    //         await postApiCallWithCred({
    //             data: paymentData,
    //             path: path,
    //             onSuccess: (res) => {
    //                 console.log("Full Response Object:", JSON.stringify(res, null, 2));
    //                 if (res?.data?.status === "success") {
    //                     result = res.data;
    //                 } else {
    //                     console.error("Failed to capture payment:", res?.data?.message);
    //                     toastError(res?.data?.message || "Unknown error occurred.");
    //                 }
    //             },
    //             onError: (error) => {
    //                 console.error("Error sending payment details to backend:", error);
    //                 toastError(error?.message || "Failed to send payment details.");
    //             },
    //         });

    //         return result;
    //     } catch (error) {
    //         console.error("Unexpected error:", error);
    //         toastError("An unexpected error occurred while sending payment details.");
    //     }
    // };
    const openRazorpay = (order) => {
        const options = {
            key: "rzp_test_2f3MyoLe5Xdgw8",
            amount: order.amountSentToRazorPay, // Amount in paise
            currency: "INR",
            name: "Onefranhub",
            description: `Payment for Order #${order.orderId}`,
            order_id: order.paymentGatewayOrderId,
            handler: async (response) => {
                console.log("Payment successful:", response);
                console.log("Payment response:", JSON.stringify(response, null, 2));

                const paymentResponse = await sendPaymentDetailsToBackend({
                    orderId: order.orderId,
                    mobileNumber: "9555953402", // Replace with user's mobile number
                    emailId: "customer@example.com", // Replace with user's email
                    amount: (order.amountSentToRazorPay / 100).toFixed(2),
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    status: "SUCCESS", // Razorpay handler success always sets to "SUCCESS"
                });

                if (paymentResponse?.status === "success") {
                    console.log("Payment captured successfully:", paymentResponse);
                    toastSuccess("Payment successful! Redirecting...");
                    // setTimeout(() => {
                    //     window.location.href = "/payment-success";
                    // }, 2000); // Add a delay for better user experience
                } else {
                    console.error("Payment capturing failed:", paymentResponse?.message);
                    toastError(paymentResponse?.message || "Payment capturing failed. Please contact support.");
                }
            },
            prefill: {
                name: "Customer Name",
                email: "customer@example.com",
                contact: "9999999999",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const razorpayInstance = new window.Razorpay(options);

        razorpayInstance.on("payment.failed", async (response) => {
            console.error("Payment failed:", response);

            await sendPaymentDetailsToBackend({
                orderId: order.orderId,
                mobileNumber: "9555953402",
                emailId: "customer@example.com",
                amount: (order.amountSentToRazorPay / 100).toFixed(2),
                razorpayPaymentId: response.error.metadata.payment_id || null,
                razorpayOrderId: response.error.metadata.order_id || null,
                razorpaySignature: null,
                status: "FAILED",
            });

            toastError("Payment failed. Please try again.");
        });

        razorpayInstance.open();
    };

    const sendPaymentDetailsToBackend = async (paymentData) => {
        try {
            const path = API.postPayment();
            let result = null;

            await postApiCallWithCred({
                data: paymentData,
                path: path,
                onSuccess: (res) => {
                    console.log("API.postPayment response:", JSON.stringify(res, null, 2));
                    if (res?.status === "success") {
                        toastSuccess(res.message || "Payment was successful.");
                        result = res;
                    } else {
                        console.error("Failed to capture payment:", res);
                        toastError(res?.data?.message || "Unknown error occurred.");
                    }
                },
                onError: (error) => {
                    console.error("Error sending payment details to backend:", error);
                    toastError(error?.message || "Failed to send payment details.");
                },
            });

            return result;
        } catch (error) {
            console.error("Unexpected error:", error);
            toastError("An unexpected error occurred while sending payment details.");
        }
    };




    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '3'));
    };
    const handleBack = () => {
        // Extract the last segment of the URL
        const currentStep = parseInt(location.pathname.split('/').pop(), 10);

        // Decrement the current step by 1
        const previousStep = Math.max(1, currentStep - 1); // Ensure step doesn't go below 1

        // Replace the last segment with the decremented step
        const updatedPath = location.pathname.replace(/[^/]*$/, previousStep);

        // Navigate to the updated path
        console.log("Navigating to step -->", updatedPath);
        navigate(updatedPath);
    };

    // const handleBack = () => {
    //     navigate(location.pathname.replace(/[^/]*$/, '6'));
    // };
    // const handleBack = () => {
    //     let targetStep;
    //     switch (selectedPlan?.entityType) {
    //         case 'FRANCHISER':
    //             targetStep = '6';
    //             break;
    //         case 'BCOLLAB':
    //             targetStep = '7';
    //             break;
    //         case 'DEALER':
    //             targetStep = '5';
    //             break;
    //         case 'INVESTOR':
    //             targetStep = '3';
    //             break;
    //         case 'BSELLER':
    //             targetStep = '6';
    //             break;

    //     }
    //     console.log(" step  --> " + location.pathname.replace(/[^/]*$/, targetStep))
    //     navigate(location.pathname.replace(/[^/]*$/, targetStep));
    // };



    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 2,
                    //  margin: 4,
                }}
            >
                <PaymentInfoForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}


                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    onPlanSelect={handlePlanSelect}

                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Submit"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default PaymentInfo