
import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Checkbox,
    FormControlLabel,
    Slider,
    Radio,
    RadioGroup,
    Collapse,
    TextField,
    Typography,
    IconButton,
    Divider,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const SearchPageForm = ({ industries, categories, locations, onFilterChange }) => {
    const [selectedIndustries, setSelectedIndustries] = useState({});
    const [selectedSectors, setSelectedSectors] = useState({});
    const [investmentRange, setInvestmentRange] = useState([0, 9000000]);
    const [selectedCategory, setSelectedCategory] = useState('1');
    const [selectedLocations, setSelectedLocations] = useState({});
    const [expanded, setExpanded] = useState({ category: true, investment: true });
    const [expandedSectors, setExpandedSectors] = useState({});

    useEffect(() => {
        // Construct a filters object to send to parent
        const constructFilters = () => {
            return {
                industry: industries
                    .filter((industry) => selectedIndustries[industry.id])
                    .map((industry) => ({ id: industry.id, name: industry.name })),
                sector: industries
                    .flatMap((industry) =>
                        industry.industrySectors
                            .filter((sector) => selectedSectors[sector.id])
                            .map((sector) => ({ id: sector.id, name: sector.name }))
                    ),
                category: categories.find((cat) => cat.id === selectedCategory) || {},
                location: locations
                    .filter((location) => selectedLocations[location.id])
                    .map((location) => ({ id: location.id, name: location.name })),
                investmentRange,
            };
        };

        onFilterChange(constructFilters());
    }, [selectedIndustries, selectedSectors, selectedCategory, selectedLocations, investmentRange]);

    const handleExpand = (section) => {
        setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    const handleExpandSectors = (industryId) => {
        setExpandedSectors((prev) => ({
            ...prev,
            [industryId]: !prev[industryId],
        }));
    };

    const handleIndustryChange = (industryId, isChecked) => {
        setSelectedIndustries((prev) => ({
            ...prev,
            [industryId]: isChecked,
        }));

        if (!isChecked) {
            setSelectedSectors((prev) => {
                const updatedSectors = { ...prev };
                industries
                    .find((industry) => industry.id === industryId)
                    .industrySectors.forEach((sector) => {
                        updatedSectors[sector.id] = false;
                    });
                return updatedSectors;
            });
        }
    };

    const handleSectorChange = (industryId, sectorId, isChecked) => {
        setSelectedSectors((prev) => ({
            ...prev,
            [sectorId]: isChecked,
        }));

        const industry = industries.find((ind) => ind.id === industryId);
        const hasSelectedSectors = industry.industrySectors.some(
            (sector) => selectedSectors[sector.id] || sector.id === sectorId && isChecked
        );

        setSelectedIndustries((prev) => ({
            ...prev,
            [industryId]: hasSelectedSectors,
        }));
    };

    const handleInvestmentRangeChange = (newValue) => {
        setInvestmentRange(newValue);
    };

    const handleCategoryChange = (categoryId) => {
        setSelectedCategory(categoryId);
    };

    const handleLocationChange = (locationId, isChecked) => {
        setSelectedLocations((prev) => ({
            ...prev,
            [locationId]: isChecked,
        }));
    };

    return (
        <Grid container>
            {/* Left Panel */}
            <Grid item xs={12} sx={{ borderRight: '1px solid #ddd' }}>
                <Box mt={5}
                >
                    <Box mb={3} mt={2}>
                        <Typography variant="TITLE3" gutterBottom>
                            Filters
                        </Typography>

                    </Box>
                    <Divider />
                    <Box mb={2} >


                    </Box>
                    {/* Industry Section */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="TITLE3">Industry</Typography>
                            <IconButton onClick={() => handleExpand('industry')}>
                                {expanded.industry ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={expanded.industry}>
                            <Box sx={{ ml: 1 }}>
                                {industries.map((industry) => (
                                    <Box key={industry.id}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedIndustries[industry.id] || false}
                                                        onChange={(e) =>
                                                            handleIndustryChange(industry.id, e.target.checked)
                                                        }
                                                    />
                                                }
                                                label={industry.name}
                                            />
                                            <IconButton onClick={() => handleExpandSectors(industry.id)}>
                                                {expandedSectors[industry.id] ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        </Box>
                                        <Collapse in={expandedSectors[industry.id]}>
                                            <Box sx={{ ml: 3 }}>
                                                {industry.industrySectors.map((sector) => (
                                                    <Box sx={{ ml: 1 }} key={sector.id}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedSectors[sector.id] || false}
                                                                    onChange={(e) =>
                                                                        handleSectorChange(industry.id, sector.id, e.target.checked)
                                                                    }
                                                                />
                                                            }
                                                            label={sector.name}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Collapse>
                                    </Box>
                                ))}
                            </Box>
                        </Collapse>
                    </Grid>

                    {/* Investment Range Section */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="TITLE3">Investment Range</Typography>
                            <IconButton onClick={() => handleExpand('investment')}>
                                {expanded.investment ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={expanded.investment}>
                            <Box sx={{ ml: 0 }}>
                                <Slider
                                    value={investmentRange}
                                    onChange={(e, newValue) => handleInvestmentRangeChange(newValue)}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={90000000}
                                />
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Min"
                                            value={investmentRange[0]}
                                            onChange={(e) =>
                                                handleInvestmentRangeChange([Number(e.target.value), investmentRange[1]])
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Max"
                                            value={investmentRange[1]}
                                            onChange={(e) =>
                                                handleInvestmentRangeChange([investmentRange[0], Number(e.target.value)])
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                    </Grid>

                    {/* Category Section */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="TITLE3">Category</Typography>
                            <IconButton onClick={() => handleExpand('category')}>
                                {expanded.category ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={expanded.category}>
                            <Box sx={{ ml: 1 }}>
                                <RadioGroup
                                    value={selectedCategory}
                                    onChange={(e) => handleCategoryChange(e.target.value)}
                                >
                                    <Grid container spacing={1}>
                                        {categories.map((category) => (
                                            <Grid item xs={12} key={category.id}>
                                                <FormControlLabel
                                                    value={category.id}
                                                    control={<Radio />}
                                                    label={category.name}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </RadioGroup>
                            </Box>
                        </Collapse>
                    </Grid>

                    {/* Location Section */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="TITLE3">Location</Typography>
                            <IconButton onClick={() => handleExpand('location')}>
                                {expanded.location ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </Box>
                        <Collapse in={expanded.location}>
                            {/* <Box sx={{ ml: 1 }}>
                                <Grid container spacing={1}>
                                    {locations.map((location) => (
                                        <FormControlLabel
                                            key={location.id}
                                            control={
                                                <Checkbox
                                                    checked={selectedLocations[location.id] || false}
                                                    onChange={(e) =>
                                                        handleLocationChange(location.id, e.target.checked)
                                                    }
                                                />
                                            }
                                            label={location.name}
                                        />
                                    ))}
                                </Grid>
                            </Box> */}
                            <Box sx={{ ml: 1 }}>
                                <Grid container spacing={1}>
                                    {locations.map((location, index) => (
                                        <Grid item xs={12} key={location.id}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedLocations[location.id] || false}
                                                        onChange={(e) =>
                                                            handleLocationChange(location.id, e.target.checked)
                                                        }
                                                    />
                                                }
                                                label={location.name}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Collapse>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default SearchPageForm;
