//npm install react-hook-form @mui/material @emotion/react @emotion/styled yup
// npm install @hookform/resolvers yup


import React, { useState } from 'react';
import { Button, TextField, Stack, Box, useMediaQuery, Paper, Divider, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { oneFranHubtheme } from '../../theme/theme'
import ProgressStepper from './progress/ProgressSteper';
import { ReactComponent as UserIcon } from "../../assets/icons/User.svg"



import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from '../../helpers/custom-input';
import CustomInputPassword from '../../helpers/CustomInputPassword'
import { OFMailIcon, OFSquareLockPassword, OFUser } from '../../icons/CustomIcon';
import ProgressSteperFranchisor from './progress/ProgressSteperFranchisor';
import ProgressSteperInvestor from './progress/ProgressSteperInvestor';
import ProgressSteperBusinessCollaboration from './progress/ProgressSteperBusinessCollaboration';
import ProgressSteperPartnership from './progress/ProgressSteperPartnership';
import ProgressSteperDealer from './progress/ProgressSteperDealer';
import ProgressSteperSellMyBusiness from './progress/ProgressSteperSellMyBusiness';
import CustomInputPhone from '../../helpers/CustomInputPhone';
import { countryCodes } from '../../utils/Constants';
import CustomToolTip from '../../helpers/CustomTooltip';


const PersonalInfoForm = ({ control, errors, user, title, isShow }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const navigate = useNavigate();







    return (

        <>

            <Box paddingX={6} paddingY={3} sx={{ backgroundColor: '#E7F0FF' }}>

                <Typography variant="h5" sx={{ color: '#0D2659' }}>{title}</Typography>
            </Box>
            {!isMobile &&
                <>
                    {user === 'franchiser' && <ProgressSteperFranchisor />}
                    {user === 'investor' && <ProgressSteperInvestor />}
                    {user === 'dealer' && <ProgressSteperDealer />}
                    {user === 'bcollab' && <ProgressSteperBusinessCollaboration />}
                    {user === 'BusinessSeller' && <ProgressSteperSellMyBusiness />}

                    <Divider />
                </>
            }

            <Box
                paddingX={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Typography variant="TITLE1" sx={{ color: '#0D2659', mb: 2, mt: 1 }}>Sign up</Typography>

                {/* <form onSubmit={handleSubmit(onSubmit)}> */}



                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>


                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="First Name"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    error={!!errors.firstName}
                                    helperText={errors.firstName ? errors.firstName.message : ''}
                                    sicon={<OFUser />}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Last Name"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    error={!!errors.lastName}
                                    helperText={errors.lastName ? errors.lastName.message : ''}
                                    sicon={<OFUser />}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="emailId"
                            control={control}
                            render={({ field }) => (
                                <CustomInput
                                    {...field}
                                    label="Email ID (User Id)"
                                    type="email"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    error={!!errors.emailId}
                                    helperText={errors.emailId ? errors.emailId.message : ''}
                                    sicon={<OFMailIcon />}
                                />
                            )}
                        />
                    </Grid>
                    {!isShow && (<>
                        <Grid item xs={12} md={6}>

                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (

                                    <div>
                                        <CustomInputPassword
                                            {...field}
                                            label="Password"
                                            infoTooltipContent={[
                                                'Should be alphanumeric',
                                                'Have minimum 6 characters',
                                                'Have Maximum 15 characters',
                                                'Should not contain any special characters',
                                            ]}

                                            type="password"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            error={!!errors.password}
                                            helperText={errors.password ? errors.password.message : ''}
                                            sicon={<OFSquareLockPassword />}
                                        />
                                    </div>

                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                render={({ field }) => (

                                    <div>
                                        <CustomInputPassword
                                            {...field}
                                            label="Confirm Password"
                                            infoTooltipContent={[
                                                'Should be alphanumeric',
                                                'Have minimum 6 characters',
                                                'Have Maximum 15 characters',
                                                'Should not contain any special characters',
                                            ]}
                                            type="password"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            error={!!errors.confirmPassword}
                                            helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                                            sicon={<OFSquareLockPassword />}
                                        />
                                    </div>

                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <Controller
                                name="mobileNo"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputPhone
                                        label="Mobile No."
                                        fullWidth
                                        required
                                        // sicon={< OFPhone />}
                                        countryCodeOptions={countryCodes}
                                        selectedCountryCode={field.value?.countryCode || ''}
                                        onCountryCodeChange={(newCountryCode) => {
                                            field.onChange({
                                                ...field.value,
                                                countryCode: newCountryCode,
                                            });
                                        }}
                                        value={field.value?.phoneNumber || ''}
                                        onChange={(newPhoneNumber) => {
                                            field.onChange({
                                                ...field.value,
                                                phoneNumber: newPhoneNumber,
                                            });
                                        }}
                                        helperText={errors.mobileNo?.phoneNumber?.message || ''}
                                        error={!!errors.mobileNo}
                                    // isViewMode={!isEditing}
                                    />
                                )}
                            />

                        </Grid>
                    </>)}
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
                {/* </form> */}

            </Box>
            {/* </Paper> */}
            {/* </Box> */}

        </>
    );
};

export default PersonalInfoForm;
