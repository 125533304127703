import { toastError } from "../utils/toast-notify";
import { API } from "./api-path";
import { getApiCall, getApiCallWithCred, getApiDownloadFile, getApiDownloadFIle } from "./axios-methods";

export const fetchCountries = async (setCountries) => {

    try {
        const path = API.getcountries();


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {




                setCountries(response.data);


            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });
    } catch (error) {
        console.error('Unexpected error:', error);

    } finally {


    }

};

export const fetchStates = async (setStates, selectedCountry) => {

    try {
        const path = `${API.getStates()}/${selectedCountry}`;



        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                setStates(response.data);

            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });
    } catch (error) {
        console.error('Unexpected error:', error);

    } finally {

    }

};


export const fetchCities = async (setCities, selectedState) => {

    try {
        const path = API.getCities() + "/" + selectedState;


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {



                setCities(response.data);
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });
    } catch (error) {
        console.error('Unexpected error:', error);

    } finally {

    }

};


export const fetchIndustries = async (setIndustries) => {

    try {
        const path = API.getIndustries()


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                setIndustries(response.data);
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });
    } catch (error) {
        console.error('Unexpected error:', error);

    } finally {

    }

};


export const fetchSectors = async (setSectors, selectedIndustry) => {

    try {
        const path = API.getIndustrySector() + "/" + selectedIndustry


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                setSectors(response.data);
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });
    } catch (error) {
        console.error('Unexpected error:', error);

    } finally {

    }

};



export const fetchRange = async (setRanges) => {

    try {
        const path = API.getRange()


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {



                setRanges(response.data);
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });
    } catch (error) {
        console.error('Unexpected error:', error);

    } finally {

    }

};



export const fetchBusinessProfile = async () => {
    try {
        const path = API.getFranBusinessDetails();


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                return response
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });


        return data;
    } catch (error) {
        console.error('Unexpected error:', error);


    } finally {


    }
};


export const fetchPropertyInfo = async () => {
    try {
        const path = API.getFranExpansionDetails();


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                return response
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });


        return data;
    } catch (error) {
        console.error('Unexpected error:', error);

        throw error;
    } finally {


    }
};



export const fetchFFinancialInfo = async () => {
    try {
        const path = API.getFranFinancialDetails();


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                return response
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });


        return data;
    } catch (error) {
        console.error('Unexpected error:', error);


    } finally {


    }
};

export const fetchExpansionInfo = async () => {
    try {
        const path = API.getFranSpaceRequirementDetails();


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                return response
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });


        return data;
    } catch (error) {
        console.error('Unexpected error:', error);


    } finally {


    }
};

export const fetchExpansionStates = async (setExpansionStates, selectedCountry) => {

    try {
        const path = API.getStates() + "/" + selectedCountry;


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                setExpansionStates(response);
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });
    } catch (error) {
        console.error('Unexpected error:', error);

    } finally {


    }

};

export const fetchAppearanceInfo = async () => {
    try {
        const path = API.getFranAppearanceDetails();


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                return response
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });


        return data;
    } catch (error) {
        console.error('Unexpected error:', error);


    } finally {


    }
};


export const fetchCardDetails = async (path) => {
    try {
        // const path = API.getCardList();


        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {
                return response;
            },
            onError: (error) => {
                console.error('Error fetching data:', error);
                throw error;
            },
        });


        return data;

    } catch (error) {
        console.error('Unexpected error:', error);
        throw error;
    }
};



export const fetchSingleCardDetails = async (params, path) => {
    try {
        // const path = API.getSingleCardDetails();

        const data = await getApiCall({
            path: path,
            params: params,
            onSuccess: (response) => {


                return response
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });


        return data;
    } catch (error) {
        console.error('Unexpected error:', error);

        throw error;
    } finally {


    }
};






export const fetchSingleImage = async (path, imageId) => {
    try {
        const urlPath = `${path}/${imageId}`;




        const fileUrlBlob = await getApiDownloadFile(
            urlPath,
            (response) => {

            },
            (error) => {
                console.error('Error fetching image:', error);
            }
        );

        return fileUrlBlob;
    } catch (error) {
        console.error('Unexpected error:', error);
        throw error;
    }
};


export const fetchFormInfo = async (path) => {
    try {

        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {


                return response
            },
            onError: (error) => {
                console.error('Error fetching data:', error);

            },
        });


        return data;
    } catch (error) {
        console.error('Unexpected error:', error);


    } finally {


    }
};

export const fetchPaymentInfo = async (entityType) => {
    try {
        // Construct the path with the entityType as a query parameter
        let path = API.getPaymentDetails();
        if (entityType) {
            path += `?entityType=${encodeURIComponent(entityType)}`;
        }

        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {
                return response;
            },
            onError: (error) => {
                console.error('Error fetching data:', error);
            },
        });

        return data;
    } catch (error) {
        console.error('Unexpected error:', error);
    } finally {
        // Add any cleanup logic here if necessary
    }
};



export const fetchSubscriptionInfo = async (entityType) => {
    try {
        // Construct the path with the entityType as a query parameter
        let path = API.getSubscriptionDetails();
        // if (entityType) {
        //     path += `?entityType=${encodeURIComponent(entityType)}`;
        // }

        const data = await getApiCallWithCred({
            path: path,
            onSuccess: (response) => {
                return response;
            },
            onError: (error) => {
                console.error('Error fetching data:', error);
            },
        });

        return data;
    } catch (error) {
        console.error('Unexpected error:', error);
    } finally {
        // Add any cleanup logic here if necessary
    }




};



export const fetchInterestDetails = async (entityType) => {
    try {
        // Construct the path with the entityType as a query parameter
        let path = API.getInterestOnMe();
        // if (entityType) {
        //     path += `?entityType=${encodeURIComponent(entityType)}`;
        // }

        const data = await getApiCallWithCred({
            path: path,
            onSuccess: (response) => {
                return response;
            },
            onError: (error) => {
                console.error('Error fetching data:', error);
            },
        });

        return data;
    } catch (error) {
        console.error('Unexpected error:', error);
    } finally {
        // Add any cleanup logic here if necessary
    }




};

// FetchData

export const fetchData = async (path) => {
    try {



        const data = await getApiCall({
            path: path,
            onSuccess: (response) => {
                return response;
            },
            onError: (error) => {
                console.error('Error fetching data:', error);
                throw error;
            },
        });


        return data;

    } catch (error) {
        console.error('Unexpected error:', error);
        throw error;
    }
};