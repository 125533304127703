
import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, Grid, Typography, TextField, Button, Breadcrumbs, Stack, Paper, InputAdornment, Container } from '@mui/material';

import { oneFranHubtheme } from '../../theme/theme';
import { fetchCardDetails, fetchData, fetchIndustries, fetchSectors, fetchStates } from '../../axios/api-service';
import { Link, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { OFMail2, OFSearchIcon1, OFSearchIcon2, OFSearchIcon3 } from '../../icons/CustomIcon';
import IdeaCardContainer from '../../components/idea-card/IdeaCardContainer';
import { getColorPair, getRandomIndex } from '../../utils/RandomColour';
import { OFIdeaCard } from '../../components/idea-card/OFIdeaCard';
import { API } from '../../axios/api-path';

const IdeaPage = () => {


    const [cardData, setCardData] = useState(null)

    useEffect(() => {
        const fetchIdeas = async () => {  // Renamed to avoid conflict

            const path = API.getAllIdeas();  // Assuming this is a function that returns the API endpoint

            try {
                const response = await fetchData(path);  // Using your `fetchData` method to make the request

                console.log("All ideas:", JSON.stringify(response.data, null, 2));
                setCardData(response.data)
            } catch (error) {
                console.error("Error fetching card details:", error);
            } finally {
                // Optional: Any cleanup or state update after data fetch
                // setLoading(false);
            }
        };

        fetchIdeas();  // Calling the renamed function to fetch the ideas
    }, []);

    const navigate = useNavigate();

    const noOfCards = 4



    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));




    // console.log('Data:', data);
    // console.log('Selected Filters:', selectedFilters);






    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/"
        //onClick={navigate("/")}
        >
            Home
        </Link >,
        // <Link
        //     underline="hover"
        //     key="2"
        //     color="inherit"
        //     href="/material-ui/getting-started/installation/"
        // // onClick={handleClick}
        // >
        //     Core
        // </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            Idea
        </Typography>,
    ];

    return (
        <Box sx={{
            backgroundColor: '#FFFFFF', boxSizing: 'border-box',


        }}>
            <Container maxWidth={'xl'} >
                <Box

                >


                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>

                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{ minHeight: '15rem', borderRadius: '18px', backgroundColor: 'lightBlue' }}>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'center', // horizontal center
                                    alignItems: 'center',     // vertical center
                                    height: '15rem'           // or any height you need
                                }}>
                                    <Typography>Advertisement</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Left Panel */}

                        {/* Main Content Area */}
                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: 2, }}>
                                <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="TITLE1" paragraph>Ideas</Typography> {/* Use appropriate variant for your design */}
                                        <Typography variant="Content" paragraph>Where Ideas Meet Opportunity and turns into Reality..</Typography> {/* Use appropriate variant for your design */}
                                    </Grid>
                                    <Grid item>

                                    </Grid>
                                </Grid>
                            </Box>

                            <Box >
                                {/* Pass filtered items to CardDisplay */}
                                {/* <CardDisplay filteredItems={filteredItems} /> */}
                                <Grid container spacing={3} padding={isMobile ? 2 : 1}>
                                    {cardData?.map((card) => {
                                        // Generate a random index for each card
                                        const randomIndex = getRandomIndex();
                                        // Get the color pair for the generated index
                                        const { lightColor, darkColor } = getColorPair(randomIndex);

                                        return (
                                            <OFIdeaCard
                                                key={card?.entityCode}  // unique
                                                contentBGColor={lightColor} // Light color for content
                                                titleBgColor={darkColor}    // Matching dark color for title
                                                entityCode={card?.entityCode}
                                                content={card?.text}
                                                noOfCards={noOfCards}
                                            />
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Grid >
                    </Grid >
                </Box>
            </ Container>
        </Box >
    );
};

export default IdeaPage;
