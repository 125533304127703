
import FinancialDetailsForm from './FinancialDetailsForm'
import React, { useEffect, useState } from 'react'
import PropertyDetailsForm from './PropertyDetailsForm'
import { Paper, Stack } from '@mui/material';
import CustomButton from '../../../helpers/CustomButton';
import { API } from '../../../axios/api-path';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError } from '../../../utils/toast-notify';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { fetchExpansionStates, fetchFFinancialInfo, fetchPropertyInfo, fetchRange, fetchStates } from '../../../axios/api-service';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { currencyValidation, dropdownValidation, multiSelectCheckBoxValidation } from '../../../utils/validations';
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';

const FinancialDetails = ({ isDashboardMode }) => {



    const navigate = useNavigate();
    const location = useLocation();

    const [perInfo, setPerInfo] = useRecoilState(perInfoState);
    const [financialInfo, setFinancialInfo] = useState(null);
    const [isFinancialInfo, setIsFinancialInfo] = useState(false);
    const [ranges, setRanges] = useState([]);
    useEffect(() => {
        fetchRange(setRanges);
        const fetchData = async () => {
            try {
                const response = await fetchFFinancialInfo();

                if (response.status === "success") {
                    setFinancialInfo(response.data);

                    setIsFinancialInfo(true);
                } else {
                    setIsFinancialInfo(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);

    const validationSchema = yup.object({

        initialInvestmentRange: dropdownValidation,
        durationOfTraining: dropdownValidation,

        expectedRevenueProjection: currencyValidation,
        breakEvenPoint: yup.string().required('Break-Even Point is required'),
        financialFranchiseResponsibility: multiSelectCheckBoxValidation("Franchisee’s Financial Responsibility"),
        // financialFranchiseResponsibility: yup
        //     .array()
        //     .of(yup.number())
        //     .min(1, 'At least one option must be selected')
        //     .required('This field is required'),
        // // financialFranchiseResponsibility: yup
        //     .array()
        //     .of(yup.number())
        //     .min(1, 'At least one option must be selected')
        //     .required('This field is required'),
        // financialFranchiseResponsibility: yup.array()
        //     .of(yup.string().required()),
        // //   .min(1, 'At least one responsibility must be selected'),
        // financialFranchiseResponsibility: yup
        //     .array()
        //     .of(yup.number())
        //     .min(1, 'At least one option must be selected')
        //     .required('This field is required'),
        // isTrainingSupport: yup.string().required('Please specify if training support is available'),
        // locationOfTraining: yup.array()
        //     .of(yup.string().required())
        //     .min(1, 'At least one location must be selected'),




        // contentOfTraining: yup.array()
        //     .of(yup.string().required())
        //     .min(1, 'At least one content type must be selected'),
        fieldSupportForFranchise: yup.string().required('Field Support for Franchisees is required'),
        supportServices: yup.string().required('Ongoing Support Services is required'),
        marketingSupport: yup.string().required('Marketing Support is required'),
        operationalSupport: yup.string().required('Operational Support is required'),
        mentorShipSupport: yup.string().required('Mentorship Programs is required'),


    });



    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {



        },
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (financialInfo) {

            reset({
                "initialInvestmentRange": financialInfo?.initialInvestmentRange,
                "expectedRevenueProjection": financialInfo?.expectedRevenueProjection,
                "breakEvenPoint": financialInfo?.breakEvenPoint,
                "financialFranchiseResponsibility": financialInfo?.financialFranchiseResponsibility,
                "trainingSupport": financialInfo?.trainingSupport === true ? 'Yes' : financialInfo?.trainingSupport === false ? 'No' : '',
                "locationOfTraining": financialInfo?.locationOfTraining,
                "durationOfTraining": financialInfo?.durationOfTraining,
                "contentOfTraining": financialInfo?.contentOfTraining,
                "fieldSupportForFranchise": financialInfo?.fieldSupportForFranchise === true ? 'Yes' : financialInfo?.fieldSupportForFranchise === false ? 'No' : '',
                "supportServices": financialInfo?.supportServices === true ? 'Yes' : financialInfo?.supportServices === false ? 'No' : '',
                "marketingSupport": financialInfo?.marketingSupport === true ? 'Yes' : financialInfo?.marketingSupport === false ? 'No' : '',
                "operationalSupport": financialInfo?.operationalSupport === true ? 'Yes' : financialInfo?.operationalSupport === false ? 'No' : '',
                "mentorShipSupport": financialInfo?.mentorShipSupport === true ? 'Yes' : financialInfo?.mentorShipSupport === false ? 'No' : '',
            });
        }
    }, [financialInfo, reset]);




    const onSubmit = async (data) => {

        const apiCallData =
        {
            "initialInvestmentRange": data?.initialInvestmentRange,
            "expectedRevenueProjection": data?.expectedRevenueProjection,
            "breakEvenPoint": data?.breakEvenPoint,
            "financialFranchiseResponsibility": data?.financialFranchiseResponsibility,
            "trainingSupport": data?.trainingSupport === 'Yes' ? true : false,
            "locationOfTraining": data?.locationOfTraining,
            "durationOfTraining": data?.durationOfTraining,
            "contentOfTraining": data?.contentOfTraining,
            "fieldSupportForFranchise": data?.fieldSupportForFranchise === 'Yes' ? true : false,
            "supportServices": data?.supportServices === 'Yes' ? true : false,
            "marketingSupport": data?.marketingSupport === 'Yes' ? true : false,
            "operationalSupport": data?.operationalSupport === 'Yes' ? true : false,
            "mentorShipSupport": data?.mentorShipSupport === 'Yes' ? true : false,

        };


        // const path = API.signupFranFinancialDetails();

        const userCode = sessionStorage.getItem("userCode");
        let path
        if ((perInfo?.roles?.includes("ADMIN")))
            path = `${API.signupFranFinancialDetailsAdmin()}?userCode=${encodeURIComponent(userCode)}`

        else
            path = API.signupFranFinancialDetails();








        try {
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {
                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }
                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '5'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '3'));
    };

















    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    return (
        <>  <ToastContainer

        />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 2,
                    margin: 4,
                }}
            >
                <FinancialDetailsForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    ranges={ranges}


                />
                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}
            </Paper>

        </>
    )
}

export default FinancialDetails