
import React, { useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
    Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, IconButton, Typography, Collapse, Box, Avatar,
    Badge, Grid
} from '@mui/material';
import {
    Home, Lock, ExitToApp, Phone, Favorite, Notifications, ChevronLeft as ChevronLeftIcon, Menu as MenuIcon,
    ExpandLess, ExpandMore
} from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../recoil/profile-atom';
import { OFCompanyLogo, OFDashboardIcon, OFDrawer, OFIndustry, OFInquiriesIcon, OFInterestsIcon, OFProfile, OFProfileIcon, OFRecentlyViewIcon, OFSettingsIcon, OFSupportIcon, OFUpgradePlanIcon, OFWishlistIcon } from '../../icons/CustomIcon';
import { Button } from 'react-scroll';
import CompanyLogo from '../../assets/CompanyLogoHeader.svg';
import CompanyLogo2 from '../../assets/CompanyLogo2.svg';
import { toProper } from '../../utils/UtilFunctions';
import { AuthContext } from '../../context/AuthContext';


const drawerWidth = 240;
const miniDrawerWidth = 50;



const DashboardLayout = () => {
    const { logout } = useContext(AuthContext);
    const [userInfo] = useRecoilState(perInfoState);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [openMobileOptions, setOpenMobileOptions] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState("Dashboard"); // State for selected menu item name
    const navigate = useNavigate();

    console.log(" User Info --> " + JSON.stringify(userInfo, null, 2))

    const roleBasedMenuItems = {
        INVESTOR: [

            { text: 'Investor Details', path: '/dashboard/investor-details' },
        ],
        FRANCHISER: [

            { text: 'Business and Professional', path: '/dashboard/franchise/business-info' },
            { text: 'Franchise Offering and Expansion', path: '/dashboard/franchise/expansion-info' },
            { text: 'Financial and Training', path: '/dashboard/franchise/financial-info' },
            { text: 'Space and Legal', path: '/dashboard/franchise/space-info' },
            { text: 'Appearance and Media', path: '/dashboard/franchise/appearance-info' },
            { text: 'Payment', path: '/dashboard/payment' },
        ],
        DEALER: [

            { text: 'Business Information', path: '/dashboard/dealer/business-info' },
            { text: 'Market Details', path: '/dashboard/dealer/market-info' },
            { text: 'Training and Operations', path: '/dashboard/dealer/operation-info' },
            { text: 'Appearance and Reference', path: '/dashboard/dealer/appearance-info' },
            { text: 'Payment', path: '/dashboard/payment' },
        ],
        BCOLLAB: [

            { text: 'Company Information', path: '/dashboard/partnership/company-info' },
            { text: 'Financial Information', path: '/dashboard/partnership/financial-info' },
            { text: 'Mentorship and Advisory', path: '/dashboard/partnership/mentorship-info' },
            { text: 'Legal & Regulatory', path: '/dashboard/partnership/legal-info' },
            { text: 'Appearance and Media', path: '/dashboard/partnership/appearance-info' },
            { text: 'Payment', path: '/dashboard/payment' },
        ],
        BSELLER: [

            { text: 'Owner Information', path: '/dashboard/seller/owner-info' },
            { text: 'Business Information', path: '/dashboard/seller/business-info' },
            { text: 'Financial Details', path: '/dashboard/seller/financial-info' },
            { text: 'Business and Advertisement', path: '/dashboard/seller/advertisement-info' },
            { text: 'Payment', path: '/dashboard/payment' },
        ],

        ADMIN: [

            { text: 'Franchisor', path: '/dashboard/franchise/registration/step/1' },
            { text: 'Business Collaboration', path: '/dashboard/business-collaboration/registration/step/1' },
            { text: 'Dealers and Distributors', path: '/dashboard/dealer/registration/step/1' },
            { text: 'Business on Sales', path: '/dashboard/sell-business/registration/step/1' },
            { text: 'Investor', path: '/dashboard/investor/registration/step/1' },
            // { text: 'Legal Support', path: '/dashboard/dashboard/payment' },
        ],

    };

    console.log(" IH " + JSON.stringify(roleBasedMenuItems[userInfo.roles[0]], null, 2))

    const menuItems = roleBasedMenuItems[userInfo.roles[0]] || [];

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleNavigation = (path, name) => {
        setSelectedMenuItem(name); // Update the selected menu item name
        navigate(path);
    };

    // const handleNavigation = (path) => {
    //     navigate(path);
    // };

    const handleMobileOptionsToggle = () => {
        setOpenMobileOptions(!openMobileOptions);
    };

    // const getMenuItemStyles = (menuItemName, selectedMenuItem) => ({
    //     // backgroundColor: menuItemName === selectedMenuItem ? 'red' : 'inherit',
    //     color: menuItemName === selectedMenuItem ? 'blue' : 'inherit',
    //     fontSize: menuItemName === selectedMenuItem ? '40px' : 'inherit',
    //     '&:hover': {
    //         // backgroundColor: menuItemName === selectedMenuItem ? 'red' : '#f0f0f0',
    //     },
    // });

    const getMenuItemStyles = (menuItemName, selectedMenuItem) => ({
        color: menuItemName === selectedMenuItem ? 'blue' : 'inherit',
        fontWeight: menuItemName === selectedMenuItem ? 'bold' : 'normal',
        fontSize: menuItemName === selectedMenuItem ? '20px' : '16px',
    });



    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            {/* Top Bar */}
            <Grid container direction="column" flexWrap="nowrap" overflow="hidden">
                <Grid item>
                    <Toolbar
                        sx={{
                            backgroundColor: 'white',
                            color: 'grey',
                            justifyContent: 'space-between',
                            ml: drawerOpen ? `${drawerWidth}px` : `${miniDrawerWidth}px`,
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
                            {/* <IconButton color="inherit" onClick={toggleDrawer}>
                                {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                            </IconButton> */}
                            {/* <Typography variant="TITLE1" noWrap>
                               
                                Manage Profile
                            </Typography> */}
                        </Box>
                        <Box display="flex" alignItems="center" gap={2}>
                            {/* Favorite Icon */}
                            <IconButton color="inherit">
                                <Favorite />
                            </IconButton>

                            {/* Notifications Icon with Badge */}
                            <IconButton color="inherit">
                                <Badge badgeContent={4} color="error">
                                    <Notifications />
                                </Badge>
                            </IconButton>

                            {/* User Avatar and Info */}
                            <Box display="flex" alignItems="center" gap={1}>
                                <IconButton color="inherit">
                                    <Avatar alt="User Avatar" src="/images/avatar1.png" />
                                </IconButton>
                                <Box display="flex" flexDirection="column" alignItems="flex-start">
                                    <Typography variant="body1" sx={{ fontSize: '18px', color: 'black' }}>
                                        {toProper(`${userInfo.firstName} ${userInfo.lastName}`)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '14px', color: 'blue' }}>
                                        {toProper(userInfo.roles[0])}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </Grid>

                <Grid item container flexGrow={1} flexWrap="nowrap" overflow="hidden">
                    {/* Drawer */}
                    <Drawer
                        sx={{
                            width: drawerOpen ? drawerWidth : miniDrawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerOpen ? drawerWidth : miniDrawerWidth,
                                boxSizing: 'border-box',
                                overflowX: 'hidden',
                                transition: 'width 0.3s ease-in-out',
                            },
                        }}
                        variant="permanent"
                        anchor="left"
                    >
                        <Toolbar sx={{ justifyContent: drawerOpen ? 'space-between' : 'center', px: 2 }}>
                            {drawerOpen &&
                                <Box mt={2} mb={2}>
                                    <Typography onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
                                        <img src={CompanyLogo2} width="100%" alt="Company Logo" />
                                    </Typography>

                                </Box>

                            }
                            <IconButton onClick={toggleDrawer}>
                                {drawerOpen ? <OFDrawer /> : <MenuIcon />}
                            </IconButton>

                        </Toolbar>



                        <List>
                            <ListItem button
                                onClick={() => handleNavigation('/dashboard/home')}
                            >
                                <ListItemIcon sx={{ minWidth: 30 }}><OFDashboardIcon /></ListItemIcon>
                                {drawerOpen && <ListItemText primary="Dashboard" />}
                            </ListItem>
                            <ListItem button onClick={() => handleNavigation('/dashboard/interests', 'Interests')}>
                                <ListItemIcon sx={{ minWidth: 30 }}><OFInquiriesIcon /></ListItemIcon>
                                {drawerOpen && <ListItemText primary="Expressed Interest" />}
                            </ListItem>
                            <ListItem button onClick={() => handleNavigation('/dashboard/inquiries', 'Inquiries')}>
                                <ListItemIcon sx={{ minWidth: 30 }}><OFInterestsIcon /></ListItemIcon>
                                {drawerOpen && <ListItemText primary="Inquiries Received" />}
                            </ListItem>

                            <ListItem button onClick={handleMobileOptionsToggle}>
                                <ListItemIcon sx={{ minWidth: 30 }}><OFProfileIcon /></ListItemIcon>
                                {drawerOpen && <ListItemText primary="Manage Profile" />}
                                {drawerOpen && (openMobileOptions ? <ExpandLess /> : <ExpandMore />)}
                            </ListItem>

                            <Collapse in={openMobileOptions} timeout="auto" unmountOnExit>
                                {/* <List component="div" disablePadding>
                                    {menuItems.map((item, index) => (
                                        <ListItem key={index} button onClick={() => handleNavigation(item.path, 'Manage Profile')}>
                                            <ListItemText primary={item.text} sx={{ pl: 4 }} />
                                        </ListItem>
                                    ))}
                                </List> */}
                                <List component="div" disablePadding>
                                    {menuItems.map((item, index) => (
                                        <ListItem
                                            key={index}
                                            button
                                            onClick={() => handleNavigation(item.path, item.text)}  // Update the selected menu item
                                            sx={getMenuItemStyles(item.text, selectedMenuItem)}  // Apply the style here
                                        >
                                            <ListItemText primary={item.text} sx={{ pl: 4 }} />
                                        </ListItem>
                                    ))}

                                </List>

                            </Collapse>
                        </List>

                        <Box sx={{ mt: 'auto' }}>

                            <List>

                                {drawerOpen && userInfo?.roles?.includes('ADMIN') && (
                                    <ListItem button onClick={() => handleNavigation('/dashboard/ideas', 'Wishlist')}>
                                        <ListItemIcon sx={{ minWidth: 30 }}>
                                            <OFRecentlyViewIcon sx={{ fontSize: 20 }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Create Business Idea" />
                                    </ListItem>
                                )}
                                {drawerOpen && userInfo?.roles?.includes('ADMIN') && (
                                    <ListItem button onClick={() => handleNavigation('/dashboard/articles', 'Wishlist')}>
                                        <ListItemIcon sx={{ minWidth: 30 }}>
                                            <OFRecentlyViewIcon sx={{ fontSize: 20 }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Create Articles" />
                                    </ListItem>
                                )}
                                {drawerOpen && userInfo?.roles?.includes('ADMIN') && (
                                    <ListItem button onClick={() => handleNavigation('/dashboard/success-stories', 'Wishlist')}>
                                        <ListItemIcon sx={{ minWidth: 30 }}>
                                            <OFRecentlyViewIcon sx={{ fontSize: 20 }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Create Success Story" />
                                    </ListItem>
                                )}


                                <ListItem button onClick={() => handleNavigation('/dashboard/wishlist', 'Wishlist')}>
                                    <ListItemIcon sx={{ minWidth: 30 }}> {/* Adjust minWidth if needed */}
                                        <OFWishlistIcon sx={{ fontSize: 20 }} /> {/* Adjust fontSize if needed */}
                                    </ListItemIcon>
                                    {drawerOpen && <ListItemText primary="Wishlist" />}
                                </ListItem>
                                <ListItem button onClick={() => handleNavigation('/dashboard/history', 'View History')}>
                                    <ListItemIcon sx={{ minWidth: 30 }}> {/* Adjust minWidth if needed */}
                                        <OFRecentlyViewIcon sx={{ fontSize: 20 }} /> {/* Adjust fontSize if needed */}
                                    </ListItemIcon>
                                    {drawerOpen && <ListItemText primary="View History" />}
                                </ListItem>
                                <ListItem button onClick={() => handleNavigation('/dashboard/support', 'Support')}>
                                    <ListItemIcon sx={{ minWidth: 30 }}> {/* Adjust minWidth if needed */}
                                        <OFSupportIcon sx={{ fontSize: 20 }} /> {/* Adjust fontSize if needed */}
                                    </ListItemIcon>
                                    {drawerOpen && <ListItemText primary="Support" />}
                                </ListItem>
                                <ListItem button onClick={() => handleNavigation('/dashboard/settings', 'Settings')}>
                                    <ListItemIcon sx={{ minWidth: 30 }}> {/* Adjust minWidth if needed */}
                                        <OFSettingsIcon sx={{ fontSize: 20 }} /> {/* Adjust fontSize if needed */}
                                    </ListItemIcon>
                                    {drawerOpen && <ListItemText primary="Settings" />}
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleNavigation('/dashboard/upgrade-plan', 'Upgrade Plan')}
                                    sx={{
                                        paddingY: '10px',
                                        backgroundColor: '#0D2659', // Set background color to green
                                        '&:hover': {
                                            backgroundColor: '#0D2659', // Darker shade on hover for better interaction feedback
                                        },
                                        borderRadius: '6px', // Optional: add rounded corners for a more modern look
                                    }}
                                >
                                    <ListItemIcon sx={{ minWidth: 30 }}>
                                        <OFUpgradePlanIcon sx={{ fontSize: 20, color: 'white' }} /> {/* Icon color set to white */}
                                    </ListItemIcon>
                                    {drawerOpen && <ListItemText primary="Upgrade Plan" sx={{ color: 'white' }} />} {/* Text color set to white */}
                                </ListItem>

                                <ListItem button onClick={() => {
                                    logout()

                                    handleNavigation('/')
                                }}>
                                    <ListItemIcon sx={{ minWidth: 30 }}> {/* Adjust minWidth if needed */}
                                        <ExitToApp sx={{ fontSize: 20 }} /> {/* Adjust fontSize if needed */}
                                    </ListItemIcon>
                                    {drawerOpen && <ListItemText primary="Logout" />}
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>

                    {/* Main Content */}
                    <Grid item xs={12} flexGrow={1} overflow="hidden" sx={{ bgcolor: "white", transition: 'margin-left 0.3s ease-in-out' }}>
                        <Box component="div" width="100%" height="100%" overflow="auto">
                            <Outlet />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashboardLayout;
