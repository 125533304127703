
// // // import React, { useState } from 'react';
// // // import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery, Grid, Container, Paper, Button } from '@mui/material';
// // // import { useForm, Controller } from 'react-hook-form';
// // // import { useTheme } from '@mui/material/styles';
// // // import { API } from '../../../axios/api-path';
// // // import { postApiCall } from '../../../axios/axios-methods';
// // // import { toast, ToastContainer } from 'react-toastify';
// // // import Interest from "../../../assets/icons/Interest.svg";  // Update icon paths as needed

// // // function SuccessStory() {
// // //     const theme = useTheme();
// // //     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

// // //     const { handleSubmit, control, formState: { errors }, reset } = useForm();
// // //     const [ideaDescription, setIdeaDescription] = useState('');
// // //     const [ideaTitle, setIdeaTitle] = useState('');

// // //     const onSubmit = async (data) => {
// // //         const apiCallData = {
// // //             text: data.title, // Mapping 'title' to 'text'
// // //             remarks: data.description, // Mapping 'description' to 'remarks'
// // //             type: "IDEA"
// // //         };

// // //         const path = API.createIdea();
// // //         try {
// // //             console.log("Submitting data:", apiCallData);  // Debug log
// // //             await postApiCall({
// // //                 data: apiCallData,
// // //                 path: path,
// // //                 onSuccess: (res) => {
// // //                     toast.success("Idea submitted successfully");
// // //                     reset(); // Reset fields after successful submission
// // //                 },
// // //                 onError: (error) => {
// // //                     console.error('Submission error:', error);
// // //                     toast.error("Failed to submit the idea");
// // //                 },
// // //             });
// // //         } catch (error) {
// // //             console.error('Unexpected error:', error);
// // //             toast.error("Unexpected error occurred");
// // //         }
// // //     };


// // //     return (
// // //         <>
// // //             <ToastContainer position="top-right" autoClose={5000} />

// // //             <Box>
// // //                 <Container maxWidth="xl">
// // //                     <Box paddingY={2}>
// // //                         <Grid container justifyContent="center" alignItems="left">

// // //                             <Grid item xs={12} md={12}>
// // //                                 <Box>
// // //                                     <Typography variant='h4' sx={{ color: '#002659', py: 4 }}>Create Your Success Story</Typography>
// // //                                     <Typography variant='body1' sx={{ color: '#666666' }} paragraph>
// // //                                         Share your innovative ideas with us. Fill in the form below to create a new idea.
// // //                                     </Typography>
// // //                                 </Box>
// // //                             </Grid>

// // //                             <Grid item xs={12} md={6} paddingY={4}>
// // //                                 <Paper sx={{ width: '100%', p: 4, bgcolor: '#E7F0FF' }}>
// // //                                     <Typography variant='h6' sx={{ color: '#002659' }} paragraph>Idea Details</Typography>

// // //                                     <form onSubmit={handleSubmit(onSubmit)}>
// // //                                         <Grid item xs={12} mb={2}>
// // //                                             <Controller
// // //                                                 name="title"
// // //                                                 control={control}
// // //                                                 defaultValue=""
// // //                                                 rules={{ required: 'Title is required' }}
// // //                                                 render={({ field }) => (
// // //                                                     <OutlinedInput
// // //                                                         {...field}
// // //                                                         startAdornment={
// // //                                                             <InputAdornment position="start">
// // //                                                                 <img src={Interest} alt="Interest Icon" style={{ width: 20, height: 20 }} />
// // //                                                             </InputAdornment>
// // //                                                         }
// // //                                                         placeholder="Idea Title"
// // //                                                         error={!!errors.title}
// // //                                                         fullWidth
// // //                                                         sx={{
// // //                                                             backgroundColor: '#f9f9f9',
// // //                                                             borderRadius: '0.5rem',
// // //                                                             padding: '10px',
// // //                                                         }}
// // //                                                     />
// // //                                                 )}
// // //                                             />
// // //                                             {errors.title && <Typography color="error">{errors.title.message}</Typography>}
// // //                                         </Grid>
// // //                                         <Grid item xs={12} mb={2}>
// // //                                             <Controller
// // //                                                 name="description"
// // //                                                 control={control}
// // //                                                 defaultValue=""
// // //                                                 rules={{ required: 'Description is required' }}
// // //                                                 render={({ field }) => (
// // //                                                     <OutlinedInput
// // //                                                         {...field}
// // //                                                         startAdornment={
// // //                                                             <InputAdornment position="start">
// // //                                                                 <img src={Interest} alt="Interest Icon" style={{ width: 20, height: 20 }} />
// // //                                                             </InputAdornment>
// // //                                                         }
// // //                                                         placeholder="Describe your idea"
// // //                                                         multiline
// // //                                                         rows={4}
// // //                                                         error={!!errors.description}
// // //                                                         fullWidth
// // //                                                         sx={{
// // //                                                             backgroundColor: '#f9f9f9',
// // //                                                             borderRadius: '0.5rem',
// // //                                                             padding: '10px',
// // //                                                         }}
// // //                                                     />
// // //                                                 )}
// // //                                             />
// // //                                             {errors.description && <Typography color="error">{errors.description.message}</Typography>}
// // //                                         </Grid>
// // //                                         <Grid item xs={12} md={6} mb={2}>
// // //                                             <Button
// // //                                                 type="submit"
// // //                                                 variant="contained"
// // //                                                 sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}>
// // //                                                 Submit Idea
// // //                                             </Button>
// // //                                         </Grid>
// // //                                     </form>
// // //                                 </Paper>
// // //                             </Grid>

// // //                             <Grid item xs={12} md={6} paddingY={2}>
// // //                                 <Box>
// // //                                     {/* Placeholder for other components or images */}
// // //                                 </Box>
// // //                             </Grid>
// // //                         </Grid>
// // //                     </Box>
// // //                 </Container>
// // //             </Box>

// // //         </>
// // //     );
// // // }

// // // export default SuccessStory;
// // import React, { useState } from 'react';
// // import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery, Grid, Container, Paper, Button } from '@mui/material';
// // import { useForm, Controller } from 'react-hook-form';
// // import { useTheme } from '@mui/material/styles';
// // import { toast, ToastContainer } from 'react-toastify';
// // import Interest from "../../../assets/icons/Interest.svg"; // Update icon paths as needed
// // import { API } from '../../../axios/api-path';
// // import { postApiCall } from '../../../axios/axios-methods';

// // function SuccessStory() {
// //     const theme = useTheme();
// //     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

// //     const { handleSubmit, control, formState: { errors }, reset } = useForm();
// //     const [storyDetails, setStoryDetails] = useState({
// //         storyDescription: "This is a sample story description.",
// //         author: "John Doe",
// //         thumbnail: {
// //             url: "https://example.com/image.jpg",
// //             width: 200,
// //             height: 150
// //         }
// //     });

// //     const onSubmit = async (data) => {
// //         const apiCallData = {
// //             storyDescription: data.storyDescription,
// //             author: data.author,
// //             thumbnail: {
// //                 url: data.thumbnailUrl,
// //                 width: storyDetails.thumbnail.width, // Keeping width and height constant for now
// //                 height: storyDetails.thumbnail.height,
// //             }
// //         };


// //         const path = API.createSuccessStory();
// //         try {
// //             console.log("Submitting data:", apiCallData);  // Debug log
// //             await postApiCall({
// //                 data: apiCallData,
// //                 path: path,
// //                 onSuccess: (res) => {
// //                     console.log("Submitting data:", apiCallData); // Debug log
// //                     toast.success("Story submitted successfully");
// //                     reset(); // Reset fields after successful submission
// //                 },
// //                 onError: (error) => {
// //                     console.error('Submission error:', error);
// //                     toast.error("Failed to submit the idea");
// //                 },
// //             });
// //         } catch (error) {
// //             console.error('Unexpected error:', error);
// //             toast.error("Unexpected error occurred");
// //         }

// //     };

// //     return (
// //         <>
// //             <ToastContainer position="top-right" autoClose={5000} />

// //             <Box>
// //                 <Container maxWidth="xl">
// //                     <Box paddingY={2}>
// //                         <Grid container justifyContent="left" alignItems="left">

// //                             <Grid item xs={12} md={12}>
// //                                 <Box>
// //                                     <Typography variant="h4" sx={{ color: '#002659', py: 4 }}>
// //                                         Share Your Success Story
// //                                     </Typography>
// //                                     <Typography variant="body1" sx={{ color: '#666666' }} paragraph>
// //                                         Fill in the form below to share your story with us.
// //                                     </Typography>
// //                                 </Box>
// //                             </Grid>

// //                             <Grid item xs={12} md={6} paddingY={4}>
// //                                 <Paper sx={{ width: '100%', p: 4, bgcolor: '#E7F0FF' }}>
// //                                     <Typography variant="h6" sx={{ color: '#002659' }} paragraph>Story Details</Typography>

// //                                     <form onSubmit={handleSubmit(onSubmit)}>
// //                                         <Grid item xs={12} mb={2}>
// //                                             <Controller
// //                                                 name="storyDescription"
// //                                                 control={control}
// //                                                 defaultValue=""
// //                                                 rules={{ required: 'Story description is required' }}
// //                                                 render={({ field }) => (
// //                                                     <OutlinedInput
// //                                                         {...field}
// //                                                         startAdornment={
// //                                                             <InputAdornment position="start">
// //                                                                 <img src={Interest} alt="Interest Icon" style={{ width: 20, height: 20 }} />
// //                                                             </InputAdornment>
// //                                                         }
// //                                                         placeholder="Story Description"
// //                                                         multiline
// //                                                         rows={4}
// //                                                         error={!!errors.storyDescription}
// //                                                         fullWidth
// //                                                         sx={{
// //                                                             backgroundColor: '#f9f9f9',
// //                                                             borderRadius: '0.5rem',
// //                                                             padding: '10px',
// //                                                         }}
// //                                                     />
// //                                                 )}
// //                                             />
// //                                             {errors.storyDescription && <Typography color="error">{errors.storyDescription.message}</Typography>}
// //                                         </Grid>

// //                                         <Grid item xs={12} mb={2}>
// //                                             <Controller
// //                                                 name="author"
// //                                                 control={control}
// //                                                 defaultValue=""
// //                                                 rules={{ required: 'Author name is required' }}
// //                                                 render={({ field }) => (
// //                                                     <OutlinedInput
// //                                                         {...field}
// //                                                         placeholder="Author Name"
// //                                                         error={!!errors.author}
// //                                                         fullWidth
// //                                                         sx={{
// //                                                             backgroundColor: '#f9f9f9',
// //                                                             borderRadius: '0.5rem',
// //                                                             padding: '10px',
// //                                                         }}
// //                                                     />
// //                                                 )}
// //                                             />
// //                                             {errors.author && <Typography color="error">{errors.author.message}</Typography>}
// //                                         </Grid>

// //                                         <Grid item xs={12} mb={2}>
// //                                             <Controller
// //                                                 name="thumbnailUrl"
// //                                                 control={control}
// //                                                 defaultValue=""
// //                                                 rules={{ required: 'Thumbnail URL is required' }}
// //                                                 render={({ field }) => (
// //                                                     <OutlinedInput
// //                                                         {...field}
// //                                                         placeholder="Thumbnail URL"
// //                                                         error={!!errors.thumbnailUrl}
// //                                                         fullWidth
// //                                                         sx={{
// //                                                             backgroundColor: '#f9f9f9',
// //                                                             borderRadius: '0.5rem',
// //                                                             padding: '10px',
// //                                                         }}
// //                                                     />
// //                                                 )}
// //                                             />
// //                                             {errors.thumbnailUrl && <Typography color="error">{errors.thumbnailUrl.message}</Typography>}
// //                                         </Grid>

// //                                         <Grid item xs={12} md={6} mb={2}>
// //                                             <Button
// //                                                 type="submit"
// //                                                 variant="contained"
// //                                                 sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}
// //                                             >
// //                                                 Submit Story
// //                                             </Button>
// //                                         </Grid>
// //                                     </form>
// //                                 </Paper>
// //                             </Grid>

// //                             {/* <Grid item xs={12} md={6} paddingY={2}>
// //                                 <Box>
// //                                     <Typography variant="h6" sx={{ color: '#002659' }} paragraph>
// //                                         Preview
// //                                     </Typography>
// //                                     <img
// //                                         src={storyDetails.thumbnail.url}
// //                                         alt="Thumbnail"
// //                                         style={{ width: storyDetails.thumbnail.width, height: storyDetails.thumbnail.height, borderRadius: '8px' }}
// //                                     />
// //                                     <Typography variant="body1" sx={{ color: '#666', mt: 2 }}>
// //                                         <strong>Author:</strong> {storyDetails.author}
// //                                     </Typography>
// //                                     <Typography variant="body2" sx={{ color: '#666' }}>
// //                                         {storyDetails.storyDescription}
// //                                     </Typography>
// //                                 </Box>
// //                             </Grid> */}
// //                         </Grid>
// //                     </Box>
// //                 </Container>
// //             </Box>
// //         </>
// //     );
// // }

// // export default SuccessStory;
// import React from 'react';
// import {
//     Box, Typography, OutlinedInput, InputAdornment, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions,
// } from '@mui/material';
// import { useForm, Controller } from 'react-hook-form';
// import Interest from "../../../assets/icons/Interest.svg";

// function SuccessStory({ editIdea, onSubmit, onClose }) {
//     const { handleSubmit, control, formState: { errors }, reset } = useForm({
//         defaultValues: {
//             storyDescription: editIdea?.storyDescription || '',
//             author: editIdea?.author || '',
//             thumbnailUrl: editIdea?.thumbnail?.url || '',
//         },
//     });

//     const handleFormSubmit = (data) => {
//         const formattedData = {
//             storyDescription: data.storyDescription,
//             author: data.author,
//             thumbnail: {
//                 url: data.thumbnailUrl,
//                 width: editIdea?.thumbnail?.width || 200,
//                 height: editIdea?.thumbnail?.height || 150,
//             },
//         };

//         onSubmit(formattedData);
//         reset(); // Reset the form after submission
//     };

//     return (
//         <Dialog
//             open={true}
//             onClose={onClose}
//             maxWidth="sm"
//             fullWidth
//         >
//             <DialogTitle>{editIdea ? "Edit Success Story" : "Add Success Story"}</DialogTitle>
//             <DialogContent>
//                 <form onSubmit={handleSubmit(handleFormSubmit)}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={12}>
//                             <Controller
//                                 name="storyDescription"
//                                 control={control}
//                                 rules={{ required: 'Story description is required' }}
//                                 render={({ field }) => (
//                                     <OutlinedInput
//                                         {...field}
//                                         placeholder="Story Description"
//                                         multiline
//                                         rows={4}
//                                         fullWidth
//                                         startAdornment={
//                                             <InputAdornment position="start">
//                                                 <img src={Interest} alt="Interest Icon" style={{ width: 20, height: 20 }} />
//                                             </InputAdornment>
//                                         }
//                                         error={!!errors.storyDescription}
//                                     />
//                                 )}
//                             />
//                             {errors.storyDescription && <Typography color="error">{errors.storyDescription.message}</Typography>}
//                         </Grid>

//                         <Grid item xs={12}>
//                             <Controller
//                                 name="author"
//                                 control={control}
//                                 rules={{ required: 'Author name is required' }}
//                                 render={({ field }) => (
//                                     <OutlinedInput
//                                         {...field}
//                                         placeholder="Author Name"
//                                         fullWidth
//                                         error={!!errors.author}
//                                     />
//                                 )}
//                             />
//                             {errors.author && <Typography color="error">{errors.author.message}</Typography>}
//                         </Grid>

//                         <Grid item xs={12}>
//                             <Controller
//                                 name="thumbnailUrl"
//                                 control={control}
//                                 rules={{ required: 'Thumbnail URL is required' }}
//                                 render={({ field }) => (
//                                     <OutlinedInput
//                                         {...field}
//                                         placeholder="Thumbnail URL"
//                                         fullWidth
//                                         error={!!errors.thumbnailUrl}
//                                     />
//                                 )}
//                             />
//                             {errors.thumbnailUrl && <Typography color="error">{errors.thumbnailUrl.message}</Typography>}
//                         </Grid>

//                         <Grid item xs={12}>
//                             <Button
//                                 type="submit"
//                                 variant="contained"
//                                 sx={{ backgroundColor: '#002659', color: '#fff' }}
//                                 fullWidth
//                             >
//                                 {editIdea ? "Update Story" : "Submit Story"}
//                             </Button>
//                         </Grid>
//                     </Grid>
//                 </form>
//             </DialogContent>
//             {/* <DialogActions>
//                 <Button onClick={onClose} color="primary">Close</Button>
//             </DialogActions> */}
//         </Dialog>
//     );
// }

// export default SuccessStory;
import React from 'react';
import {
    Typography, OutlinedInput, InputAdornment, Grid, Button, Dialog, DialogTitle, DialogContent,
    Container,
    Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import Interest from "../../../assets/icons/Interest.svg";
import { OFIdea, OFStore, OFStory } from '../../../icons/CustomIcon';

function SuccessStory({ editIdea, onSubmit, onClose }) {
    const { handleSubmit, control, formState: { errors }, reset, watch } = useForm({
        defaultValues: {
            storyDescription: editIdea?.storyDescription || '',
            author: editIdea?.author || '',
            thumbnailUrl: editIdea?.thumbnail?.url || '',
        },
    });

    // Watch the story description field to count characters
    const storyDescription = watch('storyDescription', '');

    const handleFormSubmit = (data) => {
        const formattedData = {
            storyDescription: data.storyDescription,
            author: data.author,
            thumbnail: {
                url: data.thumbnailUrl,
                width: editIdea?.thumbnail?.width || 200,
                height: editIdea?.thumbnail?.height || 150,
            },
        };

        onSubmit(formattedData);
        reset(); // Reset the form after submission
    };

    const handleCancel = () => {
        reset(); // Reset form fields
        onClose(); // Close the dialog
    };

    return (
        <Box>
            <Container maxWidth="xl">
                <Box paddingY={4} marginY={3}>
                    <Grid container justifyContent="center" alignItems="center"></Grid>


                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>

                                <Controller
                                    name="storyDescription"
                                    control={control}
                                    rules={{
                                        required: 'Story description is required',
                                        maxLength: {
                                            value: 500,
                                            message: 'Story description cannot exceed 500 characters',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            placeholder="Story Description"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <InputAdornment position="start"
                                                        sx={{
                                                            position: 'absolute', // Position the adornment absolutely
                                                            top: 0, // Align to the top of the input
                                                            left: 0, // Align to the left of the input
                                                            height: '100%', // Match input height if needed
                                                            padding: '10px', // Optional: Adjust spacing around the icon
                                                            pointerEvents: 'none', // Prevent interaction
                                                            alignItems: 'flex-start', // Ensure alignment at the top
                                                            display: 'flex',
                                                        }}>
                                                        <OFStory />
                                                    </InputAdornment>
                                                </InputAdornment>
                                            }
                                            inputProps={{ maxLength: 500 }}
                                            error={!!errors.storyDescription}
                                        />
                                    )}
                                />
                                {errors.storyDescription && <Typography color="error">{errors.storyDescription.message}</Typography>}
                                <Typography variant="body2" color="textSecondary" align="right">
                                    {storyDescription.length} / 500 characters
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="author"
                                    control={control}
                                    rules={{ required: 'Author name is required' }}
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            placeholder="Author Name"
                                            fullWidth
                                            error={!!errors.author}
                                        />
                                    )}
                                />
                                {errors.author && <Typography color="error">{errors.author.message}</Typography>}
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="thumbnailUrl"
                                    control={control}
                                    rules={{ required: 'Thumbnail URL is required' }}
                                    render={({ field }) => (
                                        <OutlinedInput
                                            {...field}
                                            placeholder="Thumbnail URL"
                                            fullWidth
                                            error={!!errors.thumbnailUrl}
                                        />
                                    )}
                                />
                                {errors.thumbnailUrl && <Typography color="error">{errors.thumbnailUrl.message}</Typography>}
                            </Grid>
                            <Grid container justifyContent="center" spacing={2} mt={2}>
                                <Grid item xs={3}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ backgroundColor: '#002659', color: '#fff' }}
                                        fullWidth
                                    >
                                        {editIdea ? "Update Story" : "Submit Story"}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                    <Button
                                        onClick={handleCancel}
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>


                </Box></Container ></Box >
    );
}

export default SuccessStory;
