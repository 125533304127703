
// // // import React, { useState } from 'react';
// // // import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery, Grid, Container, Paper, Button } from '@mui/material';
// // // import { useForm, Controller } from 'react-hook-form';
// // // import { useTheme } from '@mui/material/styles';
// // // import { API } from '../../../axios/api-path';
// // // import { postApiCall } from '../../../axios/axios-methods';
// // // import { toast, ToastContainer } from 'react-toastify';
// // // import Interest from "../../../assets/icons/Interest.svg";  // Update icon paths as needed

// // // function Idea() {
// // //     const theme = useTheme();
// // //     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

// // //     const { handleSubmit, control, formState: { errors }, reset } = useForm();
// // //     const [ideaDescription, setIdeaDescription] = useState('');
// // //     const [ideaTitle, setIdeaTitle] = useState('');

// // //     const onSubmit = async (data) => {
// // //         const apiCallData = {
// // //             text: data.title, // Mapping 'title' to 'text'
// // //             remarks: data.description, // Mapping 'description' to 'remarks'
// // //             type: "IDEA"
// // //         };

// // //         const path = API.createIdea();
// // //         try {
// // //             console.log("Submitting data:", apiCallData);  // Debug log
// // //             await postApiCall({
// // //                 data: apiCallData,
// // //                 path: path,
// // //                 onSuccess: (res) => {
// // //                     toast.success("Idea submitted successfully");
// // //                     reset(); // Reset fields after successful submission
// // //                 },
// // //                 onError: (error) => {
// // //                     console.error('Submission error:', error);
// // //                     toast.error("Failed to submit the idea");
// // //                 },
// // //             });
// // //         } catch (error) {
// // //             console.error('Unexpected error:', error);
// // //             toast.error("Unexpected error occurred");
// // //         }
// // //     };


// // //     return (
// // //         <>
// // //             <ToastContainer position="top-right" autoClose={5000} />

// // //             <Box>
// // //                 <Container maxWidth="xl">
// // //                     <Box paddingY={2}>
// // //                         <Grid container justifyContent="center" alignItems="left">

// // //                             <Grid item xs={12} md={12}>
// // //                                 <Box>
// // //                                     <Typography variant='h4' sx={{ color: '#002659', py: 4 }}>Create Your Idea</Typography>
// // //                                     <Typography variant='body1' sx={{ color: '#666666' }} paragraph>
// // //                                         Share your innovative ideas with us. Fill in the form below to create a new idea.
// // //                                     </Typography>
// // //                                 </Box>
// // //                             </Grid>

// // //                             <Grid item xs={12} md={6} paddingY={4}>
// // //                                 <Paper sx={{ width: '100%', p: 4, bgcolor: '#E7F0FF' }}>
// // //                                     <Typography variant='h6' sx={{ color: '#002659' }} paragraph>Idea Details</Typography>

// // //                                     <form onSubmit={handleSubmit(onSubmit)}>
// // //                                         <Grid item xs={12} mb={2}>
// // //                                             <Controller
// // //                                                 name="title"
// // //                                                 control={control}
// // //                                                 defaultValue=""
// // //                                                 rules={{ required: 'Title is required' }}
// // //                                                 render={({ field }) => (
// // //                                                     <OutlinedInput
// // //                                                         {...field}
// // //                                                         startAdornment={
// // //                                                             <InputAdornment position="start">
// // //                                                                 <img src={Interest} alt="Interest Icon" style={{ width: 20, height: 20 }} />
// // //                                                             </InputAdornment>
// // //                                                         }
// // //                                                         placeholder="Idea Title"
// // //                                                         error={!!errors.title}
// // //                                                         fullWidth
// // //                                                         sx={{
// // //                                                             backgroundColor: '#f9f9f9',
// // //                                                             borderRadius: '0.5rem',
// // //                                                             padding: '10px',
// // //                                                         }}
// // //                                                     />
// // //                                                 )}
// // //                                             />
// // //                                             {errors.title && <Typography color="error">{errors.title.message}</Typography>}
// // //                                         </Grid>
// // //                                         <Grid item xs={12} mb={2}>
// // //                                             <Controller
// // //                                                 name="description"
// // //                                                 control={control}
// // //                                                 defaultValue=""
// // //                                                 rules={{ required: 'Description is required' }}
// // //                                                 render={({ field }) => (
// // //                                                     <OutlinedInput
// // //                                                         {...field}
// // //                                                         startAdornment={
// // //                                                             <InputAdornment position="start">
// // //                                                                 <img src={Interest} alt="Interest Icon" style={{ width: 20, height: 20 }} />
// // //                                                             </InputAdornment>
// // //                                                         }
// // //                                                         placeholder="Describe your idea"
// // //                                                         multiline
// // //                                                         rows={4}
// // //                                                         error={!!errors.description}
// // //                                                         fullWidth
// // //                                                         sx={{
// // //                                                             backgroundColor: '#f9f9f9',
// // //                                                             borderRadius: '0.5rem',
// // //                                                             padding: '10px',
// // //                                                         }}
// // //                                                     />
// // //                                                 )}
// // //                                             />
// // //                                             {errors.description && <Typography color="error">{errors.description.message}</Typography>}
// // //                                         </Grid>
// // //                                         <Grid item xs={12} md={6} mb={2}>
// // //                                             <Button
// // //                                                 type="submit"
// // //                                                 variant="contained"
// // //                                                 sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}>
// // //                                                 Submit Idea
// // //                                             </Button>
// // //                                         </Grid>
// // //                                     </form>
// // //                                 </Paper>
// // //                             </Grid>

// // //                             <Grid item xs={12} md={6} paddingY={2}>
// // //                                 <Box>
// // //                                     {/* Placeholder for other components or images */}
// // //                                 </Box>
// // //                             </Grid>
// // //                         </Grid>
// // //                     </Box>
// // //                 </Container>
// // //             </Box>

// // //         </>
// // //     );
// // // }

// // // export default Idea;

// // import React, { useState } from 'react';
// // import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery, Grid, Container, Paper, Button } from '@mui/material';
// // import { useForm, Controller } from 'react-hook-form';
// // import { useTheme } from '@mui/material/styles';
// // import { API } from '../../../axios/api-path';
// // import { postApiCall } from '../../../axios/axios-methods';
// // import { toast, ToastContainer } from 'react-toastify';
// // import Interest from "../../../assets/icons/Interest.svg";  // Update icon paths as needed
// // import { MailOutline } from '@mui/icons-material';
// // import { OFAddress, OFIdea, OFIdeaIcon } from '../../../icons/CustomIcon';

// // function Idea() {
// //     const theme = useTheme();
// //     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

// //     const { handleSubmit, control, formState: { errors }, reset } = useForm();
// //     const [ideaDescription, setIdeaDescription] = useState('');
// //     const [ideaTitle, setIdeaTitle] = useState('');

// //     const onSubmit = async (data) => {
// //         const apiCallData = {
// //             text: data.title, // Mapping 'title' to 'text'
// //             remarks: data.description, // Mapping 'description' to 'remarks'
// //             type: "IDEA"
// //         };

// //         const path = API.createIdea();
// //         try {
// //             console.log("Submitting data:", apiCallData);  // Debug log
// //             await postApiCall({
// //                 data: apiCallData,
// //                 path: path,
// //                 onSuccess: (res) => {
// //                     toast.success("Idea submitted successfully");
// //                     reset(); // Reset fields after successful submission
// //                 },
// //                 onError: (error) => {
// //                     console.error('Submission error:', error);
// //                     toast.error("Failed to submit the idea");
// //                 },
// //             });
// //         } catch (error) {
// //             console.error('Unexpected error:', error);
// //             toast.error("Unexpected error occurred");
// //         }
// //     };


// //     return (
// //         <>
// //             <ToastContainer position="top-right" autoClose={5000} />

// //             <Box>
// //                 <Container maxWidth="xl">
// //                     <Box paddingY={2}>
// //                         <Grid container justifyContent="center" alignItems="left">

// //                             {/* <Grid item xs={12} md={12}>
// //                                 <Box>
// //                                     <Typography variant='h4' sx={{ color: '#002659', py: 4 }}>Create Your Idea</Typography>
// //                                     <Typography variant='body1' sx={{ color: '#666666' }} paragraph>
// //                                         Share your innovative ideas with us. Fill in the form below to create a new idea.
// //                                     </Typography>
// //                                 </Box>
// //                             </Grid> */}

// //                             <Grid item xs={12} md={12} paddingY={4}>

// //                                 <Typography variant='body' sx={{ color: '#002659' }} paragraph>Create Business Idea</Typography>

// //                                 <form onSubmit={handleSubmit(onSubmit)}>
// //                                     <Grid item xs={12} mb={2}>

// //                                         <Controller
// //                                             name="title"
// //                                             control={control}
// //                                             defaultValue=""
// //                                             rules={{ required: 'Title is required' }}
// //                                             render={({ field }) => (
// //                                                 <OutlinedInput
// //                                                     {...field}
// //                                                     startAdornment={
// //                                                         <InputAdornment
// //                                                             position="start"
// //                                                             sx={{
// //                                                                 position: 'absolute', // Position the adornment absolutely
// //                                                                 top: 0, // Align to the top of the input
// //                                                                 left: 0, // Align to the left of the input
// //                                                                 height: '100%', // Match input height if needed
// //                                                                 padding: '10px', // Optional: Adjust spacing around the icon
// //                                                                 pointerEvents: 'none', // Prevent interaction
// //                                                                 alignItems: 'flex-start', // Ensure alignment at the top
// //                                                                 display: 'flex',
// //                                                             }}
// //                                                         >

// //                                                             <OFIdea />
// //                                                         </InputAdornment>
// //                                                     }
// //                                                     placeholder="Enter the brief about business idea"
// //                                                     multiline
// //                                                     rows={4}
// //                                                     error={!!errors.title}
// //                                                     fullWidth
// //                                                     sx={{
// //                                                         backgroundColor: '#f9f9f9',
// //                                                         borderRadius: '0.5rem',
// //                                                         paddingTop: '20px', // Add space for multiline text
// //                                                         paddingLeft: '40px', // Space for the icon
// //                                                         position: 'relative', // Required for absolute positioning of the adornment
// //                                                         '& .MuiOutlinedInput-input': {
// //                                                             marginTop: '0px', // Adjust to align text correctly
// //                                                         },
// //                                                     }}
// //                                                 />
// //                                             )}
// //                                         />

// //                                         {errors.title && <Typography color="error">{errors.title.message}</Typography>}
// //                                     </Grid>

// //                                     <Grid item xs={12} md={6} mb={2}>
// //                                         <Button
// //                                             type="submit"
// //                                             variant="contained"
// //                                             sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}>
// //                                             Submit Idea
// //                                         </Button>
// //                                     </Grid>
// //                                 </form>

// //                             </Grid>


// //                         </Grid>
// //                     </Box>
// //                 </Container>
// //             </Box>

// //         </>
// //     );
// // }

// // export default Idea;
// import React, { useState } from 'react';
// import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery, Grid, Container, Button } from '@mui/material';
// import { useForm, Controller } from 'react-hook-form';
// import { useTheme } from '@mui/material/styles';
// import { API } from '../../../axios/api-path';
// import { postApiCall } from '../../../axios/axios-methods';
// import { toast, ToastContainer } from 'react-toastify';
// import { OFIdea } from '../../../icons/CustomIcon';

// function Idea() {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     const { handleSubmit, control, formState: { errors }, reset } = useForm();
//     const [charCount, setCharCount] = useState(0);

//     const onSubmit = async (data) => {
//         const apiCallData = {
//             text: data.title,
//             remarks: data.description,
//             type: "IDEA"
//         };

//         const path = API.createIdea();
//         try {
//             await postApiCall({
//                 data: apiCallData,
//                 path: path,
//                 onSuccess: () => {
//                     toast.success("Idea submitted successfully");
//                     reset();
//                     setCharCount(0); // Reset character count
//                 },
//                 onError: (error) => {
//                     console.error('Submission error:', error);
//                     toast.error("Failed to submit the idea");
//                 },
//             });
//         } catch (error) {
//             console.error('Unexpected error:', error);
//             toast.error("Unexpected error occurred");
//         }
//     };

//     const handleCancel = () => {
//         reset();
//         setCharCount(0);
//     };

//     return (
//         <>
//             <ToastContainer position="top-right" autoClose={5000} />

//             <Box>
//                 <Container maxWidth="xl">
//                     <Box paddingY={2}>
//                         <Grid container justifyContent="center" alignItems="center">

//                             <Grid item xs={12} md={12} paddingY={4}>

//                                 <Typography variant='body' sx={{ color: '#002659', textAlign: 'left' }} paragraph>Create Business Idea</Typography>

//                                 <form onSubmit={handleSubmit(onSubmit)}>
//                                     <Grid item xs={12} mb={2}>
//                                         <Controller
//                                             name="title"
//                                             control={control}
//                                             defaultValue=""
//                                             rules={{
//                                                 required: 'Required',
//                                                 maxLength: {
//                                                     value: 250,
//                                                     message: 'Maximum 250 characters allowed',
//                                                 },
//                                             }}
//                                             render={({ field }) => (
//                                                 <OutlinedInput
//                                                     {...field}
//                                                     startAdornment={
//                                                         <InputAdornment position="start"
//                                                             sx={{
//                                                                 position: 'absolute', // Position the adornment absolutely
//                                                                 top: 0, // Align to the top of the input
//                                                                 left: 0, // Align to the left of the input
//                                                                 height: '100%', // Match input height if needed
//                                                                 padding: '10px', // Optional: Adjust spacing around the icon
//                                                                 pointerEvents: 'none', // Prevent interaction
//                                                                 alignItems: 'flex-start', // Ensure alignment at the top
//                                                                 display: 'flex',
//                                                             }}>
//                                                             <OFIdea />
//                                                         </InputAdornment>
//                                                     }
//                                                     placeholder="Enter the brief about business idea"
//                                                     multiline
//                                                     rows={4}
//                                                     error={!!errors.title}
//                                                     fullWidth
//                                                     inputProps={{ maxLength: 250 }}
//                                                     onChange={(e) => {
//                                                         field.onChange(e);
//                                                         setCharCount(e.target.value.length);
//                                                     }}
//                                                     sx={{
//                                                         backgroundColor: '#f9f9f9',
//                                                         borderRadius: '0.5rem',
//                                                         paddingLeft: '40px',
//                                                         '& .MuiOutlinedInput-input': {
//                                                             marginTop: '0px',
//                                                         },
//                                                     }}
//                                                 />
//                                             )}
//                                         />

//                                         {errors.title && <Typography color="error">{errors.title.message}</Typography>}

//                                         <Typography variant="body2" color="textSecondary" align="right">
//                                             {250 - charCount} characters remaining
//                                         </Typography>
//                                     </Grid>

//                                     <Grid container justifyContent="center" spacing={2}>
//                                         <Grid item>
//                                             <Button
//                                                 type="submit"
//                                                 variant="contained"
//                                                 sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}>
//                                                 Save Idea
//                                             </Button>
//                                         </Grid>
//                                         <Grid item>
//                                             <Button
//                                                 type="button"
//                                                 variant="outlined"
//                                                 sx={{ padding: '10px 20px' }}
//                                                 onClick={handleCancel}>
//                                                 Cancel
//                                             </Button>
//                                         </Grid>
//                                     </Grid>
//                                 </form>

//                             </Grid>
//                         </Grid>
//                     </Box>
//                 </Container >
//             </Box >
//         </>
//     );
// }

// export default Idea;
import React, { useState, useEffect } from 'react';
import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery, Grid, Container, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { API } from '../../../axios/api-path';
import { postApiCall, putApiCall } from '../../../axios/axios-methods';
import { toast, ToastContainer } from 'react-toastify';
import { OFIdea } from '../../../icons/CustomIcon';

function Idea({ editIdea, onSubmit, onClose, fetchIdeas }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { handleSubmit, control, formState: { errors }, reset } = useForm();

    const [charCount, setCharCount] = useState(0);

    // If editIdea exists, prefill the form with the data
    useEffect(() => {
        if (editIdea) {
            reset({
                ideaCode: editIdea.ideaCode,
                title: editIdea.text || '',
                description: editIdea.remarks || ''
            });
            setCharCount(editIdea.text?.length || 0);
        }
    }, [editIdea, reset]);

    const handleFormSubmit = async (data) => {
        console.log("update idea" + JSON.stringify(data, null, 2))
        const apiCallData = {
            ideaCode: data.ideaCode,
            text: data.title,
            remarks: data.description,
            type: "IDEA"
        };

        if (editIdea) {
            // If editing an existing idea, update it
            try {
                await putApiCall({
                    data: apiCallData,
                    path: API.createIdea(),

                    onSuccess: () => {
                        toast.success("Idea updated successfully");
                        reset();
                        setCharCount(0); // Reset character count
                        onSubmit(); // Callback to refresh the parent state
                        onClose(); // Close the form
                        fetchIdeas();
                    },
                    onError: (error) => {
                        console.error('Submission error:', error);
                        toast.error("Failed to update the idea");
                    },
                });
            } catch (error) {
                console.error('Unexpected error:', error);
                toast.error("Unexpected error occurred");
            }
        } else {
            // If no editIdea, create a new idea
            try {
                await postApiCall({
                    data: apiCallData,
                    path: API.createIdea(),
                    onSuccess: () => {
                        toast.success("Idea submitted successfully");
                        reset();
                        setCharCount(0); // Reset character count
                        onSubmit(); // Callback to refresh the parent state
                        onClose(); // Close the form
                        fetchIdeas();
                    },
                    onError: (error) => {
                        console.error('Submission error:', error);
                        toast.error("Failed to submit the idea");
                    },
                });
            } catch (error) {
                console.error('Unexpected error:', error);
                toast.error("Unexpected error occurred");
            }
        }
    };

    const handleCancel = () => {
        reset();
        setCharCount(0);
        onClose(); // Close the dialog/form
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} />

            <Box>
                <Container maxWidth="xl">
                    <Box paddingY={2}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} md={12} paddingY={4}>
                                <Typography variant='body' sx={{ color: '#002659', textAlign: 'left' }} paragraph>
                                    {editIdea ? 'Edit Business Idea' : 'Create Business Idea'}
                                </Typography>

                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <Grid item xs={12} mb={2}>
                                        <Controller
                                            name="title"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: 'Required',
                                                maxLength: {
                                                    value: 250,
                                                    message: 'Maximum 250 characters allowed',
                                                },
                                            }}
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    startAdornment={
                                                        <InputAdornment position="start"
                                                            sx={{
                                                                position: 'absolute', // Position the adornment absolutely
                                                                top: 0, // Align to the top of the input
                                                                left: 0, // Align to the left of the input
                                                                height: '100%', // Match input height if needed
                                                                padding: '10px', // Optional: Adjust spacing around the icon
                                                                pointerEvents: 'none', // Prevent interaction
                                                                alignItems: 'flex-start', // Ensure alignment at the top
                                                                display: 'flex',
                                                            }}>
                                                            <OFIdea />
                                                        </InputAdornment>
                                                    }
                                                    placeholder="Enter the brief about business idea"
                                                    multiline
                                                    rows={4}
                                                    error={!!errors.title}
                                                    fullWidth
                                                    inputProps={{ maxLength: 250 }}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setCharCount(e.target.value.length);
                                                    }}
                                                    sx={{
                                                        backgroundColor: '#f9f9f9',
                                                        borderRadius: '0.5rem',
                                                        paddingLeft: '40px',
                                                        '& .MuiOutlinedInput-input': {
                                                            marginTop: '0px',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />

                                        {errors.title && <Typography color="error">{errors.title.message}</Typography>}

                                        <Typography variant="body2" color="textSecondary" align="right">
                                            {250 - charCount} characters remaining
                                        </Typography>
                                    </Grid>

                                    <Grid container justifyContent="center" spacing={2}>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}>
                                                {editIdea ? 'Update Idea' : 'Save Idea'}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                sx={{ padding: '10px 20px' }}
                                                onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default Idea;
