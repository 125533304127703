/**
 * Path definition to route between JavaScript pages.
 * Paths can be accessed through Path's static
 * members, e.g., Path.index etc.
 * @type {string}
 */


const INDEX = "/";
const NOTFOUND = "/notfound";
const SIGNIN = "/signin";
const REGISTRATION = "/signup";
const LANDING = "/landing";
const FORGET = "/forget";



const API_CONTEXT_PATH = "/api";
const API_DOC_PATH = "/api-doc";


const API_SIGNUP_PERSONAL_INFO = `${API_CONTEXT_PATH}/auth/register`;
const API_SIGNUP_PERSONAL_INFO_ADMIN = `${API_CONTEXT_PATH}/auth/admin/register`;


// Contact & Feedback
const API_POST_CONTACT_FEEDBACK = `${API_CONTEXT_PATH}/pub/lead`

// Interest
const API_POST_INTEREST = `${API_CONTEXT_PATH}/in/interest`

// Idea
const API_CREATE_IDEA = `${API_CONTEXT_PATH}/admin/businessIdea`
const API_GET_ALL_IDEAS = `${API_CONTEXT_PATH}/pub/businessIdeas`

// success -story
const API_CREATE_SUCCESS_STORY = `${API_CONTEXT_PATH}/admin/successStory`
const API_GET_ALL_SUCCESS_STORIES = `${API_CONTEXT_PATH}/pub/successStories`


// Password
const API_PASSWORD_CHANGE = `${API_CONTEXT_PATH}/auth/change-password`
const API_PASSWORD_FORGOT = `${API_CONTEXT_PATH}/auth/forgot-password`
const API_PASSWORD_RESET = `${API_CONTEXT_PATH}/auth/reset-password`

// Profile Details
const API_GET_PROFILE_DETAILS = `${API_CONTEXT_PATH}/setting/profile`


// Franchiser
const API_SIGNUP_FRAN_BUSINESS_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-businessProf`
const API_SIGNUP_FRAN_BUSINESS_DETAILS_ADMIN = `${API_CONTEXT_PATH}/admin/franchise-businessProf`

const API_SIGNUP_FRAN_EXPANSION_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-expansion`
const API_SIGNUP_FRAN_EXPANSION_DETAILS_ADMIN = `${API_CONTEXT_PATH}/admin/franchise-expansion`

const API_SIGNUP_FRAN_FINANCIAL_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-financial-projection-training`
const API_SIGNUP_FRAN_FINANCIAL_DETAILS_ADMIN = `${API_CONTEXT_PATH}/admin/franchise-financial-projection-training`

const API_SIGNUP_FRAN_SPACE_REQUIREMENT_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-space-legal`
const API_SIGNUP_FRAN_SPACE_REQUIREMENT_DETAILS_ADMIN = `${API_CONTEXT_PATH}/admin/franchise-space-legal`

const API_SIGNUP_FRAN_APPEARANCE_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-apperance-media`
const API_SIGNUP_FRAN_APPEARANCE_DETAILS_ADMIN = `${API_CONTEXT_PATH}/admin/franchise-apperance-media`

const API_SIGNUP_PAYMENT_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-paymentDetails`

const API_GET_FRAN_BUSINESS_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-businessProf`
const API_GET_FRAN_EXPANSION_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-expansion`
const API_GET_FRAN_FINANCIAL_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-financial-projection-training`
const API_GET_FRAN_SPACE_REQUIREMENT_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-space-legal`
const API_GET_FRAN_APPEARANCE_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-apperance-media`


// Payment
const API_GET_PLAN_DETAILS = `${API_CONTEXT_PATH}/subs/plans?entityType=FRANCHISER`
const API_GET_SUBSCRIPTION_DETAILS = `${API_CONTEXT_PATH}/subs/subscription`
const API_CREATE_PAYMENT_ORDER = `${API_CONTEXT_PATH}/subs/createOrder`
const API_POST_PAYMENT = `${API_CONTEXT_PATH}/subs/submitPaymentStatus`

// Investor
const API_SIGNUP_INVESTOR_DETAILS = `${API_CONTEXT_PATH}/investor/investor-detail`
const API_GET_INVESTOR_DETAILS = `${API_CONTEXT_PATH}/investor/investor-detail`



// Sell Buisness
const API_SIGNUP_SBUSINESS = `${API_CONTEXT_PATH}/bs/business-seller-ownerInfo`
const API_SIGNUP_SBUSINESS_ADMIN = `${API_CONTEXT_PATH}/admin/business-seller-ownerInfo`

const API_SIGNUP_SBUSINESS_INFO = `${API_CONTEXT_PATH}/bs/business-seller-businessInfo`
const API_SIGNUP_SBUSINESS_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/business-seller-businessInfo`

const API_SIGNUP_SBUSINESS_FIN_INFO = `${API_CONTEXT_PATH}/bs/business-seller-financialInfo`
const API_SIGNUP_SBUSINESS_FIN_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/business-seller-financialInfo`

const API_SIGNUP_SBUSINESS_ADV_INFO = `${API_CONTEXT_PATH}/bs/business-seller-req-adv`
const API_SIGNUP_SBUSINESS_ADV_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/business-seller-req-adv`


// Partner or Business Collaaboration
const API_SIGNUP_PARTNER_COMPANY_INFO = `${API_CONTEXT_PATH}/bc/business-collab-companyInfo`
const API_SIGNUP_PARTNER_COMPANY_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/business-collab-companyInfo`

const API_SIGNUP_PARTNER_FINANCIAL_INFO = `${API_CONTEXT_PATH}/bc/business-collab-financial`
const API_SIGNUP_PARTNER_FINANCIAL_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/business-collab-financial`

const API_SIGNUP_PARTNER_MENTOR_INFO = `${API_CONTEXT_PATH}/bc/business-collab-mentorship`
const API_SIGNUP_PARTNER_MENTOR_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/business-collab-mentorship`

const API_SIGNUP_PARTNER_LEGAL_INFO = `${API_CONTEXT_PATH}/bc/business-collab-legalreq`
const API_SIGNUP_PARTNER_LEGAL_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/business-collab-legalreq`

const API_SIGNUP_PARTNER_APPEARANCE_INFO = `${API_CONTEXT_PATH}/bc/business-collab-appearance`
const API_SIGNUP_PARTNER_APPEARANCE_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/business-collab-appearance`

const API_GET_PARTNER_INFO = `${API_CONTEXT_PATH}/bc/business-collab`


// Dealer
const API_SIGNUP_DEALER_COMPANY_INFO = `${API_CONTEXT_PATH}/dd/dealer-businessInfo`
const API_SIGNUP_DEALER_COMPANY_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/dealer-businessInfo`

const API_SIGNUP_DEALER_MARKETING_INFO = `${API_CONTEXT_PATH}/dd/dealer-marketing`
const API_SIGNUP_DEALER_MARKETING_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/dealer-marketing`

const API_SIGNUP_DEALER_TRAINING_INFO = `${API_CONTEXT_PATH}/dd/dealer-training`
const API_SIGNUP_DEALER_TRAINING_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/dealer-training`

const API_SIGNUP_DEALER_APPEARANCE_INFO = `${API_CONTEXT_PATH}/dd/dealer-appearance`
const API_SIGNUP_DEALER_APPEARANCE_INFO_ADMIN = `${API_CONTEXT_PATH}/admin/dealer-appearance`

const API_GET_DEALER_INFO = `${API_CONTEXT_PATH}/dd/dealer`


// Need space
const API_GET_NEED_SPACE = `${API_CONTEXT_PATH}/needSpaces`
const API_GET_NEED_SPACE_ADMIN = `${API_CONTEXT_PATH}/admin/needSpaces`
const API_CREATE_NEED_SPACE = `${API_CONTEXT_PATH}/pub/needSpace`

// Loan On Property
const API_GET_LOAN_ON_PROPERTY = `${API_CONTEXT_PATH}/loansOnProperty`
const API_GET_LOAN_ON_PROPERTY_ADMIN = `${API_CONTEXT_PATH}/admin/loansOnProperty`


const API_CREATE_LOAN_ON_PROPERTY = `${API_CONTEXT_PATH}/pub/loanOnProperty`


const API_GET_SBUSINESS_INFO = `${API_CONTEXT_PATH}/bs/business-seller`


const API_GET_FRAN_SINGLE_CARD_DETAILS = `${API_CONTEXT_PATH}/pub/fran/franchiser`
const API_GET_BSELLER_SINGLE_CARD_DETAILS = `${API_CONTEXT_PATH}/pub/bs/business-seller`
const API_GET_PARTNER_SINGLE_CARD_DETAILS = `${API_CONTEXT_PATH}/pub/bc/business-collab`
const API_GET_DEALER_SINGLE_CARD_DETAILS = `${API_CONTEXT_PATH}/pub/dd/dealer`

const API_PAYMENT_DETAILS = `${API_CONTEXT_PATH}/fran/franchise-paymentDetails`
const API_DOCUMENT_UPLOAD = `${API_DOC_PATH}/documents/upload`

const API_SIGNIN = `${API_CONTEXT_PATH}/auth/authenticate`;

const MASTER_COUNTRIES = `${API_CONTEXT_PATH}/mast/countries`
const MASTER_STATES = `${API_CONTEXT_PATH}/mast/state`
const MASTER_CITY = `${API_CONTEXT_PATH}/mast/city`
const MASTER_INDUSTRIES = `${API_CONTEXT_PATH}/mast/industries`
const MASTER_INDUSTRY_SECTORS = `${API_CONTEXT_PATH}/mast/industry-sector`

const MASTER_RANGE = `${API_CONTEXT_PATH}/mast/range-master?type=NUMBER`

const CARD_LIST = `${API_CONTEXT_PATH}/pub/fran/franchiser-cards`
const BSELLER_CARD_LIST = `${API_CONTEXT_PATH}/pub/bs/business-seller-cards`
const PARTNER_CARD_LIST = `${API_CONTEXT_PATH}/pub/bc/business-collab-cards`
const DEALER_CARD_LIST = `${API_CONTEXT_PATH}/pub/dd/dealer-cards`

const API_GET_SINGLE_IMAGE = `${API_DOC_PATH}/documents`

// Interests

const API_GET_INTEREST_ALL = `${API_CONTEXT_PATH}/in/interests`
const API_GET_INTEREST_ON_ME = `${API_CONTEXT_PATH}/in/interests-in-me`
const API_GET_INTEREST_BY_PROFILE_ID = `${API_CONTEXT_PATH}/in/interests-on-business`







export class API {

    static get landing() {
        return LANDING;
    }
    static get index() {
        return INDEX;
    }

    static get forget() {
        return FORGET;
    }
    static get signin() {
        return SIGNIN;
    }

    static get signup() {
        return REGISTRATION;
    }

    static get notFound() {
        return NOTFOUND;
    }

    static postContactOrFeedback() {
        return API_POST_CONTACT_FEEDBACK;
    }

    static postInterest() {
        return API_POST_INTEREST;
    }

    static createIdea() {
        return API_CREATE_IDEA;
    }

    static changeProfilePassword() {
        return API_PASSWORD_CHANGE;
    }

    static getProfileDetails() {
        return API_GET_PROFILE_DETAILS;
    }

    static forgotProfilePassword() {
        return API_PASSWORD_FORGOT;
    }

    static resetProfilePassword() {
        return API_PASSWORD_RESET
    }

    static createSuccessStory() {
        return API_CREATE_SUCCESS_STORY;
    }

    static signupPersonalInfo() {
        return API_SIGNUP_PERSONAL_INFO;
    }

    static signupPersonalInfoAdmin() {
        return API_SIGNUP_PERSONAL_INFO_ADMIN;
    }


    static signupPaymentDetails() {
        return API_SIGNUP_PAYMENT_DETAILS;
    }


    static signupFranBusinessDetails() {
        return API_SIGNUP_FRAN_BUSINESS_DETAILS;
    }

    static signupFranBusinessDetailsAdmin() {
        return API_SIGNUP_FRAN_BUSINESS_DETAILS_ADMIN;
    }



    static signupFranExpansionDetails() {
        return API_SIGNUP_FRAN_EXPANSION_DETAILS;
    }

    static signupFranExpansionDetailsAdmin() {
        return API_SIGNUP_FRAN_EXPANSION_DETAILS_ADMIN;
    }

    static signupFranFinancialDetails() {
        return API_SIGNUP_FRAN_FINANCIAL_DETAILS;
    }

    static signupFranFinancialDetailsAdmin() {
        return API_SIGNUP_FRAN_FINANCIAL_DETAILS_ADMIN;
    }

    static signupFranSpaceRequirementDetails() {
        return API_SIGNUP_FRAN_SPACE_REQUIREMENT_DETAILS;
    }

    static signupFranSpaceRequirementDetailsAdmin() {
        return API_SIGNUP_FRAN_SPACE_REQUIREMENT_DETAILS_ADMIN;
    }

    static signupFranBusinessDetails() {
        return API_SIGNUP_FRAN_BUSINESS_DETAILS;
    }

    static signupFranAppearanceDetails() {
        return API_SIGNUP_FRAN_APPEARANCE_DETAILS;
    }



    static signupFranAppearanceDetailsAdmin() {
        return API_SIGNUP_FRAN_APPEARANCE_DETAILS_ADMIN;
    }

    static getFranBusinessDetails() {
        return API_GET_FRAN_BUSINESS_DETAILS;
    }

    static getFranExpansionDetails() {
        return API_GET_FRAN_EXPANSION_DETAILS;
    }

    static getFranFinancialDetails() {
        return API_GET_FRAN_FINANCIAL_DETAILS;
    }

    static getFranSpaceRequirementDetails() {
        return API_GET_FRAN_SPACE_REQUIREMENT_DETAILS;
    }

    static getPaymentDetails() {
        return API_GET_PLAN_DETAILS;
    }

    static createPaymentOrder() {
        return API_CREATE_PAYMENT_ORDER;
    }


    static postPayment() {
        return API_POST_PAYMENT;
    }

    static getSubscriptionDetails() {
        return API_GET_SUBSCRIPTION_DETAILS;
    }

    static getFranAppearanceDetails() {
        return API_GET_FRAN_APPEARANCE_DETAILS;
    }

    static signupInvestorDetails() {
        return API_SIGNUP_INVESTOR_DETAILS;
    }

    static signupBSellerDetails() {
        return API_SIGNUP_SBUSINESS;
    }
    static signupBSellerDetailsAdmin() {
        return API_SIGNUP_SBUSINESS_ADMIN;
    }

    static signupBSellerBusinessInfo() {
        return API_SIGNUP_SBUSINESS_INFO
    }
    static signupBSellerBusinessInfoAdmin() {
        return API_SIGNUP_SBUSINESS_INFO_ADMIN
    }

    static signupBSellerFinancialInfo() {
        return API_SIGNUP_SBUSINESS_FIN_INFO
    }

    static signupBSellerFinancialInfoAdmin() {
        return API_SIGNUP_SBUSINESS_FIN_INFO_ADMIN
    }

    static signupBSellerAdvInfo() {
        return API_SIGNUP_SBUSINESS_ADV_INFO
    }

    static signupBSellerAdvInfoAdmin() {
        return API_SIGNUP_SBUSINESS_ADV_INFO_ADMIN
    }

    static signupPartnerCompanyInfo() {
        return API_SIGNUP_PARTNER_COMPANY_INFO
    }

    static signupPartnerCompanyInfoAdmin() {
        return API_SIGNUP_PARTNER_COMPANY_INFO_ADMIN
    }

    static signupPartnerFinancialInfo() {
        return API_SIGNUP_PARTNER_FINANCIAL_INFO
    }
    static signupPartnerFinancialInfoAdmin() {
        return API_SIGNUP_PARTNER_FINANCIAL_INFO_ADMIN
    }

    static signupPartnerMentorInfo() {
        return API_SIGNUP_PARTNER_MENTOR_INFO
    }
    static signupPartnerMentorInfoAdmin() {
        return API_SIGNUP_PARTNER_MENTOR_INFO_ADMIN
    }

    static signupPartnerLegalInfo() {
        return API_SIGNUP_PARTNER_LEGAL_INFO
    }
    static signupPartnerLegalInfoAdmin() {
        return API_SIGNUP_PARTNER_LEGAL_INFO_ADMIN
    }
    static signupPartnerAppearanceInfo() {
        return API_SIGNUP_PARTNER_APPEARANCE_INFO
    }
    static signupPartnerAppearanceInfoAdmin() {
        return API_SIGNUP_PARTNER_APPEARANCE_INFO_ADMIN
    }

    static getPartnerInfo() {
        return API_GET_PARTNER_INFO
    }

    static getNeedSpace() {
        return API_GET_NEED_SPACE
    }
    static createNeedSpace() {
        return API_CREATE_NEED_SPACE
    }

    static getLoanOnProperty() {
        return API_GET_LOAN_ON_PROPERTY
    }
    static createLoanOnProperty() {
        return API_CREATE_LOAN_ON_PROPERTY
    }

    static getBSellerInfo() {
        return API_GET_SBUSINESS_INFO
    }





    static signupDealerCompanyInfo() {
        return API_SIGNUP_DEALER_COMPANY_INFO
    }
    static signupDealerCompanyInfoAdmin() {
        return API_SIGNUP_DEALER_COMPANY_INFO_ADMIN
    }
    static signupDealerMarketingInfo() {
        return API_SIGNUP_DEALER_MARKETING_INFO
    }
    static signupDealerMarketingInfoAdmin() {
        return API_SIGNUP_DEALER_MARKETING_INFO_ADMIN
    }
    static signupDealerTrainingInfo() {
        return API_SIGNUP_DEALER_TRAINING_INFO
    }
    static signupDealerTrainingInfoAdmin() {
        return API_SIGNUP_DEALER_TRAINING_INFO_ADMIN
    }

    static signupDealerAppearanceInfo() {
        return API_SIGNUP_DEALER_APPEARANCE_INFO
    }
    static signupDealerAppearanceInfoAdmin() {
        return API_SIGNUP_DEALER_APPEARANCE_INFO_ADMIN
    }

    static getDealerInfo() {
        return API_GET_DEALER_INFO
    }


    static getInvestorDetails() {
        return API_GET_INVESTOR_DETAILS;
    }


    static fileUpload() {
        return API_DOCUMENT_UPLOAD;
    }


    static signin() {
        return API_SIGNIN;
    }


    static getcountries() {
        return MASTER_COUNTRIES;

    }

    static getStates() {
        return MASTER_STATES;

    }

    static getCities() {
        return MASTER_CITY;

    }



    static getIndustries() {
        return MASTER_INDUSTRIES;

    }

    static getIndustrySector() {

        return MASTER_INDUSTRY_SECTORS;
    }

    static getRange() {
        return MASTER_RANGE;

    }

    static getCardList() {
        return CARD_LIST;

    }

    static getAllIdeas() {
        return API_GET_ALL_IDEAS
    }

    static getAllSuccessStories() {
        return API_GET_ALL_SUCCESS_STORIES
    }

    static getBSellerCardList() {
        return BSELLER_CARD_LIST
    }
    static getPartnerCardList() {
        return PARTNER_CARD_LIST
    }
    static getDealerCardList() {
        return DEALER_CARD_LIST
    }

    static getSingleCardDetails() {
        return API_GET_FRAN_SINGLE_CARD_DETAILS;
    }
    static getPartnerSingleCardDetails() {
        return API_GET_PARTNER_SINGLE_CARD_DETAILS;
    }
    static getBSellerSingleCardDetails() {
        return API_GET_BSELLER_SINGLE_CARD_DETAILS;
    }
    static getDealerSingleCardDetails() {
        return API_GET_DEALER_SINGLE_CARD_DETAILS;
    }

    static getSingleImage() {
        return API_GET_SINGLE_IMAGE;
    }

    static getInterestOnMe() {
        return API_GET_INTEREST_ON_ME;
    }
    static getAllInterest() {
        return API_GET_INTEREST_ALL;
    }
    static getInterestsByProfileId() {
        return API_GET_INTEREST_BY_PROFILE_ID;
    }


}
