
import FinancialDetailsForm from './FinancialDetailsForm'
import React, { useEffect, useState } from 'react'
import PropertyDetailsForm from './PropertyDetailsForm'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import CustomButton from '../../../helpers/CustomButton';
import { API } from '../../../axios/api-path';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError } from '../../../utils/toast-notify';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { fetchCities, fetchExpansionInfo, fetchExpansionStates, fetchFFinancialInfo, fetchPropertyInfo, fetchStates } from '../../../axios/api-service';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import SpaceRequirementDetailsForm from './SpaceRequirementDetailsForm';
import ProgressSteperFranchisor from '../progress/ProgressSteperFranchisor';
import { booleanValidation, cityValidation, numericValidation, stateValidation, textValidation } from '../../../utils/validations';
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';

const SpaceRequirementDetails = ({ isDashboardMode }) => {



    const navigate = useNavigate();
    const location = useLocation();

    const [perInfo, setPerInfo] = useRecoilState(perInfoState);
    const [spaceReqInfo, setSpaceReqInfo] = useState(null);
    const [isSpaceReqInfo, setIsSpaceReqInfo] = useState(false);
    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchExpansionInfo();


                if (response.status === "success") {
                    setSpaceReqInfo(response.data);

                    setIsSpaceReqInfo(true);
                } else {
                    setIsSpaceReqInfo(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);

    const validationSchema = yup.object({

        floorAreaRequirement: numericValidation,
        preferredPropertyType: textValidation({ message: 'Rrequired' }),
        state: stateValidation,
        city: cityValidation,
        //  assistanceForSiteSelection: booleanValidation,
        //   standardFranchiseAgreement: booleanValidation,
        durationOfFranchiseContract: numericValidation,
        // contractRenewal: booleanValidation,
        renewalTerms: textValidation({ message: 'Rrequired' }),
        insuranceRequirement: textValidation({ message: 'Rrequired' }),
        licensingRequirement: textValidation({ message: 'Rrequired' })



    });



    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {


        },
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (spaceReqInfo) {

            reset({
                "floorAreaRequirement": spaceReqInfo.floorAreaRequirement,
                "preferredPropertyType": spaceReqInfo.preferredPropertyType,
                "state": spaceReqInfo.state,
                "city": spaceReqInfo.city,
                "assistanceForSiteSelection": spaceReqInfo.assistanceForSiteSelection === true ? 'Yes' : 'No',
                "standardFranchiseAgreement": spaceReqInfo.standardFranchiseAgreement === true ? 'Yes' : 'No',
                "durationOfFranchiseContract": spaceReqInfo.durationOfFranchiseContract,
                "contractRenewal": spaceReqInfo.contractRenewal === true ? 'Yes' : 'No',
                "renewalTerms": spaceReqInfo.renewalTerms,
                "insuranceRequirement": spaceReqInfo.insuranceRequirement,
                "licensingRequirement": spaceReqInfo.licensingRequirement
            });
        }
    }, [spaceReqInfo, reset]);

    const {

        city,
        state,
    }
        = watch({ control });

    const [country, setCountry] = useState({ id: 1, name: 'India' });
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);
    useEffect(() => {

        fetchStates(setStates, 1);

    }, []);

    const onSubmit = async (data) => {

        const apiCallData =
        {
            "floorAreaRequirement": data.floorAreaRequirement,
            "preferredPropertyType": data.preferredPropertyType,
            "state": data.state,
            "city": data.city,
            "assistanceForSiteSelection": data.assistanceForSiteSelection === 'Yes' ? true : false,
            "standardFranchiseAgreement": data.standardFranchiseAgreement === 'Yes' ? true : false,
            "durationOfFranchiseContract": data.durationOfFranchiseContract,
            "contractRenewal": data.contractRenewal === 'Yes' ? true : false,
            "renewalTerms": data.renewalTerms,
            "insuranceRequirement": data.insuranceRequirement,
            "licensingRequirement": data.licensingRequirement


        };


        //const path = API.signupFranSpaceRequirementDetails();

        const userCode = sessionStorage.getItem("userCode");
        let path
        if ((perInfo?.roles?.includes("ADMIN")))
            path = `${API.signupFranSpaceRequirementDetailsAdmin()}?userCode=${encodeURIComponent(userCode)}`

        else
            path = API.API.signupFranSpaceRequirementDetails();




        try {
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {
                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }
                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '6'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '4'));
    };



















    return (
        <>  <ToastContainer

        />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 2,
                    margin: 4,
                }}
            >

                <>

                </>

                <SpaceRequirementDetailsForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    cities={cities}
                    states={states}



                    setSelectedCity={setSelectedCity}



                    setSelectedState={setSelectedState}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}

                />

                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}
            </Paper>

        </>
    )
}

export default SpaceRequirementDetails