import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false
});



// Request Interceptor
api.interceptors.request.use(
    config => {


        const token = localStorage.getItem('token');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => Promise.reject(error)
);

// Response Interceptor
api.interceptors.response.use(
    response => {
        if (response.status === 200) {
            // console.log('Successful response:', response.data);
        }
        if (response.status === 201) {
            // console.log('Successful response:', response.data);
        }
        return response;
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    console.log('Unauthorized. Redirecting to login.');

                    break;
                case 500:
                    console.log('Server error. Please try again later.');
                    break;
                default:
                    console.log('An error occurred:', error.message);
            }
        }
        return Promise.reject(error);
    }
);


export const apiFileUpload = axios.create({
    // baseURL: process.env.REACT_APP_BASE_URL,
    baseURL: `http://onefranhub.com`,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
    withCredentials: false
});

// Request Interceptor
apiFileUpload.interceptors.request.use(
    config => {


        const token = localStorage.getItem('token');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => Promise.reject(error)
);

// Response Interceptor
apiFileUpload.interceptors.response.use(
    response => {
        if (response.status === 200) {
            // console.log('Successful response:', response.data);
        }
        if (response.status === 201) {
            // // console.log('Successful response:', response.data);
        }
        return response;
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    console.log('Unauthorized. Redirecting to login.');

                    break;
                case 500:
                    console.log('Server error. Please try again later.');
                    break;
                default:
                    console.log('An error occurred:', error.message);
            }
        }
        return Promise.reject(error);
    }
);


export const apiDownloadFile = axios.create({
    baseURL: `http://onefranhub.com`,
    headers: {
        'Content-Type': 'application/octet-stream',
    },
    withCredentials: false,
});

// Request Interceptor
apiDownloadFile.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response Interceptor
apiDownloadFile.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            // // console.log('Successful response:', response.data);
        }
        return response;
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    console.log('Unauthorized. Redirecting to login.');
                    break;
                case 500:
                    console.log('Server error. Please try again later.');
                    break;
                default:
                    console.log('An error occurred:', error.message);
            }
        }
        return Promise.reject(error);
    }
);


