

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';


import { cityValidation, countryValidation, dropdownValidation, emailValidation, industryValidation, numericValidation, phoneValidation, pincodeValidation, royaltyFeeValidation, sectorValidation, stateValidation, textValidation } from '../../../utils/validations';
import BCCompanyAndGoalInfoForm from './BCCompanyAndGoalInfoForm';
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';

const BCCompanyAndGoalInfo = ({ isDashboardMode }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    const [perInfo, setPerInfo] = useRecoilState(perInfoState);
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);
    // const [outletStates, setOutletStates] = useState([])

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchRange(setRanges);
        fetchIndustries(setIndustries);

        const fetchData = async () => {
            const path = API.getPartnerInfo();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.companyInfoStatus === 'COMPLETE') {
                    setFormData(response.data.companyInfo);

                    setIsFormData(true);
                } else {
                    setIsFormData(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);



    const validationSchema = yup.object({




        "businessName": textValidation({ message: 'Rrequired' }),
        "establishmentYear": numericValidation,
        "industry": industryValidation,
        "sector": sectorValidation,
        "businessDescription": textValidation({ message: 'Rrequired' }),

        "businessCollaborationType": dropdownValidation,

        "companyType": dropdownValidation,
        "ownershipDetails": textValidation({ message: 'Rrequired' }),
        "keyMember1": textValidation({ message: 'Rrequired' }),
        "keyMember2": textValidation({ message: 'Rrequired' }),
        "advisoryBoardMember": textValidation({ message: 'Rrequired' }),
        "numberOfEmployees": numericValidation,
        "desiredOutcome": textValidation({ message: 'Rrequired' }),
        "investmentRequired": numericValidation,
        "share": numericValidation



    });




    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
    }
        = watch({ control });








    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);

    // useEffect(() => {
    //     fetchCountries(setCountries);
    //     fetchIndustries(setIndustries);
    //     fetchRange(setRanges);
    // }, []);

    useEffect(() => {


        if (selectedCountry) {


            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);











    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);



    useEffect(() => {

        if (selectedCountry) {

            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);



    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }


    useEffect(() => {
        if (formData) {

            reset({

                "businessName": formData?.businessName,
                "establishmentYear": formData?.establishmentYear,
                "industry": formData?.industry,
                "sector": formData?.industrySector,
                "businessDescription": formData?.businessDescription,

                "businessCollaborationType": formData?.businessCollaborationType,
                "share": formData?.share,
                "investmentRequired": formData?.investmentRequired,
                "companyType": formData?.companyType,
                "ownershipDetails": formData?.ownershipDetails,
                "keyMember1": formData?.keyMember1,
                "keyMember2": formData?.keyMember2,
                "advisoryBoardMember": formData?.advisoryBoardMember,
                "numberOfEmployees": formData?.numberOfEmployees,
                "desiredOutcome": formData?.desiredOutcome,

                "street": formData?.address?.street,
                "city": formData?.address?.city,
                "state": formData?.address?.state,
                "country": formData?.address?.country,
                "pinCode": formData?.address?.postalCode,



                contactNo: {
                    countryCode: extractParts(formData?.contactNo).countryCode || '',
                    phoneNumber: extractParts(formData?.contactNo).phoneNumber || '',
                },
                alternateContactNo: {
                    countryCode: extractParts(formData?.alternateContactNo).countryCode || '',
                    phoneNumber: extractParts(formData?.alternateContactNo).phoneNumber || '',
                },




            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {


            "businessName": data?.businessName,
            "establishmentYear": data?.establishmentYear,
            "industry": data?.industry,
            "industrySector": data?.sector,
            "businessDescription": data?.businessDescription,

            "businessCollaborationType": data?.businessCollaborationType,
            "share": data?.share,
            "investmentRequired": data?.investmentRequired,
            "companyType": data?.companyType,
            "ownershipDetails": data?.ownershipDetails,
            "keyMember1": data?.keyMember1,
            "keyMember2": data?.keyMember2,
            "advisoryBoardMember": data?.advisoryBoardMember,
            "numberOfEmployees": data?.numberOfEmployees,
            "desiredOutcome": data?.desiredOutcome,

            //"contactNo": data?.contactNo?.countryCode + "-" + data.contactNo?.phoneNumber,

            address: {
                street: data?.street,
                city: { id: parseInt(data?.city?.id, 10) },
                state: { id: parseInt(data?.state?.id, 10) },
                postalCode: data?.pinCode,
                country: { id: parseInt(data?.country?.id, 10) }
            },

        };


        //const path = API.signupPartnerCompanyInfo();
        const userCode = sessionStorage.getItem("userCode");
        let path
        if ((perInfo?.roles?.includes("ADMIN")))
            path = `${API.signupPartnerCompanyInfoAdmin()}?userCode=${encodeURIComponent(userCode)}`

        else
            path = API.signupPartnerCompanyInfo();




        try {

            console.log(" Submit data " + JSON.stringify(apiCallData, null, 2))
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }



                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '3'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '2'));
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <BCCompanyAndGoalInfoForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}
                    countries={countries}
                    cities={cities}
                    states={states}

                    industries={industries}
                    industrySectors={industrySectors}
                    ranges={ranges}
                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}
                    setSelectedIndustry={setSelectedIndustry}
                    setSelectedSector={setSelectedSector}
                    setSelectedState={setSelectedState}








                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}


                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default BCCompanyAndGoalInfo