

import React, { useState } from 'react';
import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery, Grid, Container, Paper, Button, IconButton } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { API } from '../../../axios/api-path';
import { postApiCall, putApiCallWithCred } from '../../../axios/axios-methods';
import { toast, ToastContainer } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import { OFEditIcon } from '../../../icons/CustomIcon';

function ChangePassword() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isEditing, setIsEditing] = useState(false);

    const { handleSubmit, control, formState: { errors }, reset } = useForm();

    const onSubmit = async (data) => {
        const apiCallData = {
            currentPassword: data.currentPassword, // Current password field
            newPassword: data.newPassword,       // New password field
        };

        const path = API.changeProfilePassword();
        try {
            console.log("Submitting data:", apiCallData); // Debug log
            await putApiCallWithCred({
                //  data: apiCallData,
                params: apiCallData,
                path: path,
                onSuccess: (res) => {
                    toast.success("Password changed successfully");
                    reset(); // Reset fields after successful submission
                },
                onError: (error) => {
                    console.error('Submission error:', error);
                    toast.error("Failed to change the password");
                },
            });
        } catch (error) {
            console.error('Unexpected error:', error);
            toast.error("Unexpected error occurred");
        }
    };


    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} />
            <div>  <Box
                paddingX={0}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            > <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    //  paddingBottom: 4,
                    backgroundColor: '#F1F6FF'
                }}
            >
                    <Box padding={2}>
                        <Typography variant='TITLE3'>Change Password </Typography>
                        <IconButton
                            onClick={toggleEdit}
                            style={{ float: 'right' }}>
                            <OFEditIcon />
                        </IconButton>

                        {isEditing && (
                            <Box>
                                <Container maxWidth="xl">
                                    <Box paddingY={2}>
                                        <Grid container justifyContent="center" alignItems="left">


                                            <Grid item xs={12} md={6} paddingY={4}>

                                                {/* <Typography variant='h6' sx={{ color: '#002659' }} paragraph>Password Details</Typography> */}

                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <Grid item xs={12} mb={2}>
                                                        <Controller
                                                            name="currentPassword"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: 'Current password is required' }}
                                                            render={({ field }) => (
                                                                <OutlinedInput
                                                                    {...field}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <LockIcon style={{ color: '#666666' }} />
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder="Current Password"
                                                                    type="password"
                                                                    error={!!errors.currentPassword}
                                                                    fullWidth
                                                                    sx={{
                                                                        backgroundColor: '#f9f9f9',
                                                                        borderRadius: '0.5rem',
                                                                        padding: '10px',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.currentPassword && <Typography color="error">{errors.currentPassword.message}</Typography>}
                                                    </Grid>

                                                    <Grid item xs={12} mb={2}>
                                                        <Controller
                                                            name="newPassword"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: 'New password is required' }}
                                                            render={({ field }) => (
                                                                <OutlinedInput
                                                                    {...field}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <LockIcon style={{ color: '#666666' }} />
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder="New Password"
                                                                    type="password"
                                                                    error={!!errors.newPassword}
                                                                    fullWidth
                                                                    sx={{
                                                                        backgroundColor: '#f9f9f9',
                                                                        borderRadius: '0.5rem',
                                                                        padding: '10px',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.newPassword && <Typography color="error">{errors.newPassword.message}</Typography>}
                                                    </Grid>

                                                    <Grid item xs={12} md={6} mb={2}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}>
                                                            Change Password
                                                        </Button>
                                                    </Grid>
                                                </form>

                                            </Grid>

                                            <Grid item xs={12} md={6} paddingY={2}>
                                                <Box>
                                                    {/* Placeholder for other components or images */}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Container>
                            </Box>
                        )}
                    </Box>
                    <Grid container paddingX={2} spacing={1} ml={2}>

                    </Grid>
                </Paper>
            </Box></div >
        </>
    );
}

export default ChangePassword;
