import React, { useEffect, useState } from 'react'
import PropertyDetailsForm from './PropertyDetailsForm'
import { Paper, Stack } from '@mui/material';
import CustomButton from '../../../helpers/CustomButton';
import { API } from '../../../axios/api-path';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError } from '../../../utils/toast-notify';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { fetchExpansionStates, fetchPropertyInfo, fetchRange, fetchStates } from '../../../axios/api-service';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { currencyValidation, royaltyFeeValidation } from '../../../utils/validations';
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';


const PropertyDetails = ({ isDashboardMode }) => {



    const navigate = useNavigate();
    const location = useLocation();
    const [perInfo, setPerInfo] = useRecoilState(perInfoState);

    const [formData, setFormData] = useState(null);
    const [isPropertyInfo, setIsPropertyInfo] = useState(false);
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [ranges, setRanges] = useState([]);

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);
    useEffect(() => {
        fetchStates(setStates, 1);
        fetchRange(setRanges);
        const fetchData = async () => {
            try {
                const response = await fetchPropertyInfo();


                if (response.status === "success") {
                    setFormData(response.data);

                    isPropertyInfo(true);
                } else {
                    isPropertyInfo(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);



    const validationSchema = yup.object({

        franchiserModalType: yup.array()
            .min(1, 'At least one franchise model type must be selected') // Ensure at least one option is selected
            .required('You must select at least one option'),

        // franchiserModalType: yup.array()
        //     .of(yup.object().shape({
        //         id: yup.number().required(),
        //         name: yup.string().required(),
        //     }))
        //     .min(1, 'At least one franchise model type must be selected')
        // ,
        // franchiserModalType: yup
        //     .array()
        //     .min(1, 'At least one franchise model type must be selected')
        //     .required('Franchise Model Type is required'),

        // initialFranchiseFee: currencyValidation,


        royaltyFee: royaltyFeeValidation,


        territoryExclusive: yup
            .string()
            .required('Territory Exclusivity is required'),
        territoryDetails: yup
            .string()
            .required('Territory details are required'),
        franchiseKitAvailable: yup
            .string()
            .required('Franchise Kit Availability is required'),
        expectedROImonths: yup
            .string()
            .required('Expected ROI in months is required')
            .typeError('Expected ROI must be a number'),
        payBackPeriodMonths: yup
            .string()
            .required('Payback Period is required')
            .typeError('Payback Period must be a number'),

        additionalInvestmentRequirements: yup.object().shape({
            name: yup.string().required('Additional Investment Requirements are required')
        }).required('Additional Investment Requirements are required').nullable(),


        expansionPlan: yup
            .string()
            .required('Expansion Plan is required'),
        // expansionState: yup
        //     .array()
        //     .min(1, 'At least one state must be selected')
        //     .required('Expansion State is required'),


        preferredMarket: yup.object().shape({
            name: yup.string().required('Preferred Market Conditions are required')
        }).required('Preferred Market Conditions are required').nullable(),


        tgtNoOfNewOutlet: yup
            .string()
            .required('Targeted Number of New Outlets is required')
            .typeError('Targeted Number of New Outlets must be a number'),
    });




    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {



        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        expansionPlan,
        city,
        state,
    }
        = watch({ control });


    useEffect(() => {

        if (expansionPlan === 'India') {
            fetchStates(setStates, 1);

        }
        if (expansionPlan === 'International') {
            setValue('expansionState', [])

        }
    }, [expansionPlan, setValue]);




    useEffect(() => {
        if (formData) {
            const formattedRoyaltyFee = formData?.royaltyFee
                ? {
                    type: Object.keys(formData.royaltyFee)[0],
                    value: Object.values(formData.royaltyFee)[0],
                }
                : { type: null, value: null };




            reset({
                "franchiserModalType": formData?.franchiserModalType,
                "initialFranchiseFee": formData?.initialFranchiseFee,
                "royaltyFee": formattedRoyaltyFee,
                "territoryExclusive": formData?.territoryExclusive === true ? 'Yes' : 'No',
                "territoryDetails": formData?.territoryDetails,
                "franchiseKitAvailable": formData?.franchiseKitAvailable === true ? 'Yes' : 'No',
                "expectedROImonths": formData?.expectedROImonths,
                "payBackPeriodMonths": formData?.payBackPeriodMonths,
                "additionalInvestmentRequirements": formData?.additionalInvestmentRequirements,
                "expansionPlan": formData?.expansionPlan,

                "expansionState": formData?.expansionStates,
                "preferredMarket": formData?.preferredMarket,
                "tgtNoOfNewOutlet": formData?.tgtNoOfNewOutlet



            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const formattedData = {
            [data.royaltyFee.type]: data.royaltyFee.value,
        };

        const apiCallData =
        {


            "franchiserModalType": data?.franchiserModalType,
            "initialFranchiseFee": data?.initialFranchiseFee,
            "royaltyFee": formattedData,
            "territoryExclusive": data?.territoryExclusive === 'Yes' ? true : false,
            "territoryDetails": data?.territoryDetails,
            "franchiseKitAvailable": data?.franchiseKitAvailable === 'Yes' ? true : false,
            "expectedROImonths": data?.expectedROImonths,
            "payBackPeriodMonths": data?.payBackPeriodMonths,
            "additionalInvestmentRequirements": data?.additionalInvestmentRequirements,
            "expansionPlan": data?.expansionPlan,

            "expansionStates": data?.expansionState,
            "preferredMarket": data?.preferredMarket,
            "tgtNoOfNewOutlet": data?.tgtNoOfNewOutlet



        };


        //const path = API.signupFranExpansionDetails();

        const userCode = sessionStorage.getItem("userCode");
        let path
        if ((perInfo?.roles?.includes("ADMIN")))
            path = `${API.signupFranExpansionDetailsAdmin()}?userCode=${encodeURIComponent(userCode)}`

        else
            path = API.signupFranExpansionDetails();



        try {
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {
                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }

                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '4'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '2'));
    };


    return (
        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 2,
                    margin: 4,
                }}
            >
                <PropertyDetailsForm control={control} errors={errors}

                    // expansionStates={expansionStates}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    states={states}
                    setSelectedState={setSelectedState}
                    expansionPlan={expansionPlan}
                    ranges={ranges}

                />
                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}
            </Paper>

        </>
    )
}

export default PropertyDetails