
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Container,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { postApiCall, deleteApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import CustomTableWithAction from '../../../helpers/tables/CustomTableWithAction';
import Idea from './Idea'; // Assuming the existing Idea component is in the same directory
import { fetchData } from '../../../axios/api-service';

function IdeaList() {
    const [ideas, setIdeas] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editIdea, setEditIdea] = useState(null); // To store the idea being edited
    const [confirmDialog, setConfirmDialog] = useState(false); // Confirmation dialog state
    const [ideaToDelete, setIdeaToDelete] = useState(null); // Idea to delete

    // Fetch all ideas
    const fetchIdeas = async () => {
        const path = API.getAllIdeas();
        try {
            const response = await fetchData(path);
            setIdeas(response.data);
        } catch (error) {
            console.error('Error fetching ideas:', error);
        }
    };

    useEffect(() => {
        fetchIdeas();
    }, []);

    // Handle add new idea
    const handleAddClick = () => {
        setOpenDialog(true);
        setEditIdea(null); // Set to null for adding a new idea
    };

    // Handle edit existing idea
    const handleEditClick = (idea) => {
        setOpenDialog(true);
        setEditIdea(idea);
    };

    // Handle delete action
    const handleDeleteClick = (idea) => {
        setIdeaToDelete(idea); // Set the idea to delete
        setConfirmDialog(true); // Open the confirmation dialog
    };

    const confirmDelete = async () => {
        try {
            await deleteApiCall({
                path: API.createIdea(),
                params: { ideaCode: ideaToDelete.ideaCode },
            });
            toast.success('Idea deleted successfully');
            fetchIdeas(); // Refresh the ideas list
        } catch (error) {
            console.error('Error deleting idea:', error);
            toast.error('Failed to delete idea');
        } finally {
            setConfirmDialog(false); // Close the confirmation dialog
            setIdeaToDelete(null); // Reset the idea to delete
        }
    };

    // Handle form submission (edit or add)
    const handleFormSubmit = async (data) => {
        try {
            if (editIdea) {
                // If editIdea exists, update the idea
                await postApiCall({ path: API.addIdea(editIdea.id), data });
                toast.success('Idea updated successfully');

            } else {
                // If no editIdea, add a new idea
                await postApiCall({ path: API.addIdea(), data });
                toast.success('Idea added successfully');

            }
            fetchIdeas(); // Refresh the ideas list
            setOpenDialog(false); // Close dialog
        } catch (error) {
            toast.error('Failed to submit idea');
        }
    };

    return (
        <Container maxWidth="xl">
            <Box>
                <ToastContainer position="top-right" autoClose={5000} />
                <Typography variant="h6" sx={{ color: '#002659', textAlign: 'left' }} paragraph>
                    Create Business Idea
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
                    <Button variant="contained" color="primary" onClick={handleAddClick}>
                        Add business idea
                    </Button>
                </Box>

                <CustomTableWithAction
                    data={ideas}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                />

                {/* Dialog for Add/Edit Idea */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
                    <DialogTitle>{editIdea ? 'Edit Idea' : 'Add New Idea'}</DialogTitle>
                    <DialogContent>
                        <Idea
                            editIdea={editIdea}
                            onSubmit={handleFormSubmit}
                            onClose={() => setOpenDialog(false)}
                            fetchIdeas={fetchIdeas}
                        />
                    </DialogContent>
                </Dialog>

                {/* Confirmation Dialog */}
                <Dialog
                    open={confirmDialog}
                    onClose={() => setConfirmDialog(false)}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle sx={{ marginBottom: '8px' }}>Delete</DialogTitle>
                    <DialogContent sx={{ paddingBottom: '16px' }}>
                        <Typography variant="body1" sx={{ textAlign: 'left', marginBottom: '16px' }}>
                            Are you sure you want to delete?
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ padding: '0 16px 16px' }}>
                        <Button onClick={() => setConfirmDialog(false)} color="primary" variant="outlined">
                            No
                        </Button>
                        <Button onClick={confirmDelete} color="primary" variant="contained">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

            </Box>
        </Container>
    );
}

export default IdeaList;
