

import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        arrow
        PopperProps={{
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 10],
                    },
                },
            ],
        }}
        disableInteractive={false}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        //  backgroundColor: '#F5F5F5',
        backgroundColor: 'white',
        color: 'red',
        boxShadow: theme.shadows[1],
        fontSize: 15,
        padding: '8px 12px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
    },
}));

export default CustomTooltip;
