

import React, { useState } from 'react';
import { Box, Typography, OutlinedInput, InputAdornment, useMediaQuery, Grid, Container, Paper, Button, IconButton } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { API } from '../../../axios/api-path';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toast, ToastContainer } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import { OFEditIcon } from '../../../icons/CustomIcon';
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Backdrop } from '@mui/material';


function ResetPasswordPopUp({ handleClose }) {


    const [isLoading, setIsLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isEditing, setIsEditing] = useState(true);
    const [step, setStep] = useState(1); // Step 1: Email, Step 2: OTP, New Password, Confirm Password

    const { handleSubmit, control, formState: { errors }, watch, reset } = useForm();

    // const sendOTP = async (data) => {
    //     const apiCallData = {
    //         email: data.email,
    //     };

    //     const path = API.forgotProfilePassword();
    //     try {
    //         console.log("Submitting data for OTP:", apiCallData);
    //         await postApiCallWithCred({
    //             params: apiCallData,
    //             path: path,
    //             onSuccess: (res) => {
    //                 toast.success("OTP successfully sent to mail");
    //                 setStep(2); // Move to the next step
    //             },
    //             onError: (error) => {
    //                 console.error('Error sending OTP:', error);
    //                 toast.error("Failed to send OTP");
    //             },
    //         });
    //     } catch (error) {
    //         console.error('Unexpected error:', error);
    //         toast.error("Unexpected error occurred");
    //     }
    // };

    // const resetPassword = async (data) => {
    //     const apiCallData = {
    //         token: data.token,
    //         newPassword: data.newPassword,
    //     };

    //     const path = API.resetProfilePassword();
    //     try {
    //         console.log("Submitting data for password reset:", apiCallData);
    //         await postApiCallWithCred({
    //             params: apiCallData,
    //             path: path,
    //             onSuccess: (res) => {
    //                 toast.success("Password reset successfully");
    //                 handleClose();
    //             },
    //             onError: (error) => {
    //                 console.error('Error resetting password:', error);
    //                 toast.error("Failed to reset password");
    //             },
    //         });
    //     } catch (error) {
    //         console.error('Unexpected error:', error);
    //         toast.error("Unexpected error occurred");
    //     }
    // };

    const sendOTP = async (data) => {
        setIsLoading(true); // Show spinner
        const apiCallData = { email: data.email };
        const path = API.forgotProfilePassword();

        try {
            await postApiCallWithCred({
                params: apiCallData,
                path: path,
                onSuccess: (res) => {
                    toast.success('OTP successfully sent to mail');
                    setStep(2);
                },
                onError: (error) => {
                    toast.error('Failed to send OTP');
                },
            });
        } catch (error) {
            toast.error('Unexpected error occurred');
        } finally {
            setIsLoading(false); // Hide spinner
        }
    };

    const resetPassword = async (data) => {
        setIsLoading(true); // Show spinner
        const apiCallData = {
            token: data.token,
            newPassword: data.newPassword,
        };
        const path = API.resetProfilePassword();

        try {
            await postApiCallWithCred({
                params: apiCallData,
                path: path,
                onSuccess: (res) => {
                    setSuccessDialogOpen(true); // Open success dialog
                },
                onError: (error) => {
                    toast.error('Failed to reset password');
                },
            });
        } catch (error) {
            toast.error('Unexpected error occurred');
        } finally {
            setIsLoading(false); // Hide spinner
        }
    };


    const newPassword = watch("newPassword"); // Watch newPassword for validation

    return (
        <>
            <ToastContainer position="top-right" autoClose={3000} />
            <Box paddingX={0} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>

                <Box >
                    <Box
                        px={{ xs: 2, md: 3 }}
                        pb={{ xs: 2, md: 3 }}
                        // minHeight="40vh"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        marginTop={1}
                        width="100%"
                    >

                        <Box width="100%">
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                width="100%"
                                sx={{

                                    justifyContent: { xs: 'center', sm: 'space-between' },
                                    textAlign: { xs: 'center', sm: 'left' }
                                }}
                            >

                                <Grid item xs={12} sm="auto">
                                    <Box
                                        sx={{
                                            width: { xs: '100%', sm: '15.25rem' },
                                            color: '#000',
                                            fontFamily: 'Outfit',
                                            marginBottom: { xs: 2, sm: 0 },
                                            textAlign: { xs: 'center', sm: 'left' },
                                        }}
                                    >
                                        <Typography variant="body1" component="span">
                                            Welcome to
                                        </Typography>
                                        <Typography variant="body1" component="span">
                                            {` `}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="span"
                                            sx={{ fontWeight: 600, color: '#0D2659' }}
                                        >
                                            Onefranhub
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* Right Section - Register Link */}
                                <Grid
                                    item
                                    xs={12}
                                    sm="auto"
                                    sx={{
                                        textAlign: { xs: 'center', sm: 'right' },
                                        display: 'flex',
                                        justifyContent: { xs: 'center', sm: 'flex-end' },
                                    }}
                                >
                                    <Typography variant="body1" sx={{ color: '#BDBDBD' }}>
                                        Register&nbsp;
                                        {/* <Link
                                                            to={API.signup} // Use the correct path or URL here
                                                            style={{ color: '#0daee2', textDecoration: 'none' }}
                                                            onClick={handleLinkClick}
                                                        >
                                                            Here
                                                        </Link> */}
                                        <span
                                            style={{ color: "#0daee2", cursor: "pointer", textDecoration: 'none' }}
                                        // onClick={handleRegister}
                                        >
                                            Sign up
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={3} width="100%" textAlign={{ xs: 'center', md: 'left' }}>
                            <Typography variant="h3" fontWeight="bold" sx={{ color: '#0D2659' }} >
                                Forgot
                                Password
                            </Typography>
                        </Box>
                        <Box mt={3} width="100%" textAlign={{ xs: 'center', md: 'left' }}>
                            <Typography variant="body" fontWeight="bold" sx={{ color: '#0D2659' }} >
                                Don’t worry, happens to all of us. Enter your email below to recover your password
                            </Typography>
                        </Box>

                    </Box>


                    <Box>

                        <Container maxWidth="xl">
                            <Box paddingY={0}>
                                <Typography variant="body" fontWeight="bold" sx={{ color: '#0D2659' }} >
                                    Enter your Email address
                                </Typography>
                                <Grid container justifyContent="center" alignItems="left">
                                    <Grid item xs={12} md={12} paddingY={4}>
                                        <form onSubmit={handleSubmit(step === 1 ? sendOTP : resetPassword)}>
                                            {step === 1 && (
                                                <>
                                                    <Grid item xs={12} mb={2}>
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: 'Email is required' }}
                                                            render={({ field }) => (
                                                                <OutlinedInput
                                                                    {...field}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <LockIcon style={{ color: '#666666' }} />
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder="Email ID"
                                                                    type="email"
                                                                    error={!!errors.email}
                                                                    fullWidth
                                                                    sx={{
                                                                        backgroundColor: '#f9f9f9',
                                                                        borderRadius: '0.5rem',
                                                                        padding: '10px',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.email && <Typography color="error">{errors.email.message}</Typography>}
                                                    </Grid>
                                                </>
                                            )}

                                            {step === 2 && (
                                                <>
                                                    <Grid item xs={12} mb={2}>
                                                        <Controller
                                                            name="token"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: 'OTP is required' }}
                                                            render={({ field }) => (
                                                                <OutlinedInput
                                                                    {...field}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <LockIcon style={{ color: '#666666' }} />
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder="Enter OTP"
                                                                    type="text"
                                                                    error={!!errors.token}
                                                                    fullWidth
                                                                    sx={{
                                                                        backgroundColor: '#f9f9f9',
                                                                        borderRadius: '0.5rem',
                                                                        padding: '10px',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.token && <Typography color="error">{errors.token.message}</Typography>}
                                                    </Grid>

                                                    <Grid item xs={12} mb={2}>
                                                        <Controller
                                                            name="newPassword"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: 'New password is required' }}
                                                            render={({ field }) => (
                                                                <OutlinedInput
                                                                    {...field}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <LockIcon style={{ color: '#666666' }} />
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder="New Password"
                                                                    type="password"
                                                                    error={!!errors.newPassword}
                                                                    fullWidth
                                                                    sx={{
                                                                        backgroundColor: '#f9f9f9',
                                                                        borderRadius: '0.5rem',
                                                                        padding: '10px',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.newPassword && <Typography color="error">{errors.newPassword.message}</Typography>}
                                                    </Grid>

                                                    <Grid item xs={12} mb={2}>
                                                        <Controller
                                                            name="confirmPassword"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{
                                                                required: 'Confirm password is required',
                                                                validate: (value) => value === newPassword || 'Passwords do not match',
                                                            }}
                                                            render={({ field }) => (
                                                                <OutlinedInput
                                                                    {...field}
                                                                    startAdornment={
                                                                        <InputAdornment position="start">
                                                                            <LockIcon style={{ color: '#666666' }} />
                                                                        </InputAdornment>
                                                                    }
                                                                    placeholder="Confirm Password"
                                                                    type="password"
                                                                    error={!!errors.confirmPassword}
                                                                    fullWidth
                                                                    sx={{
                                                                        backgroundColor: '#f9f9f9',
                                                                        borderRadius: '0.5rem',
                                                                        padding: '10px',
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.confirmPassword && <Typography color="error">{errors.confirmPassword.message}</Typography>}
                                                    </Grid>
                                                </>
                                            )}

                                            {/* <Grid item xs={12} md={12} mb={2}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}>
                                                    {step === 1 ? 'Submit' : 'Reset Password'}
                                                </Button>
                                            </Grid> */}
                                            {/* <Grid item xs={12} mb={2}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}
                                                    disabled={isLoading}
                                                >
                                                    {step === 1 ? 'Submit' : 'Reset Password'}
                                                </Button>
                                            </Grid> */}
                                            <Grid item xs={12} mb={1} mt={5} display="flex" justifyContent="center">

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{
                                                        background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                                                        color: '#FFFFFF',
                                                        '&:hover': {
                                                            background: 'linear-gradient(90deg, #042f85, #0d51d9)',
                                                        },
                                                    }}
                                                    size="large"
                                                    fullWidth
                                                    disabled={isLoading}
                                                >
                                                    {step === 1 ? 'Submit' : 'Reset Password'}
                                                </Button>
                                                {/* <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#002659', color: '#fff', padding: '10px 20px' }}
                                                    disabled={isLoading}
                                                >
                                                    {step === 1 ? 'Submit' : 'Reset Password'}
                                                </Button> */}

                                            </Grid>
                                        </form>
                                    </Grid>


                                </Grid>
                            </Box>
                        </Container>
                    </Box>

                </Box>

            </Box>
            {/* Loading Overlay */}
            <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: theme.zIndex.modal + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>


            {/* Success Dialog */}
            <Dialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <Typography>Your password has been reset successfully!</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setSuccessDialogOpen(false);
                            handleClose();
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>

    );
}

export default ResetPasswordPopUp;
