
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Container } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { postApiCall, deleteApiCall } from '../../../axios/axios-methods';
import { API } from '../../../axios/api-path';
import CustomTableWithAction from '../../../helpers/tables/CustomTableWithAction';
import { fetchData } from '../../../axios/api-service';
import SuccessStory from './SuccessStory';

function SuccessStoryList() {
    const [ideas, setIdeas] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editIdea, setEditIdea] = useState(null); // To store the idea being edited

    // Fetch all ideas
    const fetchIdeas = async () => {
        const path = API.getAllSuccessStories();

        try {
            const response = await fetchData(path);
            setIdeas(response.data);
        } catch (error) {
            console.error("Error fetching ideas:", error);
        }
    };

    useEffect(() => {
        fetchIdeas();
    }, []);

    // Handle add new idea
    const handleAddClick = () => {
        setOpenDialog(true);
        setEditIdea(null); // Set to null for adding a new idea
    };

    // Handle edit existing idea
    const handleEditClick = (idea) => {
        setOpenDialog(true);
        setEditIdea(idea);
    };

    // Handle delete action
    const handleDeleteClick = async (idea) => {
        // try {
        //     await deleteApiCall({ path: API.deleteIdea(idea.id) });
        //     toast.success('Idea deleted successfully');
        //     fetchIdeas(); // Refresh the ideas list
        // } catch (error) {
        //     toast.error('Failed to delete idea');
        // }
    };

    // Handle form submission (edit or add)
    const handleFormSubmit = async (data) => {

        console.log(" update dat a" + JSON.stringify(data, null, 2))
        try {
            if (editIdea) {
                // If editIdea exists, update the idea
                await postApiCall({ path: API.addIdea(editIdea.id), data });
                toast.success('Idea updated successfully');
            } else {
                // If no editIdea, add a new idea
                await postApiCall({ path: API.addIdea(), data });
                toast.success('Idea added successfully');
            }
            fetchIdeas(); // Refresh the ideas list
            setOpenDialog(false); // Close dialog
        } catch (error) {
            toast.error('Failed to submit idea');
        }
    };

    return (
        // <Box>
        //     <ToastContainer position="top-right" autoClose={5000} />
        //     <Typography variant="h4" gutterBottom>
        //         Business Ideas
        //     </Typography>
        //     <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        //         <Button
        //             variant="contained"
        //             color="primary"
        //             onClick={handleAddClick}
        //         >
        //             Add new business idea
        //         </Button>
        //     </Box>

        //     {/* Pass onEdit and onDelete handlers to CustomTableWithAction */}
        //     <CustomTableWithAction
        //         data={ideas}
        //         onEdit={handleEditClick}
        //         onDelete={handleDeleteClick}
        //     />

        //     {/* Dialog for Add/Edit Idea */}
        //     <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        //         <DialogTitle>{editIdea ? 'Edit Idea' : 'Add New Idea'}</DialogTitle>
        //         <DialogContent>
        //             <Idea
        //                 editIdea={editIdea}
        //                 onSubmit={handleFormSubmit} // Pass the form submission handler
        //                 onClose={() => setOpenDialog(false)}
        //             />
        //         </DialogContent>
        //         <DialogActions>
        //             <Button onClick={() => setOpenDialog(false)} color="primary">
        //                 Close
        //             </Button>
        //         </DialogActions>
        //     </Dialog>
        // </Box>
        <Container maxWidth="xl">
            <Box>
                <ToastContainer position="top-right" autoClose={5000} />
                <Typography variant='h6' sx={{ color: '#002659', textAlign: 'left' }} paragraph>Create Success Stories</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddClick}
                    >
                        Add success story
                    </Button>
                </Box>

                {/* Pass onEdit and onDelete handlers to CustomTableWithAction */}
                <CustomTableWithAction
                    data={ideas}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                />


                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    maxWidth="md" // Controls the width (half of screen width)
                    fullWidth={true} // Ensures the width is 50% of screen size
                >
                    <DialogTitle>{editIdea ? 'Edit Success story' : 'Add New Success Story'}</DialogTitle>
                    <DialogContent>
                        <SuccessStory
                            editIdea={editIdea}
                            onSubmit={handleFormSubmit} // Pass the form submission handler
                            onClose={() => setOpenDialog(false)}
                        />
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </Box>
        </Container>

    );
}

export default SuccessStoryList;
