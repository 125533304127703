
import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, Grid, Typography, TextField, Button, Breadcrumbs, Stack, Paper, InputAdornment, Container } from '@mui/material';
import CardDisplay from './CardDisplay';
import SearchPageForm from './SearchPageForm';
import { oneFranHubtheme } from '../../theme/theme';
import { fetchCardDetails, fetchIndustries, fetchSectors, fetchStates } from '../../axios/api-service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { OFMail2, OFSearchIcon1, OFSearchIcon2, OFSearchIcon3 } from '../../icons/CustomIcon';
import { API } from '../../axios/api-path';




const SearchPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [mainSearch, setMainSearch] = useState(false);
    const [childSearch, setChildSearch] = useState(false);
    const [selectedFiltersFromMain, setSelectedFiltersFromMain] = useState({});
    const [searchText, setSearchText] = useState(''); // New state for search input


    const [selectedFilters, setSelectedFilters] = useState({
        industry: [],
        sector: [],
        category: null,
        location: [],
        investmentRange: [0, Infinity],
    });
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [locations, setLocations] = useState([]);
    const [categories] = useState([
        { id: '1', name: 'FRANCHISER' },
        { id: '2', name: 'BUSINESS_COLLAB' },
        { id: '3', name: 'DEALER' },
        { id: '4', name: 'BUSINESS_SALE' },
    ]);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width: 960px)');

    useEffect(() => {
        // Extract the state from the location object
        const { mainSearch: mainSearchValue, ...selectedFilters } = location.state || {};
        setMainSearch(mainSearchValue);
        setSelectedFiltersFromMain(selectedFilters);  // Set initial filters from main page
    }, [location]);

    useEffect(() => {
        // Fetch initial data
        fetchIndustries(setIndustries);
        fetchSectors(setSectors);
        fetchStates(setLocations, 1);

        const fetchData = async () => {
            try {
                const [responseFranchise, responseBSeller, responsePartner, responseDealer] = await Promise.allSettled([
                    fetchCardDetails(API.getCardList()),
                    fetchCardDetails(API.getBSellerCardList()),
                    fetchCardDetails(API.getPartnerCardList()),
                    fetchCardDetails(API.getDealerCardList())
                ]);

                const allData = [
                    ...(responseFranchise.status === 'fulfilled' ? responseFranchise.value.data : []),
                    ...(responseBSeller.status === 'fulfilled' ? responseBSeller.value.data : []),
                    ...(responsePartner.status === 'fulfilled' ? responsePartner.value.data : []),
                    ...(responseDealer.status === 'fulfilled' ? responseDealer.value.data : [])
                ];

                // Remove duplicates based on entityCode
                const mergedData = Array.from(
                    new Map(allData.map(item => [item.entityCode, item])).values()
                );

                setData(mergedData);
                setFilteredItems(mergedData);

            } catch (error) {
                console.error('Error fetching card details:', error);
            }
        };

        fetchData();
    }, []);

    const applyFilters = () => {

        let industryNames = selectedFilters.industry.map(filter => filter.name);
        let sectorNames = selectedFilters.sector.map(filter => filter.name);
        let locationNames = selectedFilters.location.map(filter => filter.name);
        let categoryNames = selectedFilters.category ? selectedFilters.category.name : '';
        let minRangeSelected = selectedFilters.investmentRange[0];
        let maxRangeSelected = selectedFilters.investmentRange[1];

        console.log(" mainSearch in filter " + mainSearch)
        if (mainSearch) {
            industryNames = mainSearch ? selectedFiltersFromMain?.industry?.map(filter => filter.name) : industryNames;

            locationNames = mainSearch ? selectedFiltersFromMain?.location?.map(filter => filter.name) : locationNames;

            minRangeSelected = mainSearch ? selectedFiltersFromMain?.investmentRange?.[0] : minRangeSelected;
            maxRangeSelected = mainSearch ? selectedFiltersFromMain?.investmentRange?.[1] : maxRangeSelected;
        } else {
            industryNames = mainSearch ? selectedFiltersFromMain?.industry?.map(filter => filter.name) : industryNames;
            sectorNames = mainSearch ? selectedFiltersFromMain?.sector?.map(filter => filter.name) : sectorNames;
            locationNames = mainSearch ? selectedFiltersFromMain?.location?.map(filter => filter.name) : locationNames;
            categoryNames = mainSearch ? selectedFiltersFromMain?.category?.name : categoryNames;
            minRangeSelected = mainSearch ? selectedFiltersFromMain?.investmentRange?.[0] : minRangeSelected;
            maxRangeSelected = mainSearch ? selectedFiltersFromMain?.investmentRange?.[1] : maxRangeSelected;

        }
        // console.log("data " + JSON.stringify(data, null, 2))
        console.log("locationNames " + locationNames)
        let filtered
        if (mainSearch) {
            filtered = data?.filter(card => {
                const matchesIndustry = industryNames.length === 0 || industryNames.includes(card.industryName);



                const matchesLocation = locationNames.length === 0 || locationNames.includes(card.state);
                console.log("industryNames " + industryNames)
                console.log("matchesLocation " + matchesLocation)
                console.log("matchesIndustry " + matchesIndustry)


                return (
                    (industryNames.length === 0 || matchesIndustry) &&
                    (locationNames?.length === 0 || matchesLocation)

                );

            });
        }
        else if (!mainSearch) {
            filtered = data?.filter(card => {

                const matchesIndustry = (industryNames?.length === 0 || industryNames?.includes(card.industryName)) || false;

                const matchesSector = (Array.isArray(sectorNames) && sectorNames.length === 0) || (sectorNames?.includes(card.industrySectorName || ''));

                const matchesCategory =
                    ((categoryNames && categoryNames.trim().toLowerCase()) || '') ===
                    ((card.entityType && card.entityType.trim().toLowerCase()) || '');



                const matchesLocation = locationNames.length === 0 || locationNames.includes(card.state);
                console.log("matchesLocation " + matchesLocation)

                const minRangeCard = card.investmentRange?.startIndex;
                const maxRangeCard = card.investmentRange?.endIndex;
                const matchesInvestmentRange = (minRangeCard !== undefined && maxRangeCard !== undefined)
                    ? (minRangeCard >= minRangeSelected && maxRangeCard <= maxRangeSelected)
                    : true;

                const matchesSearchText = searchText
                    ? [card.industryName, card.industrySectorName, card.entityType, card.state]
                        .filter(Boolean)
                        .some(value => value.toLowerCase().includes(searchText.toLowerCase()))
                    : true;


                return (
                    (industryNames?.length === 0 || matchesIndustry) &&
                    (sectorNames?.length === 0 || matchesSector) &&
                    (matchesCategory !== undefined ? matchesCategory : false) &&
                    (locationNames?.length === 0 || matchesLocation) &&
                    (minRangeSelected === 0 && maxRangeSelected === Infinity || matchesInvestmentRange) &&
                    matchesSearchText
                );

            });

        }

        setFilteredItems(filtered);
        console.log("filtered " + JSON.stringify(filtered, null, 2))
    };

    useEffect(() => {

        if (mainSearch || childSearch) {
            console.log(" apply filters called ")
            applyFilters();
        }




    }, [selectedFilters, selectedFiltersFromMain, searchText, data]);

    const handleFilterChange = (filters) => {
        setSelectedFilters(filters); // Update selected filters when user changes them
        setMainSearch(false)
        setChildSearch(true)
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Home
        </Link>,
        <Typography key="3" sx={{ color: 'text.primary' }}>
            Personalized
        </Typography>,
    ];

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <Box sx={{ backgroundColor: '#FFFFFF' }}>
            <Container maxWidth={'xl'}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{ minHeight: '15rem', borderRadius: '18px', backgroundColor: 'yellow' }}>
                                <Box style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '15rem'
                                }}>
                                    <Typography>Advertisement</Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* Left Panel */}
                        <Grid item xs={12} md={3}>
                            <Box>
                                <SearchPageForm
                                    industries={industries}
                                    sectors={sectors}
                                    categories={categories}
                                    locations={locations}
                                    onFilterChange={handleFilterChange}
                                />
                            </Box>
                        </Grid>
                        {/* Main Content Area */}
                        <Grid item xs={12} md={9}>
                            <Box sx={{ padding: 2 }}>
                                <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="h5">Personalized</Typography>
                                    </Grid>
                                    <Grid item>
                                        {/* <Stack direction="row" spacing={2} alignItems="center">
                                            <TextField
                                                type="text"
                                                placeholder="Search"
                                                variant="outlined"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '100px',
                                                        paddingRight: '8.5rem',
                                                        height: '2.75rem',
                                                        width: { xs: '100%', sm: 'auto' },
                                                        '& fieldset': {
                                                            borderColor: 'grey',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'grey',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'grey',
                                                        },
                                                    },
                                                    '& input': {
                                                        paddingLeft: '1rem',
                                                        height: '100%',
                                                    },
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <OFSearchIcon3 sx={{ color: '#505050' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Button variant="outlined">Sort by Popular</Button>
                                        </Stack> */}
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <TextField
                                                type="text"
                                                placeholder="Search"
                                                value={searchText}
                                                onChange={handleSearchChange}
                                                variant="outlined"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '100px',
                                                        paddingRight: '8.5rem',
                                                        height: '2.75rem',
                                                        width: { xs: '100%', sm: 'auto' },
                                                        '& fieldset': {
                                                            borderColor: 'grey',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'grey',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'grey',
                                                        },
                                                    },
                                                    '& input': {
                                                        paddingLeft: '1rem',
                                                        height: '100%',
                                                    },
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <OFSearchIcon3 sx={{ color: '#505050' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Button variant="outlined">Sort by Popular</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box>
                                {/* Pass filtered items to CardDisplay */}
                                <CardDisplay filteredItems={filteredItems} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default SearchPage;
