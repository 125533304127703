import { Box, Paper, Typography, Grid, IconButton } from '@mui/material';
import React from 'react';
import { OFMailAtSign, OFCallIcon, OFEditIcon } from '../../../icons/CustomIcon'
import { ReactComponent as WhatsappIcon } from "../../../assets/icons/WhatsApp.svg"
import PersonalInfo from '../../registration/PersonalInfo';
import ChangePassword from './ChangePassword';
import ResetPassword from './ResetPasswordPopUp';
import ProfileDetails from './ProfileDetails';


const SettingsPage = () => {








    return (
        <div>
            <Box
                paddingX={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                }}
            >
                <Box marginBottom={1} marginTop={1}>
                    {/* Add any other content here */}
                </Box>

                <Paper
                    elevation={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 4,
                        paddingBottom: 4,
                        marginBottom: 4,
                        marginTop: 4,
                        backgroundColor: '#FFF5F1'
                    }}
                >
                    <Box padding={2}>
                        <Typography variant='TITLE3'>Sign up</Typography>
                    </Box>
                    <Grid container paddingX={2} spacing={1} ml={2}>
                        <Grid item xs={12} md={12} display="flex" flexDirection="column" alignItems="flex-start">

                            {/* <PersonalInfo isDashboardMode={false} /> */}

                        </Grid>

                    </Grid>
                </Paper>
                <ProfileDetails />
                <ChangePassword />
                {/* <ResetPassword /> */}
                {/* <Paper
                    elevation={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 4,
                        paddingBottom: 4,
                        backgroundColor: '#F1F6FF'
                    }}
                >
                    <Box padding={2}>
                        <Typography variant='TITLE3'>Change Password time</Typography>
                        <IconButton
                            onClick={toggleEdit}
                            style={{ float: 'right' }}>
                            <OFEditIcon />
                        </IconButton>
                    </Box>
                    <Grid container paddingX={2} spacing={1} ml={2}>

                    </Grid>
                </Paper> */}
            </Box>
        </div>
    );
}

export default SettingsPage;
