
import axios from "axios";
import {
    api,
    apiDownloadFile,
    apiFileUpload,
    getApiCallWithToken,
    //apiWithCred
} from "./axios-configuration";


export const postApiCall = async ({ path, data, onSuccess, onError }) => {
    try {
        const response = await api.post(path, data); // Use the `api` instance
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};

export const putApiCall = async ({ path, params, data, onSuccess, onError }) => {
    try {
        const response = await api.put(path, data, params); // Use the `PUT` method
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};

export const deleteApiCall = async ({ path, params, onSuccess, onError }) => {
    try {
        const response = await api.delete(path, { params }); // Use the `DELETE` method with params
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};




export const postApiCallWithCred = async ({ path, data, params = {}, onSuccess, onError }) => {
    try {
        const response = await api.post(path, data, { params }); // Use the `api` instance
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};


export const putApiCallWithCred = async ({ path, data, params = {}, onSuccess, onError }) => {
    try {
        const response = await api.put(path, data, { params }); // Use PUT method and pass `params`
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};




export const getApiCallWithCred = async ({ path, params, onSuccess, onError }) => {
    try {
        // Retrieve token from localStorage
        const token = localStorage.getItem('token');

        // Make GET request with Authorization header
        const response = await axios.get(path, {
            baseURL: process.env.REACT_APP_BASE_URL, // Set the base URL
            params, // Add query parameters
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `Bearer ${token}` : undefined, // Include token if available
            },
        });

        // Call the onSuccess callback if provided
        if (onSuccess) onSuccess(response.data);

        return response.data; // Return the data from the response
    } catch (error) {
        // Call the onError callback if provided
        if (onError) onError(error);

        throw error; // Rethrow error for further handling
    }
};



export const postApiFileUpload = async ({ path, data, onSuccess, onError }) => {
    try {
        const response = await apiFileUpload.post(path, data); // Use the `api` instance
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};


// export const getApiCall = async ({ path, onSuccess, onError }) => {
//     try {
//         const response = await api.get(path);
//         if (onSuccess) onSuccess(response.data);
//         return response.data;
//     } catch (error) {
//         if (onError) onError(error);
//         throw error; // Rethrow the error for component-level handling
//     }
// };

export const getApiCall = async ({ path, params = {}, onSuccess, onError }) => {
    try {
        const response = await api.get(path, { params });
        if (onSuccess) onSuccess(response.data);
        return response.data;
    } catch (error) {
        if (onError) onError(error);
        throw error; // Rethrow the error for component-level handling
    }
};


export const getApiDownloadFile = async (fileUrl, onSuccess, onError) => {
    try {
        const response = await apiDownloadFile.get(fileUrl, {
            responseType: 'blob', // Important for handling binary data
        });


        const fileUrlBlob = URL.createObjectURL(response.data);


        if (onSuccess) onSuccess(fileUrlBlob);
        return fileUrlBlob;
    } catch (error) {
        console.error('Error downloading the file:', error);
        if (onError) onError(error);
        throw error;
    }
};