

import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import { ReactComponent as StepCompleteIcon } from '../../../assets/icons/StepCompleteIcon.svg';
import { ReactComponent as StepIncompleteIcon } from '../../../assets/icons/StepIncompleteIcon.svg';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

// Styled components for custom step icons
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#E3E3E3', // Color for active state
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#0D2659', // Color for completed state
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 8,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#E3E3E3',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'white',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
}));

function ColorlibStepIcon(props) {
    const { active, completed, className, icon } = props;

    // Determine the icon to display based on step status
    const getIcon = () => {
        if (completed) return <StepCompleteIcon />;
        if (active) return <StepIncompleteIcon />;
        return <StepIncompleteIcon />;
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {getIcon()}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

// Define steps
const steps = ['Sign up',
    'Company Information and Collaboration Goals',
    'Financial Information and Strategic Growth',
    'Mentorship and Advisory',
    'Legal & Regulatory Requirements',
    'Appearance and Media',
    'Payment'];

const ProgressSteperBusinessCollaboration = () => {
    const location = useLocation();

    // Determine the active step based on the route
    const getStepIndex = () => {
        if (location.pathname === '/business-collaboration/registration/step/1' || location.pathname === '/dashboard/business-collaboration/registration/step/1') return 1;
        if (location.pathname === '/business-collaboration/registration/step/2' || location.pathname === '/dashboard/business-collaboration/registration/step/2') return 2;
        if (location.pathname === '/business-collaboration/registration/step/3' || location.pathname === '/dashboard/business-collaboration/registration/step/3') return 3;
        if (location.pathname === '/business-collaboration/registration/step/4' || location.pathname === '/dashboard/business-collaboration/registration/step/4') return 4;
        if (location.pathname === '/business-collaboration/registration/step/5' || location.pathname === '/dashboard/business-collaboration/registration/step/5') return 5;
        if (location.pathname === '/business-collaboration/registration/step/6' || location.pathname === '/dashboard/business-collaboration/registration/step/6') return 6;
        if (location.pathname === '/business-collaboration/registration/step/7' || location.pathname === '/dashboard/business-collaboration/registration/step/7') return 7;

        return -1; // Handle case for Popup or other routes
    };

    const activeStep = getStepIndex();

    return (
        <Box sx={{ width: '100%', padding: 1 }}>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<ColorlibConnector />}
            >
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            sx={{
                                color: index < activeStep ? 'green' : 'grey',
                                '& .MuiStepLabel-label': {
                                    color: index < activeStep ? 'green' : 'grey',
                                },
                            }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default ProgressSteperBusinessCollaboration;
