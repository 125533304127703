import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { OFAddress, OFDeleteIcon, OFEditIcon } from '../../icons/CustomIcon';

const CustomTableWithAction = ({ data, onEdit, onDelete }) => {
    // Function to format column labels to proper case with spaces
    const formatColumnLabel = (label) => {
        return label
            .replace(/([A-Z])/g, ' $1')  // Add a space before each capital letter
            .replace(/^./, (str) => str.toUpperCase())  // Capitalize the first letter
            .trim(); // Remove any leading/trailing spaces
    };

    // Always call hooks unconditionally
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id'); // Default to the first column
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // Ensure data is valid
    const isDataValid = Array.isArray(data) && data.length > 0;

    // Generate columns dynamically based on keys from the first item of data
    const columns = isDataValid
        ? Object.keys(data[0]).map((key) => ({
            id: key,
            label: formatColumnLabel(key), // Format the column label
            minWidth: 170,
        }))
        : [];

    // Create rows based on the data, handling nested objects (state, city)
    const rows = isDataValid
        ? data.map(item => {
            const row = {};
            columns.forEach((column) => {
                row[column.id] = item[column.id] && typeof item[column.id] === 'object'
                    ? item[column.id].name // Extract 'name' for objects like 'state' or 'city'
                    : item[column.id]; // Otherwise, use the value directly
            });
            return row;
        })
        : [];

    const handleRequestSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const sortedRows = React.useMemo(() => {
        return rows.slice().sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc' ? -1 : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [order, orderBy, rows]);

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '40vh' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={'left'}
                                    style={{
                                        minWidth: column.minWidth,
                                        backgroundColor: 'rgba(220, 231, 255, 1)',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            {/* Add a column for Actions */}
                            <TableCell align="left" style={{ minWidth: 150, backgroundColor: 'rgba(220, 231, 255, 1)', }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isDataValid ? (
                            sortedRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={'left'}
                                                    style={{
                                                        whiteSpace: 'normal',
                                                        wordBreak: 'break-word',
                                                        padding: '8px',
                                                    }}
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                        {/* Action buttons for Edit and Delete */}
                                        <TableCell align="left" style={{ padding: '8px' }}>
                                            <IconButton color="primary" onClick={() => onEdit(row)}>
                                                <OFEditIcon />
                                            </IconButton>
                                            <IconButton color="secondary" onClick={() => onDelete(row)}>
                                                <OFDeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length + 1} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Only show pagination if data is valid */}
            {isDataValid && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Paper>
    );
};

export default CustomTableWithAction;
