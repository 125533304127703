

import React, { useEffect, useState } from 'react';
import { Button, Stack, Box, useMediaQuery, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneFranHubtheme } from '../../../theme/theme';
import { API } from '../../../axios/api-path';
import CustomButton from '../../../helpers/CustomButton';
import { postApiCallWithCred } from '../../../axios/axios-methods';
import { toastError, toastSuccess } from '../../../utils/toast-notify';
import { fetchBusinessProfile, fetchCities, fetchCountries, fetchFormInfo, fetchIndustries, fetchRange, fetchSectors, fetchStates } from '../../../axios/api-service';

import OwnerDetailsForm from './SOwnerDetailsForm';
import SOwnerDetailsForm from './SOwnerDetailsForm';
import SFinancialDetailsForm from './SFinancialDetailsForm';
import { currencyValidation, dropdownValidation } from '../../../utils/validations';
import { useRecoilState } from 'recoil';
import { perInfoState } from '../../../recoil/profile-atom';

const SFinancialDetails = ({ isDashboardMode }) => {
    const isMobile = useMediaQuery(oneFranHubtheme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(oneFranHubtheme.breakpoints.between('sm', 'md'));
    const [perInfo, setPerInfo] = useRecoilState(perInfoState);
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(null);
    const [isFormData, setIsFormData] = useState(false);
    // const [outletStates, setOutletStates] = useState([])

    const [isEditing, setIsEditing] = useState(isDashboardMode ? false : true);

    useEffect(() => {
        fetchCountries(setCountries);
        fetchIndustries(setIndustries);
        fetchRange(setRanges);
        const fetchData = async () => {
            const path = API.getBSellerInfo();

            try {
                const response = await fetchFormInfo(path);

                if (response.data.bsellerFinancialDetailsStatus === 'COMPLETE') {
                    setFormData(response.data.bsellerFinancialDetails);

                    setIsFormData(true);
                } else {
                    setIsFormData(false);
                }
            } catch (err) {
                // Handle error
            }
        };

        fetchData();
    }, []);



    const validationSchema = yup.object({
        "availableGoodsValue": currencyValidation,
        "operationalExpanse": currencyValidation,
        "employeesSalaryMonthly": currencyValidation,
        "grossIncome": currencyValidation,
        "ebitda": currencyValidation,
        "approxNetProfit": currencyValidation,
        // "debtObligation": currencyValidation
        "revenueStreams": dropdownValidation,
        "previousFPyearOne": currencyValidation,
        //  "previousFPyearTwo": data?.previousFPyearTwo,
        //  "previousFPyearThree": data?.previousFPyearThree



    });




    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue, getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {




        },
        resolver: yupResolver(validationSchema),
    });


    const {
        industry,
        country,
        city,
        state,
        loanSupportRequired,
    }
        = watch({ control });


    useEffect(() => {
        console.log(" HII" + loanSupportRequired)
        if (loanSupportRequired === 'No') {
            setValue("acceptTermAndConditions", null);
            setValue("desiredLoanAmount", null);
            setValue("preferredLoanTerm", null);
            setValue("monthlyIncome", null);
            setValue("existingDebt", null);
            setValue("creditScore", null);
            setValue("specificRequirementOnLoan", null);
            setValue("loanAgainstProperty", null);
            setValue("typeOfProperty", null);
            setValue("propertyValue", null);
            setValue("propertyArea", null);
            setValue("doYouWantSaySomething", null);

        }
    }, [loanSupportRequired, setValue]);

    useEffect(() => {

        if (country) {
            fetchStates(setStates, country.id);

        }
    }, [country, setValue]);
    useEffect(() => {

        if (state) {
            fetchCities(setCities, state.id);

        }
    }, [state, setValue]);

    useEffect(() => {

        if (industry) {
            fetchSectors(setIndustrySectors, industry.id);

        }
    }, [industry, setValue]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [industrySectors, setIndustrySectors] = useState([]);
    const [ranges, setRanges] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
        if (selectedCountry) {
            setValue('state', '');
            setValue('city', '');
            setStates([]);
            setCities([]);
        }
    }, [selectedCountry, setValue]);

    useEffect(() => {
        if (selectedState) {
            setValue('city', '');
            setCities([]);
        }
    }, [selectedState, setValue]);



    useEffect(() => {

        if (selectedCountry) {

            fetchStates(setStates, selectedCountry);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            fetchCities(setCities, selectedState);
        }
    }, [selectedState]);

    useEffect(() => {
        if (selectedIndustry) {
            fetchSectors(setIndustrySectors, selectedIndustry);
        }
    }, [selectedIndustry]);

    function extractParts(ownerMobileNo) {
        if (ownerMobileNo != null) {

            const [countryCode, phoneNumber] = ownerMobileNo.split('-');


            return {
                countryCode: countryCode || null,
                phoneNumber: phoneNumber || null
            };
        } else {

            return {
                countryCode: null,
                phoneNumber: null
            };
        }
    }


    useEffect(() => {
        if (formData) {

            reset({
                "availableGoodsValue": formData?.availableGoodsValue,
                "operationalExpanse": formData?.operationalExpanse,
                "employeesSalaryMonthly": formData?.employeesSalaryMonthly,
                "grossIncome": formData?.grossIncome,
                "ebitda": formData?.ebitda,
                "approxNetProfit": formData?.approxNetProfit,
                "debtObligation": formData?.debtObligation,
                "revenueStreams": formData?.revenueStreams,
                "previousFPyearOne": formData?.previousFPyearOne,
                "previousFPyearTwo": formData?.previousFPyearTwo,
                "previousFPyearThree": formData?.previousFPyearThree

            });
        }
    }, [formData, reset]);

    const onSubmit = async (data) => {

        const apiCallData = {

            "availableGoodsValue": data?.availableGoodsValue,
            "operationalExpanse": data?.operationalExpanse,
            "employeesSalaryMonthly": data?.employeesSalaryMonthly,
            "grossIncome": data?.grossIncome,
            "ebitda": data?.ebitda,
            "approxNetProfit": data?.approxNetProfit,
            "debtObligation": data?.debtObligation,
            "revenueStreams": data?.revenueStreams,
            "previousFPyearOne": data?.previousFPyearOne,
            "previousFPyearTwo": data?.previousFPyearTwo,
            "previousFPyearThree": data?.previousFPyearThree



        };


        // const path = API.signupBSellerFinancialInfo();
        const userCode = sessionStorage.getItem("userCode");
        let path
        if ((perInfo?.roles?.includes("ADMIN")))
            path = `${API.signupBSellerFinancialInfoAdmin()}?userCode=${encodeURIComponent(userCode)}`

        else
            path = API.signupBSellerFinancialInfo();


        try {

            console.log(" Submit data " + JSON.stringify(apiCallData, null, 2))
            await postApiCallWithCred({
                data: apiCallData,
                path: path,
                onSuccess: (res) => {

                    if (isDashboardMode) {
                        setIsEditing(false)

                    }
                    else {
                        handleNext();


                    }



                },
                onError: (error) => {
                    toastError(error);
                }
            });
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleNext = () => {
        navigate(location.pathname.replace(/[^/]*$/, '5'));
    };

    const handleBack = () => {
        navigate(location.pathname.replace(/[^/]*$/, '3'));
    };


    return (

        <>
            <ToastContainer

            />

            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 4,
                    paddingBottom: 4,
                }}
            >
                <SFinancialDetailsForm control={control} errors={errors} watch={watch} setValue={setValue} getValues={getValues}

                    countries={countries}
                    cities={cities}
                    states={states}
                    industries={industries}
                    industrySectors={industrySectors}
                    ranges={ranges}
                    setSelectedCity={setSelectedCity}
                    setSelectedCountry={setSelectedCountry}
                    setSelectedIndustry={setSelectedIndustry}
                    setSelectedSector={setSelectedSector}
                    setSelectedState={setSelectedState}
                    isDashboardMode={isDashboardMode}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    loanSupportRequired={loanSupportRequired}

                />


                {!isDashboardMode ? (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', p: 2 }}
                    >
                        <CustomButton
                            type="button"
                            width={{ xs: '75%', sm: '10rem' }}
                            variant="contained"
                            label="Back"
                            onClick={handleBack}
                        />

                        <CustomButton
                            type="submit"
                            width={{ xs: '75%', sm: '14rem' }}
                            variant="contained"
                            label={"Save and Next"}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Stack>
                ) : (
                    isEditing && (
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', p: 2 }}
                        >
                            <CustomButton
                                type="submit"
                                width={{ xs: '75%', sm: '14rem' }}
                                variant="contained"
                                label="Update"
                                onClick={handleSubmit(onSubmit)}
                            />
                        </Stack>
                    )
                )}



            </Paper>

        </>
    )
}

export default SFinancialDetails