
import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { OFIdeaCard } from './OFIdeaCard';
import { getColorPair, getRandomIndex } from '../../utils/RandomColour';
import { OFIdeaIcon } from '../../icons/CustomIcon';
import { API } from '../../axios/api-path';
import { fetchData } from '../../axios/api-service';



const IdeaCardDisplay = ({ title, description, noOfCards, defaultCard }) => {


    const [cardData, setCardData] = useState(null)

    useEffect(() => {
        const fetchIdeas = async () => {  // Renamed to avoid conflict

            const path = API.getAllIdeas();  // Assuming this is a function that returns the API endpoint

            try {
                const response = await fetchData(path);  // Using your `fetchData` method to make the request

                console.log("All ideas:", JSON.stringify(response.data, null, 2));
                setCardData(response.data)
            } catch (error) {
                console.error("Error fetching card details:", error);
            } finally {
                // Optional: Any cleanup or state update after data fetch
                // setLoading(false);
            }
        };

        fetchIdeas();  // Calling the renamed function to fetch the ideas
    }, []);










    console.log(" Card Data " + JSON.stringify(cardData, null, 2))
    // cardData = [
    //     {
    //         "id": 1,
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    //         "points": 10,
    //         "like": true
    //     },
    //     {
    //         "id": 2,
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    //         "points": 20,
    //         "like": false
    //     },
    //     {
    //         "id": 3,
    //         "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    //         "points": 15,
    //         "like": true
    //     },
    //     {
    //         "id": 4,
    //         "description": "This is the fourth item description.",
    //         "points": 8,
    //         "like": false
    //     },
    //     {
    //         "id": 5,
    //         "description": "This is the fifth item description.",
    //         "points": 25,
    //         "like": true
    //     }
    // ]

    const [showAll, setShowAll] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const cardsToShow = Array.isArray(cardData) ? (showAll ? cardData : cardData.slice(0, defaultCard)) : [];

    const handleViewMore = () => {
        navigate('/idea-page');
    };


    const { lightColor, darkColor } = getColorPair();

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, paddingTop: 4 }}>
                <Grid container >
                    <Grid item md={12} mb={1}>
                        <Typography variant='H4'
                            style={{

                                fontSize: '2.25rem',
                                color: '#0d2659',

                            }}
                        >
                            {title}
                            {/* <OFIdeaIcon /> */}
                        </Typography>
                    </Grid>


                    <Grid item md={10} mb={1}>
                        <Typography
                            style={{
                                maxWidth: isMobile ? '100%' : '80%',
                                fontWeight: 400,
                                fontSize: '18px',
                                lineHeight: '30px',
                                color: '#666',
                            }}
                        >
                            {description}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',

                            width: '100%',
                        }}
                    >
                        <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ width: '100%', paddingRight: 7 }}>
                            {!showAll && cardData?.length > defaultCard && (
                                <>
                                    <Typography
                                        component="span"
                                        onClick={handleViewMore}
                                        sx={{
                                            color: '#0D2659',
                                            cursor: 'pointer',
                                            //textDecoration: 'underline',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 1, // Space between text and icon
                                        }}
                                    >
                                        View more
                                    </Typography>
                                    <ArrowForward
                                        sx={{
                                            color: '#0D2659',
                                            fontSize: '1rem',
                                        }}
                                    />
                                </>
                            )}
                            {showAll && (
                                <Typography
                                    component="span"
                                    onClick={() => setShowAll(false)}
                                    sx={{
                                        color: '#0D2659',
                                        cursor: 'pointer',
                                        //textDecoration: 'underline',
                                        marginLeft: 2, // Space before "View Less"
                                    }}
                                >
                                    View less
                                </Typography>
                            )}
                        </Box>
                    </Grid>


                </Grid>



            </Box>

            {Array.isArray(cardData) && cardData.length === 0 ? (
                <Box paddingY={1} paddingX={1} >
                    <Typography
                        style={{
                            textAlign: 'left',
                            color: 'red',
                            fontSize: '1.25rem',
                        }}
                    >
                        No cards available.
                    </Typography>
                </Box>
            ) : (
                // <Grid container spacing={3} padding={isMobile ? 2 : 1}  >
                //     {cardsToShow.map((card, index) => (

                //         <OFIdeaCard
                //             key={card?.entityCode}  // unique
                //             contentBGColor={lightColor}

                //             titleBgColor={darkColor}
                //             entityCode={card?.entityCode}
                //             //imageId={card?.logo?.id}
                //             // category={card?.industryName}
                //             // location={card?.location}
                //             content={card?.description}
                //             // investmentRange={card?.investmentRange}
                //             // profitMargin={card?.profitMarginPerYear}
                //             //  outlets={card?.franchiseOutlets}
                //             noOfCards={noOfCards}
                //         />

                //     ))}
                // </Grid>
                <Grid container spacing={3} padding={isMobile ? 2 : 1}>
                    {cardsToShow.map((card) => {
                        // Generate a random index for each card
                        const randomIndex = getRandomIndex();
                        // Get the color pair for the generated index
                        const { lightColor, darkColor } = getColorPair(randomIndex);

                        return (
                            <OFIdeaCard
                                key={card?.entityCode}  // unique
                                contentBGColor={lightColor} // Light color for content
                                titleBgColor={darkColor}    // Matching dark color for title
                                entityCode={card?.entityCode}
                                content={card?.remarks}
                                noOfCards={noOfCards}
                            />
                        );
                    })}
                </Grid>
            )}
        </>
    );
};

export default IdeaCardDisplay;
